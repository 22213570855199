import React, { useMemo, useState } from 'react'
import { Steps } from 'antd'
import Page from '../../components/Page'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'
import Thanks from './components/Thanks'
import useExchangeReason from './hooks/exchange-reason'
import useStep from '../../hooks/step'
import useRentalProduct from '../../hooks/rental-product'
import {
  exchangeProducts as exchangeProductsApi,
  exchange as exchangeApi,
} from '../../api/rental'
import {
  RESPONSE_OK,
  EXCHANGE_REASON_BROKEN,
  EXCHANGE_REASON_INITIAL_FAILURE,
  EXCHANGE_REASON_AGEING_DEGRADATION
} from '../../constants'
import useLoading from '../Cart/hooks/loading'

const exchangeForSameItemReasons = [
  EXCHANGE_REASON_BROKEN,
  EXCHANGE_REASON_INITIAL_FAILURE,
  EXCHANGE_REASON_AGEING_DEGRADATION
]

export default function Exchange({ accountId, pNo }) {
  const { currentStep, incrementStep, decrementStep, isStep } = useStep()
  const { reasons, reason, setReason } = useExchangeReason()
  const [comment, setComment] = useState('')
  const [exchangeItems, setExchangeItems] = useState([])
  const product = useRentalProduct({ accountId, pNo })
  const [afterProductsLoading, withAfterProductsLoading] = useLoading()
  const [orderId, setOrderId] = useState(null)

  // 交換理由名
  const reasonName = useMemo(() => {
    if (reasons.length === 0 || !reason) { return '' }
    const item = reasons.find(r => r.value === reason)
    return item ? item.label : ''
  }, [reasons, reason])

  const selectTable = useMemo(() => {
    if (!product) return []
    if (product.isSet) {
      return product.setContents.map((item, index) => ({...item, sortId: index + 1}))
    }
    return [{
      pNo: product.pNo,
      title: product.productTitle,
      size: product.size,
      setCount: 1,
    }]
  }, [product])

  const handleExchangeItemChange = ({ pNo, count, setCount, sortId }) => {
    const items = exchangeItems.filter(item => item.pNo !== pNo)
    const findItem = selectTable.find(row => row.pNo === pNo)
    if (count > 0) {
      items.push({
        sortId: sortId ? sortId : 1,
        productId: product.productId,
        pNo: pNo,
        count: count,
        title: findItem ? findItem.title: "",
        size: findItem ? findItem.size: "",
        setPNo: product.pNo,      // セット品番の場合はセットの品番が入る
        setCount: setCount,       // セット品番の場合はセットの交換数量が入る
        setTitle: product.title,  // セット品番の場合はセット品番の商品タイトルが入る
        setSize: product.size,    // セット品番の場合はセットのサイズが入る
      })
    }
    setExchangeItems(items.sort((prev, next) => prev.sortId - next.sortId))
  }

  const handleSelectedReason = (value) => {
    setReason(value)
    setExchangeItems([])
  }

  // 交換先の商品を取得する
  const toChoiceAfterStep = () => withAfterProductsLoading(async () => {
    const response = await exchangeProductsApi({
      reason: reason,
      exchangeItems: exchangeItems.map((item) => ({
        pNo: item.pNo,
        count: item.count,
        productTitle: item.title,
        productId: item.productId
      }))
    })
    if (response.status !== RESPONSE_OK) { return; }
    setExchangeItems(response.data.map((item) => {
      const { pNo, exchangeProducts } = item.exchangeItem
      const exchangeItem = exchangeItems.find(exchangeItem => exchangeItem.pNo === pNo)
      return {
        ...exchangeItem,
        exchangeProducts: exchangeProducts.filter(item => {
          return exchangeForSameItemReasons.includes(reason) ? item.pNo === pNo : true
        })
      }
    }).sort((prev, next) => prev.sortId - next.sortId))
    incrementStep()
  })

  const handleSelectedExchangeAfterItem = ({ exchangeItemPNo, exchangeAfterItemPNo }) => {
    const exchangeItem = exchangeItems.find(exchangeItem => exchangeItem.pNo === exchangeItemPNo)
    const items = exchangeItems.filter(exchangeItem => exchangeItem.pNo !== exchangeItemPNo)
    items.push({
      ...exchangeItem,
      exchangeAfterItem: exchangeItem.exchangeProducts.find(product => product.pNo === exchangeAfterItemPNo)
    })
    setExchangeItems(items.sort((prev, next) => prev.sortId - next.sortId))
  }

  // 送信
  const handleSubmit = async data => {
    const response = await exchangeApi({
      ...data,
      accountId
    })
    if (response.status === RESPONSE_OK) {
      setOrderId(response.data)
      incrementStep()
    }
  }

  return (
    <Page title="交換申し込み">
      <div className="mb-6">
        <Steps current={currentStep}>
          <Steps.Step title="返却商品を選ぶ" />
          <Steps.Step title="交換先を選ぶ" />
          <Steps.Step title="確認" />
        </Steps>
      </div>

      {isStep(0) && (
        <Step1
          product={product}
          selectTable={selectTable}
          reasons={reasons}
          reason={reason}
          comment={comment}
          exchangeItems={exchangeItems}
          onSelectedReason={handleSelectedReason}
          onChangedComment={setComment}
          onChangedExchangeItem={handleExchangeItemChange}
          onNextClick={toChoiceAfterStep}
        />
      )}

      {isStep(1) && (
        <Step2
          product={product}
          reasonName={reasonName}
          exchangeItems={exchangeItems}
          afterProductsLoading={afterProductsLoading}
          onPrevClick={decrementStep}
          onNextClick={incrementStep}
          onChangedExchangeAfterItem={handleSelectedExchangeAfterItem}
        />
      )}

      {isStep(2) && (
        <Step3
          product={product}
          reason={reason}
          reasonName={reasonName}
          exchangeItems={exchangeItems}
          onPrevClick={decrementStep}
          onSubmit={handleSubmit}
        />
      )}

      {isStep(3) && <Thanks orderId={orderId} />}
    </Page>
  )
}
