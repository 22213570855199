import React from 'react'
import { Redirect } from '@reach/router'
import { useDispatch } from 'react-redux'
import { setResponse } from '../store/error'
import { setCompanyCode} from '../store/auth'
import { useAuth } from '../hooks/auth'

function GuestRoute({ as: Comp, companyCode, ...props }) {
  const dispatch = useDispatch()
  dispatch(setResponse(null))
  dispatch(setCompanyCode(companyCode))

  const isAuth = useAuth()

  return isAuth
    ? <Redirect to={`/${companyCode}`} noThrow />
    : <Comp companyCode={companyCode} {...props} />
}

export default GuestRoute
