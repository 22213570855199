import React from 'react'
import AppLink from '../../components/AppLink'
import { Button, Col, Row, Spin, Icon } from 'antd'
import Page from '../../components/Page'
import DealTable from '../../components/DealTable'
import RentalDealTable from '../../components/RentalDealTable'
import DealFooter from '../../components/DealFooter'
import { dateFormat, numFormat } from '../../formatter'
import billingApi from '../../api/billing'
import useBillingDetails from './hooks/billing-details'

function BillingShow({ companyId, storeId, year, month }) {
  const { billing, loading, isRental, setLoading } = useBillingDetails({ companyId, storeId, year, month })

  const downloadCsv = async () => {
    setLoading(true)
    await billingApi.csv({ companyId, storeId, term: `${year}-${month}` })
    setLoading(false)
  }

  return (
    <Page title="請求詳細">
      <div className="text-left">
        <AppLink to='/billings' >
          <Icon type="left" className="mr-1"/>
          <span>請求情報一覧に戻る</span>
        </AppLink>
      </div>
      <div className="mb-2 text-right">
        <Button type="default" onClick={downloadCsv}>
          CSV ファイル出力
        </Button>
      </div>

      {billing && <MetaInfo billing={billing} destinationName={storeId === undefined ? billing.companyName: billing.storeName}/>}

      <div className="mt-3 mb-3">
        <Spin spinning={loading}>
          {isRental ? (
            <RentalDealTable records={billing ? billing.details : []} />
          ) : (
            <DealTable records={billing ? billing.details : []} />
          )}
        </Spin>
      </div>

      {billing && <DealFooter item={billing} amountTitle='請求金額' />}
    </Page>
  )
}

function MetaInfo({ billing, destinationName }) {
  return (
    <div className="panel">
      <ul className="plain-list">
        <li>
          <Row>
            <Col span={6}>確定日</Col>
            <Col span={18}>{dateFormat(billing.billingConfirmedAt)}</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>対象期間</Col>
            <Col span={18}>
              {dateFormat(billing.termFrom)}
              {' '}〜{' '}
              {dateFormat(billing.termTo)}
            </Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>請求先</Col>
            <Col span={18}>{destinationName}</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>請求金額</Col>
            <Col span={18} className="text-bold">
              {numFormat(billing.totalAmount + billing.taxAmount)} 円
            </Col>
          </Row>
        </li>
      </ul>
    </div>
  )
}

export default BillingShow
