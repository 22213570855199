import React from 'react'

function SearchResult({ pageInfo, zeroMessage, children, forceError = false }) {
  if ((pageInfo && pageInfo.allCount === 0) || forceError) {
    return <div className="text-center">{zeroMessage}</div>
  }

  return children
}

export default SearchResult
