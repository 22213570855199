import { useEffect, useState } from 'react'
import accountsApi from '../../../api/accounts'

export default function useAccount(accountId) {
  const [account, setAccount] = useState(null)

  useEffect(() => {
    (async () => {
      const response = await accountsApi.show(accountId).catch(e => e.response)

      if (response.status === 200) {
        setAccount(response.data)
      }
    })()
  }, [accountId])

  return account
}
