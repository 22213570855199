import { useState } from 'react'

export default function useAddressInput(defaultValue) {
  const initialAddress = {
    addressee: '',
    postalCode: '',
    prefecture: '',
    prefectureId: null,
    city: '',
    houseNumber: '',
    buildingName: '',
    tel: '',
  }

  const [address, setAddress] = useState(
    (!defaultValue || defaultValue.shippingAddressId)
      ? initialAddress
      : defaultValue
  )

  const patchAddress = item => setAddress({ ...address, ...item })

  const resetAddress = () => setAddress(initialAddress)

  const isValidAddress = () => (
    address.addressee &&
    address.postalCode &&
    address.prefectureId &&
    address.city &&
    address.houseNumber &&
    address.tel
  )

  return { address, setAddress, patchAddress, resetAddress, isValidAddress }
}
