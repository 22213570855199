import React from 'react'
import moment from 'moment'
import { DatePicker, Select } from 'antd'

const { Option } = Select

export default function DeliveryTimePanel({
  deliveryTimes,
  onTimeChange,
  onDateChange
}) {
  const disabledDate = current =>  (
    current < moment().add(6, 'days') ||
    current > moment().add(28, 'days')
  )

  return (
    <div className="panel mb-2">
      <div className="mb-2">
        <div className="mb-1">
          <p>お届け希望日</p>
        </div>
        <div className="mb-1">
          <DatePicker
            disabledDate={disabledDate}
            style={{ width: "100%" }}
            onChange={(p) => onDateChange(p ? p.format('YYYY-MM-DD') : null)}
          />
        </div>
      </div>
      <div className="mb-1">
        <div className="mb-1">
          <p>お届け希望時間</p>
        </div>
        <div className="mb-1">
          <Select
            style={{ width: "100%" }}
            onChange={(p) => onTimeChange(p)}
            defaultValue={deliveryTimes[0].id}
          >
            {deliveryTimes ?
              deliveryTimes.map((time) => (
                <Option key={time.id} value={time.id}>{time.range}</Option>
              )) : ''
            }
          </Select>
        </div>
        <div>
          <p className="text-red">
            最短でのお届け希望の場合は、指定なしを選択してください。
          </p>
        </div>
      </div>
    </div>
  )
}
