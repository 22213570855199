import React from 'react'

function Image({ src, alt, caption, fit }) {
  let className = 'image'

  if (fit === 'contain') {
    className += ' is-contain'
  }

  return (
    <figure className={className}>
      <img src={src} alt={alt || ''} />
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  )
}

export default Image
