import { useState } from 'react'
import prefectureApi from '../../../api/prefecture'

export default function usePrefectures() {
  const [prefectures, setPrefectures] = useState([])

  const fetchPrefectures = async () => {
    const prefecturesResponse = await prefectureApi.index()
    setPrefectures(prefecturesResponse.data)
  }

  return [prefectures, fetchPrefectures]
}
