import React, { useEffect } from 'react'
import usageStatusApi from '../../../api/usage-status'
import { Button, Row, Col, List, Divider, Card } from 'antd'
import { NEXT_DELIVERY_CHANGE_COMPLETE, NEXT_DELIVERY_CHANGE_INPUT } from '../util/constants'
import NextDeliveryChangeConfirmTable from '../component/NextDeliveryChangeConfirmTable'
import { RESPONSE_OK } from '../../../constants'
import { notifyError } from '../../../notify'

// 次回配送内容変更確認
const NextDeliveryChangeConfirm = ({editedItems, setScene, setLoading}) => {
  useEffect(() => { window.scrollTo(0, 0)}, [])
  return (
    <div className="mt-3 mb-3">
      <List itemLayout="horizontal" dataSource={editedItems} renderItem={staff => (
        <List.Item>
          <Card size="small" style={listStyle} headStyle={{ backgroundColor: `#fafafa` }} title={
            <Row type="flex" align="middle" justify="space-between">
              <Col><div> {staff.staffNo} <Divider type="vertical"/> {staff.staffName} <Divider type="vertical"/> {staff.storeName}</div></Col>
            </Row>
          }>
            <NextDeliveryChangeConfirmTable
              dataSource={staff.beforeItemStatuses}
              editedItems={editedItems}
            />
          </Card>
        </List.Item>
      )}/>
      <Divider />
      <div className="text-right">
        <Button htmlType="button" className="ml-1" onClick={() => setScene(NEXT_DELIVERY_CHANGE_INPUT)}>戻る</Button>
        <Button htmlType="button" className="ml-1" type="primary" onClick={async () => {
          setLoading(true)
          const response = await usageStatusApi.update({
            itemStatuses: editedItems.map(item => item.afterItemStatuses).flat(1).map(item => ({
              itemStatusId: item.itemStatusId,
              nextDeliveryItemPNo: item.nextDeliveryItemPNo,
              nextDeliveryItemCount: item.nextDeliveryItemCount,
              isStopNextDelivery: item.isSuspendedDelivery,
              nextDeliveryMonth: Number(item.nextDeliveryMonth.replace('/', ''))
            }))
          })
          if (response.status !== RESPONSE_OK) {
            notifyError({ message : '通信に失敗しました'})
            setLoading(false)
            return
          }
          setLoading(false)
          setScene(NEXT_DELIVERY_CHANGE_COMPLETE)
        }}>変更を実行する</Button>
      </div>
    </div>
  )
}
const listStyle = { width: '100%' }

export default NextDeliveryChangeConfirm

