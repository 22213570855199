import httpClient from '../http'

export function summary() {
  return httpClient.get(`/api/rental-status/summary`)
}

export function summaryProduct(accountId, pNo) {
  const url = `/api/rental-status/summary/accounts/${accountId}/products/${pNo}`
  return httpClient.get(url)
}

export function products(accountId, params) {
  return httpClient.get(`/api/rental-status/accounts/${accountId}`, { params })
}

export function exchangeFromCounts(accountId, pNo) {
  const url = `/api/rental-status/accounts/${accountId}/products/${pNo}/exchange-from-counts`
  return httpClient.get(url)
}
