import React from 'react'
import Image from './Image'

export function PictureWall({ items, onChange }) {
  return (
    <div className="picture-wall">
      <ul className="picture-wall-list">
        {items.map((item, index) => (
          <li
            key={index}
            className="picture-wall-thumbnail"
            onClick={onChange.bind(this, items[index])}
          >
            <Image src={item} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PictureWall
