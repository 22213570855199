import { useEffect, useState } from 'react'
import orderApi from '../api/order'

export function useOrderStatusSelect() {
  const [orderStatus, setOrderStatus] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const orderRes = await orderApi.getStatus()
      orderRes.data.forEach(res => {
        res.statusCode = 'o' + res.statusCode
      })

      const returnRes = await orderApi.getReturnStatus()
      returnRes.data.forEach(res => {
        res.statusCode = 'r' + res.statusCode
      })
      const statuses = orderRes.data
      returnRes.data.map(ret => 
        statuses.push(ret)
      )
      setOrderStatus(statuses)
    }
    fetch()
  }, [])

  return orderStatus
}
