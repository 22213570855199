import { useState, useEffect } from 'react'
import { summary as rentalStatusSummaryApi } from '../../../api/rental-status'

export default function useRentalStatusSummary() {
  const [summary, setSummary] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)

      const response = await rentalStatusSummaryApi().catch(e => e.response)

      if (response.status === 200) {
        setSummary(response.data)
      } else {
        setError(true)
      }

      setLoading(false)
    })()
  }, [])

  return { summary, summaryLoading: loading, summaryError: error }
}
