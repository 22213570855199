import React, { useState } from 'react'
import { Form, Radio, Modal, Spin, Row, Col, Divider, Input  } from 'antd'
import '../../styles/modal.less'

const MAX_INPUT_COUNT = 999999

// 在庫変更モーダル
const StockChangeModal = ({ onOk, onCancel, selectedStock }) => {
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [isDecrease, setIsDecrease] = useState(0)

  return (
    <Modal
      title="在庫数の更新"
      visible={!!selectedStock}
      okText="更新する"
      okType="primary"
      okButtonProps={{ disabled: count < 1}}
      cancelText="戻る"
      onOk={() => onOk(selectedStock.pno, isDecrease ?
        selectedStock.allStockCount - count :
        selectedStock.allStockCount + count
      , setLoading)}
      onCancel={onCancel}
    >
      <Spin spinning={loading}>
        <Form layout="vertical" colon={false} labelAlign="left">
          <p className="mb-2">品番：{selectedStock.pno}</p>
          <p className="mb-2">商品名：{selectedStock.title}</p>
          <p className="mb-2">サイズ：{selectedStock.size}</p>
          <Divider style={{marginTop: 10, marginBottom: 10}}/>
          <p>倉庫在庫数（変更前）：{selectedStock.allStockCount} 個</p>
          <div className="mb-2">
            <span className="mb-2">倉庫在庫数（変更後）：</span>
            {count < 1 ? (
              <span className="mt-2"> </span>
            ) : (
              <span className="mt-2 text-red">{
                isDecrease ?
                  selectedStock.allStockCount - count :
                  selectedStock.allStockCount + count
              } 個</span>
            )}
          </div>
          <Divider style={{marginTop: 10, marginBottom: 10}}/>
          <Form.Item className="mb-2">
            <Row justify="start" align='middle' gutter={0}>
              <Col span={4}>
                <Radio.Group value={isDecrease} direction="vertical" onChange={(e) => {
                  setIsDecrease(e.target.value)
                  setCount(0)
                }}>
                  <Radio value={0}>増やす</Radio>
                  <Radio value={1}>減らす</Radio>
                </Radio.Group>
              </Col>
              <Col span={4} style={{ marginTop: '6px' }}>
                <Input
                  value={count}
                  max={isDecrease ? selectedStock.stockCount: MAX_INPUT_COUNT}
                  onChange={(e) => {
                    const { value } = e.target;
                    const inputValue = toHalfWidth(value).replace(/[^0-9]/g, '')
                    const numericValue = inputValue.length ? parseInt(inputValue.replace(/[^0-9]/g, '')) : 0;
                    const overInventory = isDecrease && selectedStock.stockCount < numericValue
                    const overOutOfRange = MAX_INPUT_COUNT < numericValue
                    if (!overInventory && !overOutOfRange) {
                      setCount(numericValue)
                    }
                  }}
                />
              </Col>
              {selectedStock.allStockCount - selectedStock.stockCount > 0 &&
                <Col className="text-red" span={15} style={{ fontSize: '10px', marginLeft: '10px', marginTop: '6px' }}>
                  出荷待ち数量が {selectedStock.allStockCount - selectedStock.stockCount} 個あるため、<br/>
                  減らす入力が出来る最大値は {selectedStock.stockCount} 個です
                </Col>
              }
            </Row>
          </Form.Item>
        </Form>
        <Divider style={{marginTop: 10, marginBottom: 10}}/>
        <p align='middle'>上記の変更後の在庫数で更新しますか？</p>
      </Spin>
    </Modal>
  )
}

function toHalfWidth(str) {
  return str.replace(/[０-９]/g, function(match) {
      return String.fromCharCode(match.charCodeAt(0) - 65248);
  });
}

export default StockChangeModal