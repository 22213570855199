import React, { useEffect, useState } from 'react'
import { Alert } from 'antd'
import Page from '../components/Page'
import ProductSearchBody from '../components/ProductSearchBody'
import useShippingMessage from '../hooks/shipping-message'
import noticeApi from '../api/notice'
import { sanitizeText } from '../sanitize'
import { RESPONSE_INVALID, RESPONSE_OK } from '../constants'

function ProductSearch() {
  const shippingMessage = useShippingMessage()

  const [notice, setNotice] = useState(null)

  useEffect(() => {
    (async () => {
      const response = await noticeApi.get()
      if (response.status === RESPONSE_OK) {
        setNotice(response.data)
      }
    })()
  }, [])

  return (
    <Page title="商品を探す">
    {notice && (
      <>
        {notice.map(notice => (
          <div className="message is-alert mb-2" key={notice.id}>
            <p className="text-bold">{notice.title}</p>
            <p style={{ margin: 0 }}
            dangerouslySetInnerHTML={
              {__html: sanitizeText(notice.content)}
            }/>
          </div>
        ))}
      </>
    )}
      {shippingMessage && (
        <div className="mb-2">
          <Alert message={shippingMessage} type="info" showIcon />
        </div>
      )}
      <ProductSearchBody
        renderDetailLink={productId => `/products/${productId}`}
      />
    </Page>
  )
}

export default ProductSearch
