import React from 'react'
import { useDispatch } from 'react-redux'
import { setResponse } from '../store/error'

function PasswordChangeRoute({ as: Comp, ...props }) {
  const dispatch = useDispatch()
  dispatch(setResponse(null))
  return <Comp {...props} />
}

export default PasswordChangeRoute
