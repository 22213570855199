import React, { useEffect, useMemo } from 'react'
import LinkButton from '../../../components/LinkButton'
import { Button, Row, Col, List, Divider, Card } from 'antd'
import NextDeliveryChangeInputTable from '../component/NextDeliveryChangeInputTable'
import { extractEditedItems } from '../util/compute'
import { NEXT_DELIVERY_CHANGE_CONFIRM } from '../util/constants'

// 次回配送内容変更入力
const NextDeliveryChangeInput = ({nextDeliveryChangeItems, setNextDeliveryChangeItems, setScene, setEditedItems}) => {
  useEffect(() => { window.scrollTo(0, 0)}, [])
  return (
    <div className="mt-3 mb-3">
      <List itemLayout="horizontal" dataSource={nextDeliveryChangeItems} renderItem={staff => (
        <List.Item>
          <Card size="small" style={listStyle} headStyle={{ backgroundColor: `#fafafa` }} title={
            <Row type="flex" align="middle" justify="space-between">
              <Col><div> {staff.staffNo} <Divider type="vertical"/> {staff.staffName} <Divider type="vertical"/> {staff.storeName}</div></Col>
            </Row>
          }>
            <NextDeliveryChangeInputTable
              dataSource={staff.afterItemStatuses}
              nextDeliveryChangeItems={nextDeliveryChangeItems}
              setNextDeliveryChangeItems={setNextDeliveryChangeItems}
            />
          </Card>
        </List.Item>
      )}/>
      <Divider />
      <div className="text-right">
        <LinkButton to='/usage-status' app><Button htmlType="button" className="ml-1">戻る</Button></LinkButton>
        <Button htmlType="button" type="primary" disabled={
          !extractEditedItems({nextDeliveryChangeItems: nextDeliveryChangeItems}).length
        } onClick={() => {
          setEditedItems(extractEditedItems({nextDeliveryChangeItems: nextDeliveryChangeItems}))
          setScene(NEXT_DELIVERY_CHANGE_CONFIRM)
        }}>
          変更内容を確認する
        </Button>
      </div>
    </div>
  )
}
const listStyle = { width: '100%' }

export default NextDeliveryChangeInput

