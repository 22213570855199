import React from 'react'
import Page from '../components/Page'
import NotFound from '../components/NotFound'

function NoMatch() {
  return (
    <Page>
      <NotFound />
    </Page>
  )
}

export default NoMatch
