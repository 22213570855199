import { Collapse, Divider, Icon, List, Popover, Typography, Spin, Row, Col } from 'antd'
import React, { useState } from 'react'
import productApi from '../api/product'
import ProductSet from '../models/Product'
import { RESPONSE_OK } from '../constants'
import { dateFormat, numFormat } from '../formatter'

export function SetContentsCollapse({ item, title }) {

  const [detail, setDetail] = useState(new ProductSet([]))
  const [loaded, setloaded] = useState(false)

  const handleOnClick = () => {
      if (loaded) return () => {}
      (async () => {
        const response = await productApi.setContents(item.pNo)
        if (response.status === RESPONSE_OK) {
          setDetail(response.data)
          setloaded(true)
        }
      })()
  }

  return (
    <Collapse
      style={{ backgroundColor: `#f5f5f5`}}
      bordered={false}
      onChange={handleOnClick}
      expandIcon={({ isActive }) => (
        <Icon type="caret-right" rotate={isActive ? 90 : 0} />
      )}
    >
      <Collapse.Panel
        key={1}
        header={<span className="text-sm">{title ? title : item.title}</span>}
        style={{ border: 0 }}
      >
        <SetContents items={detail} loaded={loaded} />
      </Collapse.Panel>
    </Collapse>
  )
}

const { Text } = Typography

export function SetContentsPopover({ items }) {
  return (
    <Popover
      trigger="click"
      placement="bottom"
      content={<SetContents items={items} bordered={false} />}
      overlayStyle={{ minWidth: 300 }}
    >
      <span className="icon-text">
        <Icon type="caret-right" />
        <Text underline className="clickable">セット内容</Text>
      </span>
    </Popover>
  )
}

export default function SetContents({ items, bordered = true, loaded }) {

  if(!loaded){return (<Spin spinning={!loaded}></Spin>)} 
  return (
    <List
      style={{ backgroundColor: '#fff' }}
      size="small"
      bordered={bordered}
      dataSource={items}
      renderItem={setItem => (
        <List.Item>
          <div style={{ width: `100%` }}>
            <Row type="flex" align="middle">
              <Col span={14}>
                <List.Item.Meta
                  title={
                    <>
                      <span>{setItem.title}</span>
                      <Divider type="vertical" />
                      <span>{setItem.size}</span>
                    </>
                  }
                  description={
                    <span className="text-sm">{setItem.pNo}</span>
                  }
                />
              </Col>
              <Col span={6}>
                <div>在庫 {numFormat(setItem.stockCount)} 点</div>
                {setItem.expectedDate && (
                  <div className="text-sm mt-1">
                    入庫予定 {numFormat(setItem.expectedCount)} 点
                    （{dateFormat(setItem.expectedDate)}）
                  </div>
                )}
              </Col>
              <Col span={4}>
                <div>{setItem.setCount}点</div>
              </Col>
            </Row>
          </div>
          
        </List.Item>
      )}
    />
  )
}
