import { useEffect, useState } from 'react'
import paymentApi from '../../../api/payment'
import { RESPONSE_OK } from '../../../constants'
import { useCurrentUser } from '../../../hooks/auth'

export default function usePaymentDetails({ companyId, storeId, year, month }) {
  const [payment, setPayment] = useState(null)
  const [loading, setLoading] = useState(false)

  const currentUser = useCurrentUser()

  useEffect(() => {
    (async () => {
      if (!companyId || !year || !month) return () => {}

      setLoading(true)

      const response = currentUser.isRental
        ? await paymentApi.showRental(companyId, year, month)
        : storeId === undefined ?
            await paymentApi.showWithCompany(companyId, year, month):
            await paymentApi.showWithStore(companyId, storeId, year, month)

      if (response.status === RESPONSE_OK) {
        setPayment(response.data)
      }

      setLoading(false)
    })()
  }, [companyId, storeId, year, month, currentUser.isRental])

  return { payment, loading }
}
