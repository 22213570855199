import httpClient, { fileClient } from '../http'
import queryString from 'query-string';
import download from '../download';
import { RESPONSE_OK } from '../constants'
import { saveAs } from 'file-saver'

export default {
  search(params) {
    return httpClient.get(`/api/orders`, {
      params,
      paramsSerializer: queryString.stringify
    })
  },

  show(id) {
    return httpClient.get(`/api/orders/${id}`)
  },

  approve(id) {
    return httpClient.put(`/api/orders/${id}/approval`)
  },

  cancel(id) {
    return httpClient.put(`/api/orders/${id}/cancel`)
  },
  
  async outputReturnNote(id) {
    const url = `/api/orders/${id}/return-note`
    const response = await fileClient.get(url)

    if (response.status === RESPONSE_OK) {
      const blob = new Blob([response.data], { type : "application/pdf" })
      saveAs(blob, `return_${id}.pdf`)
    }
  },

  getStatus() {
    return httpClient.get(`/api/orders/status`)
  },

  getReturnStatus() {
    return httpClient.get(`/api/orders/return-status`)
  },

  async csv(params) {
    const url = `/api/orders/downloadcsv`
    const response = await fileClient.get(url, { params })

    if (response.status === RESPONSE_OK) {
      const fileName = `order_history_list.csv`
      download(response.data, fileName)
    }
  },
}
