import React from 'react'
import { Modal } from 'antd'

// 注文承認モーダル
export default function ApproveModal({ id, visible, onOk, onCancel }) {
  return (
    <Modal
      title="注文を承認する"
      visible={visible}
      okText="注文を承認する"
      cancelText="戻る"
      onOk={onOk}
      onCancel={onCancel}
    >
      <p className="mb-2">注文番号：{id}</p>
      <p className="mb-2">
        注文の承認を行うと注文が確定され、発送準備に入ります。
      </p>
      <p>この注文の承認を行いますか？</p>
    </Modal>
  )
}
