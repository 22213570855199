import moment from 'moment'
export class ItemStatusResponseData {
  constructor(data) { this.data = data }
  staffs = () => (this.data && (this.data.staffs ?? [])).map(item => new Staff(item))
  pageInfo = () => { const { staffs, ...pageInfo } = this.data; return pageInfo }
}
export class Staff {
  constructor(data) {
    this.staffNo = data.staffNo
    this.staffName = data.staffName
    this.storeId = data.storeId
    this.storeName = data.storeName
    this.itemStatuses = (data.itemStatuses ?? []).map(item => new ItemStatus(item))
  }
}
export class ItemStatus {
  constructor(data) {
    this.itemStatusId = data.itemStatusId
    this.useItemPNo = data.useItemPNo
    this.useItemTitle = data.useItemTitle
    this.useItemSize = data.useItemSize
    this.useItemCount = data.useItemCount
    this.useItemProductType = data.useItemProductType === 'S' ? "新品" : data.useItemProductType === 'A' ? "中古" : ""
    this.nextDeliveryItemPNo = data.nextDeliveryItemPNo
    this.nextDeliveryItemTitle = data.nextDeliveryItemTitle
    this.nextDeliveryItemSize = data.nextDeliveryItemSize
    this.nextDeliveryItemCount = data.nextDeliveryItemCount
    this.firstOrderDate = data.firstOrderDate
    this.nextDeliveryMonth = (data.nextDeliveryMonth ?? "").slice(0, 4) + "/" + (data.nextDeliveryMonth ?? "").slice(4, 6)
    // 変更期限日付は "2023/01/01 00:00:00" のように登録しているため、表記用の期日として "2022/12/31 23:59:59" → "2022-12-31" として変換表記している
    this.closingDate = moment(data.closingDate, "YYYY-MM-DD").subtract(1, 'second').format('YYYY-MM-DD')
    this.isSuspendedDelivery = data.isStopped
    this.isExpiredChangeDeadline = data.isClosingExpired
    this.productDetailList = (data.pNoList ?? []).map(item => new ProductOmit(item))
    this.barcodeList = (data.barcodeList ?? []).map(item => new BarcodeOmit(item))
    this.barcodeText = (data.barcodeList ?? []).map(item => item.barcode).join("、")
    this.existsNextDelivery = data.nextDeliveryItemTitle !== null
  }
}
export class ProductOmit {
  constructor(data) {
    this.pNo = data.pNo
    this.size = data.size
  }
}

export class BarcodeOmit {
  constructor(data) {
    this.id = data.barcodeId
    this.barcode = data.barcode
  }
}