import React from 'react'
import { Redirect } from '@reach/router'
import { useCompanyCode, useIsAdmin } from '../hooks/auth'

function Home() {
  const isAdmin = useIsAdmin()

  // 権限ごとにトップページを設定
  const to = isAdmin ? 'account' : 'products'

  const companyCode = useCompanyCode()

  return <Redirect to={`/${companyCode}/${to}`} noThrow />
}

export default Home
