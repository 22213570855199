import httpClient from '../http'

export default {
  search(params) {
    return httpClient.get(`/api/forums`, { params })
  },

  show(id){
    return httpClient.get(`/api/forums/${id}`)
  },

  getType(){
    return httpClient.get(`/api/forums/types`)
  },

  create(data) {
    return httpClient.post(`/api/forums`, data)
  },

  complete(id){
    return httpClient.get(`/api/forums/${id}/complete`)
  },

  comment(id, data) {
    return httpClient.post(`/api/forums/${id}/comment`, data)
  },
}
