import httpClient from '../http'

export default {
  update(data) {
    return httpClient.post(`/api/staff`, data )
  },

  check(params) {
    return httpClient.get(`/api/staff/check`, { params })
  }
}