import { useEffect, useState } from 'react'
import accountApi from '../api/accounts'

export default function useShippingMessage() {
  const [shippingMessage, setShippingMessage] = useState("")

  useEffect(() => {
    const fetch = async () => {
      const response = await accountApi.shippingMessage()
      setShippingMessage(response.data.message)
    }
    fetch()
  }, [])

  return shippingMessage
}
