import httpClient from '../http'

export default {
  index() {
    return httpClient.get(`/api/guides`)
  },

  show(id) {
    return httpClient.get(`/api/guides/${id}`)
  },
}
