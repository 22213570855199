import React from 'react'
import AppLink from '../../components/AppLink'
import { Button, Col, Row, Spin, Icon } from 'antd'
import Page from '../../components/Page'
import DealTable from '../../components/DealTable'
import RentalDealTable from '../../components/RentalDealTable'
import DealFooter from '../../components/DealFooter'
import { dateFormat, numFormat } from '../../formatter'
import usePaymentDetails from './hooks/payment-details'
import { useCurrentUser } from '../../hooks/auth'
import paymentApi from '../../api/payment'

function PaymentShow({ companyId, storeId, year, month }) {
  const currentUser = useCurrentUser()
  const { payment, loading } = usePaymentDetails({ companyId, storeId, year, month })

  const downloadCsv = async () => {
    await paymentApi.csv({ companyId, storeId, term: `${year}-${month}` })
  }

  return (
    <Page title="お支払いの詳細">
      <div className="text-left">
        <AppLink to='/payments' >
          <Icon type="left" className="mr-1"/>
          <span>支払情報一覧に戻る</span>
        </AppLink>
      </div>
      <div className="mb-2 text-right">
        <Button type="default" onClick={downloadCsv}>
          CSV ファイル出力
        </Button>
      </div>

      {payment && <MetaInfo payment={payment} />}

      <div className="mt-3 mb-3">
        <Spin spinning={loading}>
          {currentUser.isRental ? (
            <RentalDealTable records={payment ? payment.details : []} />
          ) : (
            <DealTable records={payment ? payment.details : []} />
          )}
        </Spin>
      </div>

      {payment && <DealFooter item={payment}  amountTitle='支払金額' />}
    </Page>
  )
}

function MetaInfo({ payment }) {
  return (
    <div className="panel">
      <ul className="plain-list">
        <li>
          <Row>
            <Col span={6}>確定日</Col>
            <Col span={18}>{dateFormat(payment.paymentConfirmedAt)}</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>対象期間</Col>
            <Col span={18}>
              {dateFormat(payment.termFrom)}
              {' '}〜{' '}
              {dateFormat(payment.termTo)}
            </Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>支払先</Col>
            <Col span={18}>{payment.companyName}</Col>
          </Row>
        </li>
        {payment.storeName &&
          <li>
            <Row>
              <Col span={6}>支払者</Col>
              <Col span={18}>{payment.storeName}</Col>
            </Row>
          </li>
        }
        <li>
          <Row>
            <Col span={6}>支払金額</Col>
            <Col span={18} className="text-bold">
              {numFormat(payment.totalAmount + payment.taxAmount)} 円
            </Col>
          </Row>
        </li>
      </ul>
    </div>
  )
}

export default PaymentShow
