import { saveAs } from 'file-saver'

export default function(content, fileName) {
  const blob = new Blob([content], { type : "text/csv" })
  saveAs(blob, fileName)
}

export function downloadExcel(content, fileName) {
  const blob = new Blob([content], {
    type : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  })
  saveAs(blob, fileName)
}
