import { useEffect, useState } from 'react'
import storeApi from '../../../api/store'
import { RESPONSE_OK } from '../../../constants'

const useStore = () => {
  const [stores, setStores] = useState([])
  const [groupStores, setGroupStores] = useState([])

  useEffect(() => {
    (async () => {
      const response = await storeApi.store()
      if (response.status === RESPONSE_OK) {
        setStores(response.data)
      }
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const response = await storeApi.groups()
      if (response.status === RESPONSE_OK) {
        setGroupStores(response.data)
      }
    })()
  }, [])

  return { stores, groupStores }
}

export default useStore