import React from 'react'
import { InputNumber } from 'antd'

function NumInput({
  value,
  onChange,
  min = 0,
  max,
  fullWidth = true,
  disabled = false,
  allowNegative = false
}) {
  const props = { min, value, onChange: val => onChange(val || 0), disabled }

  if (max) {
    props.max = max
  }

  if (fullWidth) {
    props.style = { width: `100%` }
  }

  if (allowNegative) {
    props.min = -Infinity
  }

  return <InputNumber {...props} />
}

export default NumInput
