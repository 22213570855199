import React, { useState } from 'react'
import { Checkbox, Col, Input, Row } from 'antd'
import useStore from '../../UsageStatusSearch/hooks/store'
import { useCurrentUser, useStatusUseType } from '../../../hooks/auth'
import {
  USAGE_STATUS_TYPE1
} from '../../../constants'

const useStaffInfo = () => {
  const user = useCurrentUser()
  const [inputStaffInfo, setInputStaffInfo] = useState({
    staffNo: '', staffName: '', storeName: user.store.storeName, staffStoreId: user.store.storeId, isPersonalOrder: false
  })
  const [currentStaffInfo, setCurrentStaffInfo] = useState({isConfirm: false, staffName: '', storeName: '' })
  const staffNoInputError = () => {
    return !inputStaffInfo.staffNo.length ? '入力が必須です' :
           inputStaffInfo.staffNo.length > 25 ? '25文字以内で入力して下さい' :
           !inputStaffInfo.staffNo.match(/^[A-Za-z0-9]*$/) ? '半角英数字で入力して下さい' : ''
  }
  const staffNameInputError = () => {
    return !inputStaffInfo.staffName.length ? '入力が必須です' :
           inputStaffInfo.staffNo.length > 50 ? '50文字以内で入力して下さい' : ''
  }
  const staffStoreInputError = () => {
    return !String(inputStaffInfo.staffStoreId).length ? '入力が必須です' : ''
  }
  const inputErrors = {
    staffNo: staffNoInputError(),
    staffName: staffNameInputError(),
    storeId: staffStoreInputError()
  }
  const existsStaffInputError = Object.values(inputErrors).filter(item => item !== '').length
  return { inputStaffInfo, setInputStaffInfo, currentStaffInfo, setCurrentStaffInfo, inputErrors, existsStaffInputError }
}

// 社員情報入力
const StaffInfoInput = ({ staffInfo, setStaffInfo, inputErrors }) => {
  const { stores } = useStore()
  const { isStatusUseType } = useStatusUseType()
  return (
    <div className="mb-4">
      <p className="text-bold">社員情報</p>
      <div className="mb-2">
        <Row type="flex" align="middle" justify="start" gutter={20}>
          <Col span={8} >
            <div className='mt-3' style={{display: 'flex', justifyContent: 'space-between', marginTop: "0px", marginBottom: "10px"}}>
              <span >社員番号</span>
              <span className='text-sm text-red'>{inputErrors.staffNo}</span>
            </div>
            <Input value={staffInfo.staffNo} onChange={(event) =>
              setStaffInfo(Object.assign({}, staffInfo, {'staffNo': event.target.value}))
            }/>
          </Col>
          <Col span={8} >
            <div className='mt-3' style={{display: 'flex', justifyContent: 'space-between', marginTop: "0px", marginBottom: "10px"}}>
              <span >社員名</span>
              <span className='text-sm text-red'>{inputErrors.staffName}</span>
            </div>
            <Input value={staffInfo.staffName} onChange={(event) =>
              setStaffInfo(Object.assign({}, staffInfo, {'staffName': event.target.value}))
            }/>
          </Col>
          {/* <Col span={6} >
            <div className='mt-3' style={{display: 'flex', justifyContent: 'space-between', marginTop: "0px", marginBottom: "10px"}}>
              <span >所属支店</span>
              <span className='text-sm text-red'>{inputErrors.storeId}</span>
            </div>
            <Select showSearch allowClear optionFilterProp="children" value={staffInfo.staffStoreId} style={{ width: '100%' }} onChange={(val) => {
              const store = stores.find(item => item.storeId === val)
              const storeId = store === undefined ? '' : store.storeId
              const storeName = store === undefined ? '' : store.storeName
              setStaffInfo(Object.assign({}, staffInfo, {'staffStoreId': storeId, 'storeName': storeName}))
            }}>
              {stores && stores.map(item => <Select.Option key={item.storeId} value={item.storeId}>{item.storeName}</Select.Option>)}
            </Select>
          </Col> */}
          {isStatusUseType(USAGE_STATUS_TYPE1) &&
            <Col span={4} className='mt-3' style={{width: '120px', border: '1px solid #A4A4A4', backgroundColor: '#fafafa'}}>
              <Checkbox style={{height: "28px", marginTop: "6px"}} defaultChecked={staffInfo.isPersonalOrder} value={staffInfo.isPersonalOrder} onChange={event =>
                setStaffInfo(Object.assign({}, staffInfo, {'isPersonalOrder': event.target.checked}))
              }> 個人購入</Checkbox>
            </Col>
          }
        </Row>
      </div>
    </div>
  )
}

export { useStaffInfo, StaffInfoInput }
