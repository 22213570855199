import { useEffect, useState } from 'react'
import usageStatusApi from '../../../api/usage-status'
import { RESPONSE_OK } from '../../../constants'
import { ItemStatusResponseData, Staff } from '../entity/ItemStatus'
import scrollTop from '../../../scroll-top'

const useSearchUsageStatus = (setPageLoading, setDataLoading, searchParams) => {

  const initialQuery = Object.assign({
    nextDeliveryMonth: searchParams.nextDeliveryMonth || '',
    staffNo: searchParams.staffNo || null,
    staffName: searchParams.staffName || null,
    storeId: searchParams.storeId * 1 || null,
    barcode: searchParams.barcode || null,
    page: searchParams.page * 1 || 1,
  })
  const [query, setQuery] = useState(initialQuery)
  const [searchItem, setSearchItem] = useState(initialQuery)
  const [usageStatusItems, setUsageStatusItems] = useState([].map(_ => new Staff()))
  const [pageInfo, setPageInfo] = useState({allCount: 0, currentPage: 1})

  const downloadCsv = async () => {
    setPageLoading(true)
    await usageStatusApi.csv(query)
    setPageLoading(false)
  }

  const search = async () => {
    setPageLoading(true)
    const response = await usageStatusApi.search(query)
    if (response.status === RESPONSE_OK) {
      const data = new ItemStatusResponseData(response.data)
      setUsageStatusItems(data.staffs)
      setPageInfo(data.pageInfo)
    }
    setPageLoading(false)
  }

  const searchProduct = async () => {
    if (setDataLoading !== null) {
      setDataLoading(true)
      const result = await usageStatusApi.searchProductData(query)
      if (result.status === RESPONSE_OK) {
        const data = new ItemStatusResponseData(result.data)
        setUsageStatusItems(data.staffs)
      }
      setDataLoading(false)
    }
  }

  useEffect(() => {
    (async () => {
      scrollTop()
      await Promise.all([search(), searchProduct()])
    })()
  }, [query])

  return { query, setQuery, searchItem, setSearchItem, pageInfo, usageStatusItems, downloadCsv }
}

export default useSearchUsageStatus