import React, { useEffect, useMemo, useState } from 'react'
import { appNavigate } from '../components/AppLink'
import moment from 'moment'
import {
  Form,
  Button,
  Row,
  Col,
  List,
  DatePicker,
  Icon,
  Spin,
  Select,
} from 'antd'
import Page from '../components/Page'
import Pagination from '../components/Pagination'
import SearchResult from '../components/SearchResult'
import { numFormat } from '../formatter'
import paymentApi from '../api/payment'
import { MONTH_FORMAT, RESPONSE_OK, SORT_ASC, SORT_DESC } from '../constants'

const { MonthPicker } = DatePicker
const { Option } = Select

const inputStyle = { width: '100%' }

function PaymentSummary() {
  const [payments, setPayments] = useState([])
  const [pageInfo, setPageInfo] = useState(null)
  const [term, setTerm] = useState(moment())
  const [sort, setSort] = useState(SORT_ASC)
  const termText = useMemo(() => term ? term.format('YYYY-MM') : '', [term])
  const year = useMemo(() => termText.split('-')[0], [termText])
  const month = useMemo(() => termText.split('-')[1], [termText])
  const [query, setQuery] = useState({ term: termText, page: 1, sort: sort })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      // 年月が指定されないと検索できない
      if (query.term === '') return () => {}
      setLoading(true)
      const response = await paymentApi.summary(query)
      if (response.status === RESPONSE_OK) {
        const { payments: paymentList, ...rest } = response.data
        setPayments(paymentList)
        setPageInfo(rest)
      }
      setLoading(false)
    })()
  }, [query])

  const summaryCsv = async () => {
    await paymentApi.summaryCsv({ term: termText })
  }

  const handlePaginate = page => {
    setQuery(Object.assign({}, query, { page }))
  }

  const handleSearch = e => {
    e.preventDefault()
    setQuery(Object.assign({}, query, { term: termText, page: 1, sort: sort }))
  }

  const handleLink = (item) => {
    appNavigate('/orders', {  
      from: item.termFrom,
      to: item.termTo,
      storeId: item.storeId,
      companyId: item.companyId, })
  }

  return (
    <Page title="店舗ごとの集計">
      <div className="mb-3">
        <Form
          layout="vertical"
          colon={false}
          labelAlign="left"
          onSubmit={handleSearch}
        >
          <div className="panel">
            <Row gutter={24} type="flex" align="middle">
              <Col span={6}>
                <Form.Item label="対象月">
                  <MonthPicker
                    style={inputStyle}
                    format={MONTH_FORMAT}
                    placeholder="月を選択"
                    value={term}
                    onChange={term => setTerm(term)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
              <Form.Item label="並び替え">
                <Select defaultValue={SORT_ASC} onChange={sort => setSort(sort)}>
                  <Option value={SORT_ASC}>集計金額の高い順</Option>
                  <Option value={SORT_DESC}>集計金額の低い順</Option>
                </Select>
              </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="mt-3 text-center">
            <Button htmlType="submit" type="primary" disabled={!term}>
              検索
            </Button>
            <Button htmlType="button" className="ml-1" onClick={summaryCsv}>
              CSVファイル出力
            </Button>
          </div>
        </Form>
      </div>

      {payments.length !== 0 && (
        <div className="mt-1">
          <p className="message is-small">すべての注文ステータスを含む注文履歴へ遷移します。</p>
        </div>
      )}

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}

      <div className="mt-3 mb-3">
        <Spin spinning={loading}>
          <SearchResult
            pageInfo={pageInfo}
            zeroMessage="お支払い情報が見つかりませんでした。条件を変更してもう一度検索してください。"
          >
            <List
              itemLayout="horizontal"
              dataSource={payments}
              renderItem={item => renderItem(item, year, month, handleLink)}
            />
          </SearchResult>
        </Spin>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}
    </Page>
  )
}

function renderItem(item, year, month, handleLink) {

  return (
    <List.Item
      actions={[
        <span className="text-header">
          {numFormat(item.totalAmount + item.taxAmount)} 円
        </span>,
        <Button
          onClick={() => handleLink(item)}
          type="primary"
          size="small"
          ghost
        >
          <span>注文履歴へ</span>
          <Icon type="right" />
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={item.storeName}
      />
    </List.Item>
  )
}

export default PaymentSummary
