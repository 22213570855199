import { useEffect, useState } from 'react'
import cartApi from '../api/cart'
import { RESPONSE_OK } from '../constants'

export default function useDeliveryTimes() {
  const [deliveryTimes, setDeliveryTimes] = useState([])
  const [timeId, setTimeId] = useState(null)
  const [deliveryDate, setDeliveryDate] = useState(null)

  const fetchDeliveryTimes = async () => {
    const response = await cartApi.deliveryTimes()

    if (response.status === RESPONSE_OK) {
      setDeliveryTimes(response.data)
      setTimeId(response.data[0].id)
    }
  }

  useEffect(() => {
    fetchDeliveryTimes()
  }, [])

  return {
    deliveryTimes,
    fetchDeliveryTimes,
    timeId,
    setTimeId,
    deliveryDate,
    setDeliveryDate,
  }
}
