import React, { useEffect } from 'react'
import LinkButton from '../../../components/LinkButton'

const NextDeliveryChangeComplete = () => {
  useEffect(() => window.scrollTo(0, 0), [])
  return (
    <div>
      <div className="text-lg text-center mb-2">
        次回発送内容を変更しました。
      </div>
      <div className="text-center mb-4"/>
      <div className="text-center">
        <LinkButton to='/usage-status' app>利用状況の確認・変更ページ</LinkButton>で、ご変更の内容を確認できます。
      </div>
    </div>
  )
}

export default NextDeliveryChangeComplete
