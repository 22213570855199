import httpClient, { fileClient }from '../http'
import download from '../download'
import { RESPONSE_OK } from '../constants'

export default {
  index(params){
    return httpClient.get(`/api/stocks`, { params })
  },

  async csv(params, term) {
    const url = `/api/stocks/downloadcsv`
    const response = await fileClient.get(url, { params })

    if (response.status === RESPONSE_OK) {
      const fileName = `stocklist_${term}.csv`
      download(response.data, fileName)
    }
  },
  async manualUpdate(params){
    return httpClient.get(`/api/stocks/manual-update`, { params })
  }
}
