import React from 'react'
import { Result } from 'antd'

function SystemError() {
  return (
    <Result
      status="error"
      title="システムエラーが発生しました。"
    >
      <div className="text-center text-paragraph">
        大変申し訳ございません。一時的なエラーにより、処理を継続できませんでした。<br/>
        お手数ですが、しばらく時間を置いてから再度お試しください。
      </div>
    </Result>
  )
}

export default SystemError
