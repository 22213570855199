import React, { useState } from 'react'
import { Spin } from 'antd'
import Page from '../../../components/Page'
import Pagination from '../../../components/Pagination'
import SearchResult from '../../../components/SearchResult'
import { USAGE_STATUS_PAGE_ITEMS } from '../../../constants'
import StaffInfoChangeModal from '../component/StaffInfoChangeModal'
import UsageStatusOnlyList from '../component/UsageStatusList/UsageStatusOnlyList'
import UsageStatusOnlySearchForm from '../component/UsageStatusSearchForm/UsageStatusOnlySearchForm'
import useStore from '../hooks/store'
import useSearchUsageStatus from '../hooks/search-usage-status'
import useStaffInfoChangeModal from '../hooks/staff-info-change-modal'
import useSelectUsageStatus from '../hooks/select-usage-status'
import queryString from "query-string"

// 利用状況の検索（利用状況の確認）
const UsageStatusOnlySearch = ({props}) => {
  const searchParams = queryString.parse(props.location.search)
  const [ pageLoading, setPageLoading ] = useState(false)
  const { stores, groupStores } = useStore()
  const { query, setQuery, searchItem, setSearchItem, pageInfo, usageStatusItems, downloadCsv } = useSearchUsageStatus(setPageLoading, null, searchParams)
  const { selectedUsageStatusItems, setSelectedUsageStatusItems, handlePaginate } = useSelectUsageStatus(usageStatusItems, query, setQuery)
  const { selectedStaffItem, handleStaffInfoChangeClick, handleStaffInfoChangeCancel, handleStaffInfoChangeExecute } = useStaffInfoChangeModal(query, setQuery)

  return (
    <Page title="利用状況の確認">
      <div className="mb-3">
        <UsageStatusOnlySearchForm
          stores={stores}
          setQuery={setQuery}
          searchItem={searchItem}
          setSearchItem={setSearchItem}
          downloadCsv={downloadCsv}
          pageLoading={pageLoading}
        ></UsageStatusOnlySearchForm>
      </div>

      <div className="mt-3 mb-3">
        <Spin spinning={pageLoading}>
          <SearchResult
            pageInfo={pageInfo}
            zeroMessage="該当の情報が見つかりませんでした。条件を変更してもう一度検索してください。"
            forceError={!usageStatusItems.length}>
            <div className="mb-2">
              <div className="mb-2">
                {pageInfo && !!usageStatusItems.length && (
                  <Pagination
                    currentPage={pageInfo.currentPage}
                    allCount={pageInfo.allCount}
                    fromCount={pageInfo.fromCount}
                    toCount={pageInfo.toCount}
                    pageSize={USAGE_STATUS_PAGE_ITEMS}
                    onChange={handlePaginate}
                  />
                )}
              </div>
            </div>

            <div>
              {!!usageStatusItems.length &&
                <UsageStatusOnlyList
                  usageStatusItems={usageStatusItems}
                  selectedUsageStatusItems={selectedUsageStatusItems}
                  setSelectedUsageStatusItems={setSelectedUsageStatusItems}
                  handleStaffInfoChangeClick={handleStaffInfoChangeClick}
                ></UsageStatusOnlyList>
              }
            </div>
          </SearchResult>
        </Spin>
      </div>

      {pageInfo && !!usageStatusItems.length && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          pageSize={USAGE_STATUS_PAGE_ITEMS}
          onChange={handlePaginate}
        />
      )}

      {/* 社員支店変更モーダル */}
      {selectedStaffItem && (
        <StaffInfoChangeModal
          stores={groupStores}
          selectedStaffItem={selectedStaffItem}
          onOk={handleStaffInfoChangeExecute}
          onCancel={handleStaffInfoChangeCancel}
        ></StaffInfoChangeModal>
      )}
    </Page>
  )
}

export default UsageStatusOnlySearch
