const PasswordValidation = {
  HAS_LOWERCASE_LETTER: "英小文字が最低でも1つは必要です",
  HAS_DIGIT: "数字が最低でも1つは必要です",
  IS_LOWERCASE_LETTER_OR_DIGIT: "英小文字と数字のみで構成される必要があります",
  MIN_LENGTH8: "長さが８桁以上である必要があります"
};

export const invalidPasswordReason = (password) => {
  const hasLowercaseLetter = /[a-z]/.test(password);
  const hasDigit = /\d/.test(password);
  const isLowercaseLetterOrDigit = /^[a-z\d]*$/.test(password);
  const minLength = password.length >= 8;
  return !hasLowercaseLetter ? PasswordValidation.HAS_LOWERCASE_LETTER :
         !hasDigit ? PasswordValidation.HAS_DIGIT:
         !isLowercaseLetterOrDigit ? PasswordValidation.IS_LOWERCASE_LETTER_OR_DIGIT :
         !minLength ? PasswordValidation.MIN_LENGTH8 : ""
}

export const mailAddressRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/