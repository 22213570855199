import React from 'react'
import { Form, Button, Row, Col, Input, Select } from 'antd'
import { appNavigate } from '../../../../components/AppLink'
import queryString from "query-string"

// 利用状況検索フォーム
const UsageStatusOnlySearchForm = ({stores, setQuery, searchItem, setSearchItem, downloadCsv, pageLoading}) => {
  return (
    <Form layout="vertical" colon={false} labelAlign="left">
      <Col className="text-sm" style={{ padding: 8 }}>
        氏名・支店の設定を行います。社員コードや氏名で絞り込みを行い操作を行ってください。
      </Col>
      <div className="panel">
        <Row gutter={24} type="flex" align="middle">
          <Col span={6}>
            <Form.Item label="社員番号" style={formItemStyle}>
              <Input value={searchItem.staffNo} onChange={
                event => setSearchItem(Object.assign({}, searchItem, { staffNo: event.target.value }))
              }/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="社員名" style={formItemStyle}>
              <Input value={searchItem.staffName} onChange={
                event => setSearchItem(Object.assign({}, searchItem, { staffName: event.target.value }))
              }/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="所属支店" style={formItemStyle}>
              <Select allowClear showSearch optionFilterProp="children" value={searchItem.storeId} onChange={
                val => setSearchItem(Object.assign({}, searchItem, { storeId: val }))
              }>
                {stores && stores.map(
                  item => <Select.Option key={item.storeId} value={item.storeId}>{item.storeName}</Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="個人用バーコード" style={formItemStyle}>
              <Input value={searchItem.barcode} onChange={
                event => setSearchItem(Object.assign({}, searchItem, { barcode: event.target.value }))
              }/>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="mt-3 text-center">
        <Button htmlType="submit" type="primary" disabled={pageLoading} onClick={() =>{
          setQuery(Object.assign({}, searchItem, { page: 1 }))
          appNavigate("/usage-status?" + queryString.stringify(searchItem, { skipEmptyString: true }))
        }}>検索</Button>
        <Button htmlType="button" className="ml-1" disabled={pageLoading} onClick={downloadCsv}>CSVファイル出力</Button>
      </div>
    </Form>
  )
}
const formItemStyle = { marginBottom: 0, paddingBottom: 0 }

export default UsageStatusOnlySearchForm