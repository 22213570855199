import React from 'react'
import Tag from './Tag'
import {
  ORDER_STATUS_WAITING,
  ORDER_STATUS_OK,
  ORDER_STATUS_DONE,
  ORDER_STATUS_GOING,
  ORDER_STATUS_CANCEL,
  ORDER_STATUS_RETURN_REGISTER,
  ORDER_TYPE_SHOPPING,
  ORDER_TYPE_CANCEL,
  ORDER_TYPE_CHANGE,
  // ORDER_TYPE_RETURN
} from '../constants'

function getType(status, type) {
  // 解約の場合は、キャンセルしていなければ完了の色
  // （後続処理はない、返品状態は別出し）
  if (type === ORDER_TYPE_CANCEL) {
    if (status !== ORDER_STATUS_CANCEL) {
      return 'primary'
    }
  }

  switch (status) {
    case ORDER_STATUS_WAITING:
      return 'danger'
    case ORDER_STATUS_OK:
      return 'info'
    case ORDER_STATUS_GOING:
      return 'gold'
    case ORDER_STATUS_DONE:
      return 'primary'
    case ORDER_STATUS_CANCEL:
      return 'mute'
    case ORDER_STATUS_RETURN_REGISTER:
      return 'mute'
    default:
      return ''
  }
}

function getContent(children, status, type) {
  if (type === ORDER_TYPE_CANCEL) {
    return status === ORDER_STATUS_CANCEL ? children : '解約確定'
  }

  // 交換の場合は文言を変える
  if (type === ORDER_TYPE_CHANGE) {
    return status === ORDER_STATUS_OK ? '交換確定' : children
  }

  return children
}

function OrderTag({ status, type = ORDER_TYPE_SHOPPING, children }) {
  return (
    <Tag type={getType(status, type)}>
      {getContent(children, status, type)}
    </Tag>
  )
}

export default OrderTag
