import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import AppLink from '../../components/AppLink'
import { Button, Icon, Divider, Spin } from 'antd'
import Page from '../../components/Page'
import useScene from './hooks/scene'
import { NEXT_DELIVERY_CHANGE_COMPLETE, NEXT_DELIVERY_CHANGE_CONFIRM, NEXT_DELIVERY_CHANGE_INPUT } from './util/constants'
import NextDeliveryChangeInput from './pages/NextDeliveryChangeInput'
import NextDeliveryChangeConfirm from './pages/NextDeliveryChangeConfirm'
import NextDeliveryChangeComplete from './pages/NextDeliveryChangeComplete'

// 次回配送内容変更
const NextDeliveryChange = () => {
  const location = useLocation()
  const { setScene, isCurrentScene, pageTitle } = useScene()
  const [loading, setLoading] = useState(false)
  const [nextDeliveryChangeItems, setNextDeliveryChangeItems] = useState(location.state.changeUsageStatusInfoList)
  const [editedItems, setEditedItems] = useState([])
  useEffect(() => { window.scrollTo(0, 0)}, [])

  return (
    <Page title={pageTitle()}>
      <Spin spinning={loading}>
        {isCurrentScene(NEXT_DELIVERY_CHANGE_INPUT) &&
          <div className="text-left">
            <AppLink to='/usage-status' >
              <Icon type="left" className="mr-1"/>
              <span>利用状況の確認・変更に戻る</span>
            </AppLink>
          </div>
        }
        {isCurrentScene(NEXT_DELIVERY_CHANGE_CONFIRM) &&
          <div className="text-left">
            <Button type="link" onClick={() => setScene(NEXT_DELIVERY_CHANGE_INPUT)} >
              <Icon type="left" className="mr-1"/>
              <span>次回発送内容の変更に戻る</span>
            </Button>
          </div>
        }
        <Divider />
        {isCurrentScene(NEXT_DELIVERY_CHANGE_INPUT) &&
          <NextDeliveryChangeInput
            nextDeliveryChangeItems={nextDeliveryChangeItems}
            setNextDeliveryChangeItems={setNextDeliveryChangeItems}
            setScene={setScene}
            setEditedItems={setEditedItems}
          />
        }
        {isCurrentScene(NEXT_DELIVERY_CHANGE_CONFIRM) &&
          <NextDeliveryChangeConfirm
            setScene={setScene}
            editedItems={editedItems}
            setLoading={setLoading}
          />
        }
        {isCurrentScene(NEXT_DELIVERY_CHANGE_COMPLETE) &&
          <NextDeliveryChangeComplete />
        }
      </Spin>
    </Page>
  )
}

export default NextDeliveryChange

