import React, { useEffect, useState, useMemo } from 'react'
import { Location } from '@reach/router'
import { Icon, Menu, Button} from 'antd'
import AppLink from './AppLink'
import { adminMenuGroup, customerMenuGroup, sellerMenuGroup } from '../menu'
import { useCurrentUser, useIsAdmin, useIsSellerAdmin } from '../hooks/auth'
import { logout as logoutAction } from '../store/auth'
import { useDispatch } from 'react-redux'

function SubMenuTitle({ children, icon }) {
  return (
    <span>
      <Icon type={icon} />
      <span>{children}</span>
    </span>
  )
}

function useMenu() {
  const user = useCurrentUser()
  const isAdmin = useIsAdmin()
  const isSellerAdmin = useIsSellerAdmin()
  const [menu, setMenu] = useState([])
  const logout = {key: 'logout', title: 'ログアウト', icon: 'logout', path: 'login'}

  useEffect(() => {
    const menu = (() => {
      if (!user) return []
      if (isAdmin) return adminMenuGroup()
      if (isSellerAdmin) return sellerMenuGroup(user)
      return customerMenuGroup(user)
    })()
    menu.push(logout)
    setMenu(menu)
  }, [user, isAdmin, isSellerAdmin])

  return menu
}

function GlobalMenu({ pathname }) {
  const menu = useMenu()
  const [selectedKeys, setSelectedKeys] = useState([])
  const dispatch = useDispatch()
  const logout = () => dispatch(logoutAction())

  useEffect(() => {
    setSelectedKeys(
      menu.filter(m => pathname.indexOf(m.path) > -1).map(m => m.path)
    )
  }, [menu, pathname])

  const defaultOpenKeys = useMemo(() => {
    return menu.filter(menuGroup => (typeof menuGroup.key !== 'undefined'))
      .map(menuGroup => menuGroup.key)
  }, [menu])

  if (menu.length === 0) {
    return null
  }

  return (
    <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}
      defaultOpenKeys={defaultOpenKeys}
    >
      {menu.map(menuGroup => menuGroup.items ? (
        <Menu.SubMenu
          key={menuGroup.key}
          title={
            <SubMenuTitle icon={menuGroup.icon}>{menuGroup.title}</SubMenuTitle>
          }
        >
          {menuGroup.items.map(item => (
            <Menu.Item key={item.path}>
              <AppLink to={`/${item.path}`}>
                <span>{item.label ? item.label : item.title}</span>
              </AppLink>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ) : (
        <Menu.Item key={menuGroup.path}>
          {menuGroup.key === 'logout' ? (
            <Button style={{padding: 0, width: '100%', textAlign: 'left'}} type="link" onClick={logout}>
              <Icon type={menuGroup.icon} />
              <span style={{margin: 0}}>{menuGroup.label ? menuGroup.label : menuGroup.title}</span>
            </Button>
          ) : (
            <AppLink to={`/${menuGroup.path}`}>
              <Icon type={menuGroup.icon} />
              <span>{menuGroup.label ? menuGroup.label : menuGroup.title}</span>
            </AppLink>
          )}
        </Menu.Item>
      ))}
    </Menu>
  )
}

function withLocation(Comp) {
  return () => (
    <Location>
      {({ location }) => (
        <Comp pathname={location.pathname} />
      )}
    </Location>
  )
}

export default withLocation(GlobalMenu)
