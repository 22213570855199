import { useState } from 'react'
import { notification } from 'antd'
import staffApi from '../../../api/staff'
import { RESPONSE_OK } from '../../../constants'

const useStaffInfoChangeModal = (query, setQuery) => {
  const [selectedStaffItem, setSelectedStaffItem] = useState(null)
  const handleStaffInfoChangeClick = (item) => setSelectedStaffItem(item)
  const handleStaffInfoChangeCancel = () => setSelectedStaffItem(null)
  const handleStaffInfoChangeExecute = async (staffNo, staffName, storeId, setLoading) => {
    setLoading(true)
    const response = await staffApi.update(Object.assign({}, {}, {staffNo, staffName, storeId}))
    if (response.status === RESPONSE_OK) {
      setSelectedStaffItem(null)
      notification.success({className: `notification`,placement: `topRight`, message: `情報を更新しました`})
      setQuery(Object.assign({}, query, {}))
    }
    setLoading(false)
  }
  return { selectedStaffItem, handleStaffInfoChangeClick, handleStaffInfoChangeCancel, handleStaffInfoChangeExecute }
}

export default useStaffInfoChangeModal