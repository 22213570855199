import React, { useEffect, useState } from 'react'
import { appNavigate } from './AppLink'
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Icon,
  List,
  notification,
  Row,
  Select,
  Spin,
} from 'antd'
import { notifySuccess } from '../notify'
import ShoppingList from '../models/ShoppingList'
import cartApi from '../api/cart'
import { RESPONSE_INVALID, RESPONSE_OK } from '../constants'
import productApi from '../api/product'
import Product from '../models/Product'
import Image from './Image'
import PictureWall from './PictureWall'
import LinkButton from './LinkButton'
import { dateFormat, numFormat } from '../formatter'
import NumInput from './NumInput'
import { sanitizeText } from '../sanitize'
import { SetContentsCollapse } from '../components/SetContents'
import { useShowProductStock, useShowPrice } from '../hooks/auth'

const NOTIFICATION_KEY = 'product-show-cart-add-complete'

const r = (obj, key, defaultVal = '') => obj ? obj[key] : defaultVal

function ProductShowBody({
  id, backLink, canOrder = true, accountId = null, shippingMessage = ""
}) {
  const [product, setProduct] = useState(null)
  const [currentImage, setCurrentImage] = useState(null)
  const [shoppingList, setShoppingList] = useState(new ShoppingList([]))
  const [pageLoading, setPageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [stockShortage, setStockShortage] = useState(false)
  const [filterSize, setFilterSize] = useState("")
  const showProductStock = useShowProductStock()
  const showPrice = useShowPrice()
  const itemDetail = r(product, 'details', [])
  const filteredItemDetail = itemDetail.filter(item => filterSize === "" ? true : item.size === filterSize)

  // 画像変更
  const handleImageChange = image => setCurrentImage(image)

  // 注文数変更
  const handleShoppingListChange = newOrder => {
    setShoppingList(shoppingList.putList(newOrder))
  }

  // カートに入れる
  const handleAddToCart = async () => {
    if (!canOrder) {
      return false
    }

    if (shoppingList.isEmpty()) {
      return false
    }

    setLoading(true)

    const response = await cartApi.add(shoppingList.postData)

    if (response.status === RESPONSE_OK) {
      if (response.data.message === 'INVALID_ORDER_COUNT') {
        setStockShortage(true)
      } else {
        notify()
      }
    } else if (response.status === RESPONSE_INVALID) {
      setInvalid(true)
    }

    setLoading(false)
  }

  // 初期表示
  useEffect(() => {
    if (!id) return () => {}
    (async () => {
      setPageLoading(true)
      const params = accountId ? { accountId } : {}
      const response = await productApi.show(id, params)
      if (response.status === RESPONSE_OK) {
        setProduct(new Product(response.data))
      }
      setPageLoading(false)
    })()
  }, [id])

  // 注文リスト初期化
  useEffect(() => {
    if (product) {
      setCurrentImage(product.mainImageUrl)
      const orderItems = product.details.map(d => ({
        pNo: d.pNo,
        orderCount: 0,
        price: d.taxOutPrice,
        tax: d.tax,
        isSet: d.set,
        size: d.size
      }))
      setShoppingList(new ShoppingList(orderItems))
    }
  }, [product])

  // バリデーションエラー時の再読み込み処理
  const reload = async () => {
    setLoading(true)
    const response = await productApi.show(id)
    setProduct(new Product(response.data))
    setInvalid(false)
    setLoading(false)
  }

  const renderFooter = () => {
    if (!canOrder) {
      return <Back to={backLink} />
    }

    if (invalid) {
      return <ValidationError onReload={reload} loading={loading} />
    }

    return (
      <>
        {stockShortage && (
          <StockShortage onOk={() => setStockShortage(false)} />
        )}
        <p className="mb-2 text-right text-red text-sm">
          ※注文数は半角数字で入力してください。
        </p>
        <Row type="flex" align="middle" justify="space-between">
          <Col>
            <Back to={backLink} />
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleAddToCart}
              icon="shopping-cart"
              loading={loading}
            >
              カートに入れる
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <Spin spinning={pageLoading}>
      <Row gutter={40}>
        <Col span={12}>
          <Image src={currentImage} fit="contain" />
        </Col>
        <Col span={12}>
          <p className="mb-2 text-lg text-header">
            {r(product, 'title')}
          </p>
          {!showPrice && product && (
            <>
              <p className="mb-1 text-primary">
                <span className="text-lg text-bold">
                  {r(product, 'taxInPriceLabel')}
                </span>
                <span className="text-sm">（税込）</span>
              </p>
                <p className="mb-2">
                <span className="text-sm">税抜 </span>
                <span className="text-md">
                  {r(product, 'taxOutPriceLabel')}
                </span>
              </p>
            </>
          )}
          <p
            className="mb-3"
            dangerouslySetInnerHTML={
              {__html: sanitizeText(r(product, 'description'))}
            }
          />
          <PictureWall
            items={r(product, 'images', [])}
            onChange={handleImageChange}
          />
          {product && product.sizeImage && (
            <div className="mt-3">
              <SizeImageLink href={product.sizeImage} />
            </div>
          )}
        </Col>
      </Row>
      {shoppingList && <ProductSizeFilter shoppingList={shoppingList} setFilterSize={setFilterSize} />}
      {itemDetail[0] && itemDetail[0].set && (
        <div className="mt-2">
          <p className="text-red text-right">
            ※サイズごとのグレーの商品名枠をクリックすると在庫数と入庫予定が確認できます。
          </p>
        </div>
      )}
      {shippingMessage && (
        <div className="mt-2 mb-2">
          <Alert message={shippingMessage} type="info" showIcon />
        </div>
      )}
      <div className="mt-2 mb-2">
        <List
          bordered
          itemLayout="horizontal"
          dataSource={filteredItemDetail}
          renderItem={item => (
            <List.Item>
              <OrderRow
                item={item}
                orderCount={shoppingList.countOfProductNo(item.pNo)}
                onChange={handleShoppingListChange}
                canOrder={canOrder}
                unitName={r(product, 'unitName')}
                showProductStock={showProductStock}
                showPrice={showPrice}
              />
            </List.Item>
          )}
        />
      </div>

      {renderFooter()}
    </Spin>
  )
}

function Back({ to }) {
  return (
    <LinkButton to={to} type="link" app>
      <Icon type="left" />
      <span>商品一覧に戻る</span>
    </LinkButton>
  )
}

function SizeImageLink({ href }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <span className="icon-text">
        <Icon type="right-circle" />
        <span>サイズ詳細</span>
      </span>
    </a>
  )
}

const ProductSizeFilter = ({shoppingList, setFilterSize}) => {
  return (
    <Row className="mt-2 mb-2" type="flex" align="middle">
      <Col className="mr-2">
        <div className="text-left">サイズで絞り込む</div>
      </Col>
      <Col style={{ width: "110px" }}>
          <Select
            showSearch
            style={{ width: '100%' }}
            optionFilterProp="children"
            defaultValue={""}
            onChange={(value) => setFilterSize(value)}
          >
            <Select.Option value="">すべて</Select.Option>
            {shoppingList.productSizeList().map(value => <Select.Option value={value}>{value}</Select.Option>)}
          </Select>
      </Col>
    </Row>
  )
}

function OrderRow({
  item,
  orderCount,
  onChange,
  canOrder,
  unitName,
  showProductStock,
  showPrice
}) {
  const handleOrderChange = num => {
    onChange({ orderCount: num, pNo: item.pNo })
  }


  const renderStock = () => {
    if (!item.showStock) return null

    if (item.stockCount >= item.stockDisplayLimit) return <div>在庫あり</div>

    return (
      <>
        <div>在庫 {numFormat(item.stockCount)} {unitName}</div>
        {item.receivingExpectedDate && (
          <div className="text-sm mt-1">
            入庫予定 {numFormat(item.receivingExpectedCount)} {unitName}
            （{dateFormat(item.receivingExpectedDate)}）
          </div>
        )}
      </>
    )
  }

  return (
    <div style={{ width: `100%` }}>
      <Row type="flex" align="middle">
        <Col span={item.set ? 14 : canOrder ? 6 : 8}>
          <div>
            <span className={item.sexColorClassName}>{item.sexName}</span>
            <Divider type="vertical" />
            <span className="text-header">{item.size}</span>
            {item.set && (
              <>
                <Divider type="vertical" />
                <span className="text-sm mt-1">{item.pNo}</span>
              </>
            )}
            {(!item.set && showProductStock) && (
              <>
                <Divider type="vertical" />
                <span>{item.typeName}</span>
              </>
            )}
          </div>
          {item.set ? (
            <div className="mt-1">
              <SetContentsCollapse item={item} />
            </div>
          ) : (
            <div className="text-sm mt-1">{item.pNo}</div>
          )}
        </Col>
        <Col span={item.set ? 3 : canOrder ? 6 : 8} offset={item.set ? 1 : 0}>
        {!showPrice && (
          <>
            <div>
              <span className="text-header">{numFormat(item.taxInPrice)}</span> 円
              <span className="text-sm">（税込）</span>
            </div>
            <div className="text-sm mt-1">
              税抜 {numFormat(item.taxOutPrice)} 円
            </div>
          </>
        )}
        </Col>
        {!item.set && (
          <Col span={canOrder ? 6 : 8}>
            {renderStock()}
          </Col>
        )}
        {canOrder && (
          <Col span={6}>
            <Row gutter={8} type="flex" align="middle">
              <Col span={12}>
                <div className="text-sm text-right">注文数</div>
              </Col>
              <Col span={12}>
                <Form.Item style={{ margin: 0 }}>
                  <NumInput
                    value={orderCount}
                    onChange={handleOrderChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  )
}

function NotifyContent() {
  const handleClose = () => {
    appNavigate(`/products`)
    notification.close(NOTIFICATION_KEY)
  }

  const handleClickCart = () => {
    appNavigate(`/cart`)
    notification.close(NOTIFICATION_KEY)
  }

  return (
    <div className="mt-3 text-right">
      <Button size="small" onClick={handleClose} className="mr-1">
        お買い物を続ける
      </Button>
      <Button
        size="small"
        type="primary"
        icon="shopping-cart"
        onClick={handleClickCart}
      >
        カートを見る
      </Button>
    </div>
  )
}

function ValidationError({ onReload, loading }) {
  return (
    <div className="mt-2">
      <div className="message is-danger">
        <p className="mb-2">
          商品情報が変更されています。ページを読み込み直してください。
        </p>
        <div className="text-right">
          <Button
            type="danger"
            icon="redo"
            onClick={onReload}
            loading={loading}
          >
            再読み込み
          </Button>
        </div>
      </div>
    </div>
  )
}

function StockShortage({ onOk }) {
  return (
    <div className="mb-2">
      <div className="message is-danger">
        <p className="mb-2">
          在庫が不足しているため、ご指定の数をカートに追加できません。
        </p>
        <div className="text-right">
          <Button type="danger" onClick={onOk} ghost>OK</Button>
        </div>
      </div>
    </div>
  )
}

function notify() {
  notifySuccess({
    key: NOTIFICATION_KEY,
    message: `カートに商品が入りました。`,
    description: <NotifyContent />,
    autoClose: false,
  })
}

export default ProductShowBody
