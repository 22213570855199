import React from 'react'
import { Button, Row, Col, List, Divider, Card } from 'antd'
import UsageStatusAndNextDeliveryListTable from '../UsageStatusListTable/UsageStatusAndNextDeliveryListTable'

// 利用状況リスト
const UsageStatusAndNextDeliveryList = ({ usageStatusItems, selectedUsageStatusItems, setSelectedUsageStatusItems, handleStaffInfoChangeClick }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={usageStatusItems}
      renderItem={(_, index) => {
        const usageStatusItem = usageStatusItems[index]
        const selectedRowKeys = selectedUsageStatusItems[index] && selectedUsageStatusItems[index].selectedStatusList
        return (
          <List.Item>
            <Card
              size="small"
              style={{width: '100%'}}
              headStyle={{ backgroundColor: `#fafafa` }}
              title={
                <Row type="flex" align="middle" justify="space-between">
                  <Col>
                    <span>
                      <span>{usageStatusItem.staffNo}</span>
                      <Divider type="vertical"/>
                      <span style={{display: "inline-block", verticalAlign: "middle", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{usageStatusItem.staffName}</span>
                      <Divider type="vertical"/>
                      <span style={{display: "inline-block", verticalAlign: "middle", maxWidth: "250px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{usageStatusItem.storeName}</span>
                      <Divider type="vertical"/>
                      <Button htmlType="button" className="mr-2" icon="edit" onClick={() => handleStaffInfoChangeClick(usageStatusItem)}>
                        <span>氏名・所属支店を変更</span>
                      </Button>
                    </span>
                  </Col>
                </Row>
              }
            >
              <UsageStatusAndNextDeliveryListTable dataSource={usageStatusItem.itemStatuses} rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys) => {
                  const selectItems = JSON.parse(JSON.stringify(selectedUsageStatusItems))
                  selectItems[index].selectedStatusList = selectedRowKeys
                  setSelectedUsageStatusItems(selectItems)
                },
                getCheckboxProps: (record) => ({disabled: record.isExpiredChangeDeadline || !record.existsNextDelivery}),
                selectedRowKeys: selectedRowKeys
              }}/>
            </Card>
          </List.Item>
      )}}
    ></List>
  )
}

export default UsageStatusAndNextDeliveryList