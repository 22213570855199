import React from 'react'
import AppLink from '../../../components/AppLink'

export default function Step3({ orderId }) {
  return (
    <>
      <div className="text-lg text-center mb-2">
        店舗移動申請が完了しました。
      </div>
      <div className="text-center mb-4">
        申請番号：{orderId}
      </div>
      <div className="text-center">
        <AppLink to={`/orders/${orderId}/transfer`}>店舗移動申請の詳細ページ</AppLink>
        から、申請内容の確認を行ってください。
      </div>
    </>
  )
}
