import React from 'react'
import { Redirect } from '@reach/router'
import { useDispatch } from 'react-redux'
import { setResponse } from '../store/error'
import { ROLE_BUYER } from '../constants'
import { useCurrentUser } from '../hooks/auth'

// 企業URLを補正する HoC
function withCompanyCode(Comp) {
  return function({ companyCode, ...props }) {
    const user = useCurrentUser()

    if (user && (user.company.url !== companyCode)) {
      const correctPath = props.path.replace(':companyCode', user.company.url)
      return <Redirect to={correctPath} noThrow />
    }

    return <Comp companyCode={companyCode} {...props} />
  }
}

function PrivateRoute({ as: Comp, companyCode, roles, parentOnly, ...props }) {
  const dispatch = useDispatch()
  dispatch(setResponse(null))

  const user = useCurrentUser()

  const ok = () => <Comp companyCode={companyCode} {...props} />
  const redirectNoAuth = () => <Redirect to={`/${companyCode}`} noThrow />

  // 未認証
  if (user === null) {
    return <Redirect to={`/${companyCode}/login`} noThrow />
  }

  // 権限指定なし = どの権限でもアクセス可
  if (!roles) {
    return ok()
  }

  // 権限なし
  if (!roles.includes(user.roleId)) {
    return redirectNoAuth()
  }

  // 親店舗のみのルート
  if (user.roleId === ROLE_BUYER && parentOnly && !user.isParentStore) {
    return redirectNoAuth()
  }

  return ok()
}

export default withCompanyCode(PrivateRoute)
