import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  Divider,
  Modal,
  Radio,
  Form,
  Input,
  Select,
  Spin,
} from 'antd'
import usePrefectures from '../hooks/prefectures'
import useLoading from '../hooks/loading'
import useAddressInput from '../hooks/address-input'
import { toHalfWidth } from '../../../formatter'

const { Option } = Select

const getLabel = address => ([
  address.postalCode ? `〒${address.postalCode}` : '',
  address.prefecture,
  address.city,
  address.houseNumber,
  address.buildingName,
].filter(item => item !== '').join(' '))

export default function ShippingInfo({
  user,
  shippingAddresses,
  shipTo,
  onShipChange
}) {
  const [displayShippingAddresses, setDisplayShippingAddresses] = useState(shippingAddresses)
  const [modalOpen, setModalOpen] = useState(false)
  const [inputModalOpen, setInputModalOpen] = useState(false)
  const [loading, withLoading] = useLoading()
  const [prefectures, fetchPrefectures] = usePrefectures()

  // 店舗選択用
  const [selectedIndex, setSelectedIndex] = useState(0)
  // 配送先店舗の店舗名フィルター用
  const [filteredText, setFilteredText] = useState('')

  // フリー入力用
  const {
    address, setAddress, patchAddress, resetAddress, isValidAddress
  } = useAddressInput(shipTo)

  const handleInputClick = () => {
    if (prefectures.length === 0) {
      withLoading(fetchPrefectures)
    }
    setInputModalOpen(true)
  }

  const handleOk = () => {
    resetAddress()
    setFilteredText('')
    onShipChange(displayShippingAddresses[selectedIndex])
    setModalOpen(false)
  }

  const handleCancel = () => {
    if (shipTo.shippingAddressId) {
      setSelectedIndex(
        shippingAddresses.findIndex(a => {
          return a.shippingAddressId === shipTo.shippingAddressId
        })
      )
    }
    setFilteredText('')
    setModalOpen(false)
  }

  const handleInputOk = () => {
    setSelectedIndex(0)
    onShipChange(address)
    setInputModalOpen(false)
  }

  const handleInputCancel = () => {
    if (!shipTo.shippingAddressId) {
      setAddress(shipTo)
    }

    setInputModalOpen(false)
  }

  const handleStoreFilterInput = (val) => {
    setFilteredText(val)
    setDisplayShippingAddresses(
      shippingAddresses.filter(item => item.storeName.includes(val))
    )
  }

  useEffect(() => {
    handleStoreFilterInput(filteredText)
  }, [filteredText]);

  return (
    <>
      <div className="mb-2">
        <span className="text-bold">配送先</span>
        {user.isParentStore && (
          <>
            <Divider type="vertical" />
            <Button
              size="small"
              type="primary"
              ghost
              onClick={() => setModalOpen(true)}
            >
              別の店舗に配送する
            </Button>
            <SelectAddressModal
              visible={modalOpen}
              value={selectedIndex}
              filterValue={filteredText}
              shippingAddresses={displayShippingAddresses}
              onOk={handleOk}
              onCancel={handleCancel}
              onChange={val => setSelectedIndex(val)}
              onFilter={handleStoreFilterInput}
            />
          </>
        )}
        <Divider type="vertical" />
        <Button
          size="small"
          type="primary"
          ghost
          onClick={handleInputClick}
        >
          配送先をフリー入力する
        </Button>
        <InputAddressModal
          visible={inputModalOpen}
          loading={loading}
          valid={isValidAddress()}
          value={address}
          prefectures={prefectures}
          onOk={handleInputOk}
          onCancel={handleInputCancel}
          onChange={data => patchAddress(data)}
        />
      </div>
      <ShippingAddress currentAddress={shipTo} />
    </>
  )
}

function SelectAddressModal({
  visible,
  value,
  filterValue,
  onOk,
  onCancel,
  onChange,
  onFilter,
  shippingAddresses
}) {
  return (
    <Modal
      bodyStyle={{height: '70vh'}}
      className="modal-long"
      title="配送先の店舗を選択してください"
      visible={visible}
      centered
      onOk={onOk}
      onCancel={onCancel}
      cancelText="キャンセル"
    >
      <div className="mb-2">
        <Input
          value={filterValue}
          placeholder="店舗名で検索"
          onChange={e => onFilter(e.target.value)}
        />
      </div>
      {!shippingAddresses.length &&
        <div className="text-center text-mute">該当の情報が見つかりませんでした</div>
      }
      <Radio.Group
        style={{ width: `100%` }}
        value={value}
        onChange={e => onChange(e.target.value)}
      >
        {shippingAddresses.map((sa, index) => (
          <div key={sa.shippingAddressId} className="mb-3">
            <Radio value={index}>
              <span className="text-bold">{sa.storeName}</span>
            </Radio>
            <div className="mt-1">
              <Card>
                <div className="mb-1">{getLabel(sa)}</div>
                <div>{sa.tel}</div>
              </Card>
            </div>
          </div>
        ))}
      </Radio.Group>
    </Modal>
  )
}

/** 配送先 入力モーダル */
function InputAddressModal({
  visible,
  loading,
  valid,
  prefectures,
  value,
  onOk,
  onCancel,
  onChange
}) {
  /** 郵便番号変更 */
  const handlePostalCodeChange = (e) => {
    const newValue = e.target.value;
    onChange({ postalCode: newValue })
  };

  const handlePostalCodeBlur = (e) => {
    const newValue = e.target.value;
    // 全角を半角に変換
    const halfWidthValue = toHalfWidth(newValue);
    onChange({ postalCode: halfWidthValue })
  };
  
  /** 都道府県変更 */
  const handlePrefectureChange = (value, option) => onChange({
    prefecture: option.props.title,
    prefectureId: value,
  });
  
  /** 番地変更 */
  const handleHouseNumberChange  = (e) => {
    const newValue = e.target.value;
    onChange({ houseNumber: newValue })
  };
  const handleHouseNumberBlur  = (e) => {
    const newValue = e.target.value;
    // 全角を半角に変換
    const halfWidthValue = toHalfWidth(newValue);
    onChange({ houseNumber: halfWidthValue })
  };

  /** 電話番号変更 */
  const handleTelChange  = (e) => {
    const newValue = e.target.value;
    onChange({ tel: newValue })
  };
  const handleTelBlur  = (e) => {
    const newValue = e.target.value;
    // 全角を半角に変換
    const halfWidthValue = toHalfWidth(newValue);
    onChange({ tel: halfWidthValue })
  };

  return (
    <Modal
      title="配送先の住所を入力してください"
      visible={visible}
      centered
      onOk={onOk}
      okButtonProps={{ disabled: !valid }}
      onCancel={onCancel}
      cancelText="キャンセル"
    >
      <Spin spinning={loading}>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          colon={false}
          labelAlign="left"
        >
          <Form.Item label="宛名" required>
            <Input
              value={value.addressee}
              onChange={e => onChange({ addressee: e.target.value })}
              maxLength="255"
            />
          </Form.Item>
          <Form.Item label="郵便番号" required>
            <Input
              addonBefore="〒"
              value={value.postalCode}
              onChange={handlePostalCodeChange}
              onBlur={handlePostalCodeBlur}
              maxLength="8"
            />
          </Form.Item>
          <Form.Item label="都道府県" required>
            <Select
              value={value.prefectureId}
              onChange={handlePrefectureChange}
            >
              {prefectures.map(p => (
                <Option key={p.id} value={p.id} title={p.name}>
                  {p.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="市区町村" required>
            <Input.TextArea
              placeholder="港区 六本木"
              autoSize
              value={value.city}
              onChange={e => onChange({ city: e.target.value })}
              maxLength="255"
            />
          </Form.Item>
          <Form.Item label="番地" required>
            <Input
              placeholder="1-2-3"
              autoSize
              value={value.houseNumber}
              onChange={handleHouseNumberChange}
              onBlur={handleHouseNumberBlur}
              maxLength="255"
            />
          </Form.Item>
          <Form.Item label="ビル名">
            <Input
              value={value.buildingName}
              onChange={e => onChange({ buildingName: e.target.value })}
              maxLength="255"
            />
          </Form.Item>
          <Form.Item label="電話番号" required>
            <Input
              value={value.tel}
              onChange={handleTelChange}
              onBlur={handleTelBlur}
              maxLength="40"
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

function ShippingAddress({ currentAddress }) {
  const addressee = currentAddress.storeName || currentAddress.addressee

  return (
    <Card>
      <div className="mb-2">{addressee}</div>
      <div className="mb-1">{getLabel(currentAddress)}</div>
      <div>{currentAddress.tel}</div>
    </Card>
  )
}
