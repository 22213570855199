import { useState, useMemo } from 'react'

const PAGE_INPUT = 'PAGE_INPUT'
const PAGE_CONFIRM = 'PAGE_CONFIRM'

export default function usePage() {
  const [page, setPage] = useState(PAGE_INPUT)

  const toInput = () => setPage(PAGE_INPUT)
  const toConfirm = () => setPage(PAGE_CONFIRM)

  const isInputPage = useMemo(() => page === PAGE_INPUT, [page])
  const isConfirmPage = useMemo(() => page === PAGE_CONFIRM, [page])

  return { toInput, toConfirm, isInputPage, isConfirmPage }
}
