import React from 'react'
import { Icon, List, Spin } from 'antd'
import Page from '../../components/Page'
import RentalStatusSummary from './components/RentalStatusSummary'
import useRentalStatusSummary from './hooks/rental-status-summary'
import { AccountSearchList } from '../AccountSearch'
import LinkButton from '../../components/LinkButton'
import queryString from "query-string"

export default function RentalStatusSearch(props) {
  const { summary, summaryLoading, summaryError } = useRentalStatusSummary()

  const searchParams = queryString.parse(props.location.search)
  const pagePath = "/rental-status?"

  const initialQuery = {
    companyId: searchParams.companyId * 1 || null,
    storeId: searchParams.storeId * 1 || null,
    page: searchParams.page * 1 || 1,
  }

  const isReturnToPage = searchParams.storeId != null

  return (
    <Page title="店舗レンタル状況">

      {!summaryError && (
        <>
          <div className="text-bold mb-2">全店舗のレンタル状況</div>
          <Spin spinning={summaryLoading}>
            <RentalStatusSummary summary={summary} />
          </Spin>
        </>
      )}

      <div className="text-bold mt-6 mb-2">店舗ごとのレンタル状況</div>
      <AccountSearchList
        initialQuery={initialQuery}
        pagePath={pagePath}
        isReturnToPage={isReturnToPage}
        renderList={(accounts, query) => (
          <List
            itemLayout="horizontal"
            dataSource={accounts}
            renderItem={item => renderItem(item, query)}
          />
        )}
      />
    </Page>
  )
}

function renderItem(item, query) {
  return (
    <List.Item
      actions={[
        <LinkButton
          to={`/rental-status/${item.accountId}`}
          state={query}
          type="primary" small ghost app
        >
          <span>レンタル状況を見る</span>
          <Icon type="right" />
        </LinkButton>,
      ]}
    >
      <List.Item.Meta
        title={item.companyName + ' ' + item.storeName}
        description={item.mailAddress}
      />
    </List.Item>
  )
}
