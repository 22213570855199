import React from 'react'
import { numFormat } from '../formatter'
import { Table } from 'antd'

const { Column } = Table

const renderYenRow = text => `${numFormat(text)} 円`

const toTableData = (d, index) => ({
  key: index,
  storeName: d.storeName,
  price: d.totalSalesAmount,
  total: d.totalSalesAmount + d.totalTaxAmount,
})

function RentalDealTable({ records }) {
  const tableData = records.map(toTableData)

  return (
    <Table bordered dataSource={tableData} pagination={false}>
      <Column title="店舗名" dataIndex="storeName" key="storeName" />
      <Column
        title="金額（税抜）"
        dataIndex="price"
        key="price"
        className="text-right"
        render={renderYenRow}
      />
      <Column
        title="金額（税込）"
        dataIndex="total"
        key="total"
        className="text-right"
        render={renderYenRow}
      />
    </Table>
  )
}

export default RentalDealTable
