import { useEffect, useState } from 'react'
import accountApi from '../../../api/accounts'
import billingApi from '../../../api/billing'
import { RESPONSE_OK } from '../../../constants'

export default function useBillingDetails({ companyId, storeId, year, month}) {
  const [billing, setBilling] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isRental, setIsRental] = useState(false)

  useEffect(() => {
    (async () => {
      if (!companyId || !year || !month) return () => {}

      setLoading(true)

      const companyResponse = await accountApi.company(companyId)

      if (companyResponse.status !== RESPONSE_OK) {
        return setLoading(false)
      }

      setIsRental(companyResponse.data.isRental)

      const response = companyResponse.data.isRental
        ? await billingApi.showRental(companyId, year, month)
        : storeId === undefined ?
          await billingApi.showWithCompany(companyId, year, month):
          await billingApi.showWithStore(companyId, storeId, year, month)

      if (response.status === RESPONSE_OK) {
        setBilling(response.data)
      }

      setLoading(false)
    })()
  }, [companyId, storeId, year, month])

  return { billing, loading, isRental, setLoading }
}
