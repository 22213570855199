import { useEffect, useState } from 'react'
import productApi from '../api/product'
import {
  summaryProduct as rentalStatusSummaryProductApi,
  exchangeFromCounts as exchangeFromCountsApi
} from '../api/rental-status'

export default function useRentalProduct({ accountId, pNo }) {
  const [product, setProduct] = useState(null)

  useEffect(() => {
    (async () => {
      const [detail, set, summary] = await Promise.all([
        productApi.showDetail(pNo),
        productApi.setContents(pNo),
        rentalStatusSummaryProductApi(accountId, pNo)
      ])

      const isSet = detail.data.isSet

      let { data: setContents } = set

      if (isSet) {
        const res = await exchangeFromCountsApi(accountId, pNo)
        setContents = setContents.map(setContent => {
          const row = res.data.find(item => item.pNo === setContent.pNo)
          return { ...setContent, leftItemCount: row ? row.count : 0 }
        })
      }

      setProduct({
        ...detail.data,
        setContents,
        status: summary.data
      })
    })()
  }, [accountId, pNo])

  return product
}
