import React, { useEffect, useMemo, useState } from 'react'
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Table,
  Spin
} from 'antd'
import Page from '../components/Page'
import moment from 'moment'
import {
  DEAL_ITEM_CODE_ADMIN,
  DEAL_ITEM_CODE_ORDER,
  DEAL_ITEM_CODE_POSTAGE,
  DEAL_ITEM_CODE_OTHER,
  DEAL_ITEM_NAME_ADMIN,
  DEAL_ITEM_NAME_POSTAGE,
  DEAL_ITEM_NAME_ORDER,
  DEAL_ITEM_NAME_OTHER,
  MONTH_FORMAT,
  RESPONSE_OK,
} from '../constants'
import { monthFormat, numFormat } from '../formatter'
import billingApi from '../api/billing'
import { notifySuccess } from '../notify'
import { appNavigate } from '../components/AppLink'
import NumInput from '../components/NumInput'
import useCompanyStoreSelect from '../hooks/company-store-select'

const { MonthPicker } = DatePicker
const { Option } = Select
const { Column } = Table

const inputStyle = { width: '100%' }

const PAGE_INPUT = 'input'
const PAGE_CONFIRM = 'confirm'

const initialData = {
  month: moment(),
  companyId: null,
  companyName: '',
  storeId: null,
  storeName: '',
  type: DEAL_ITEM_CODE_ORDER,
  typeName: DEAL_ITEM_NAME_ORDER,
  name: '',
  count: 0,
  price: 0,
}

const toPostData = billing => ({
  salesAmount: billing.price,
  companyId: billing.companyId,
  storeId: billing.storeId,
  orderCount: billing.count,
  title: billing.name,
  billingMonth: billing.month.format('YYYY-MM'),
  itemTypeCode: billing.type,
})

function BillingCreate() {
  const [billing, setBilling] = useState(initialData)
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(PAGE_INPUT)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await billingApi.destinations()
      if (response.status === RESPONSE_OK) {
        const { companyList, storeList } = response.data
        setCompanies(companyList)
      }
      setLoading(false)
    })()
  }, [])

  const handleConfirm = () => setPage(PAGE_CONFIRM)
  const handleBack = () => setPage(PAGE_INPUT)

  const handleInput = data => {
    setBilling(Object.assign({}, billing, data))
  }

  const handleSubmit = async () => {
    setLoading(true)
    const response = await billingApi.create(toPostData(billing))
    setLoading(false)
    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: '請求データを作成しました。' })
      appNavigate(`/billings`)
    }
  }

  return (
    <Page title="例外請求を作成する">
      {page === PAGE_INPUT && (
        <Spin spinning={loading}>
          <InputPage
            loading={loading}
            companies={companies}
            billing={billing}
            onInput={handleInput}
            onConfirm={handleConfirm}
          />
        </Spin>
      )}
      {page === PAGE_CONFIRM && (
        <ConfirmPage
          loading={loading}
          billing={billing}
          onSubmit={handleSubmit}
          onBack={handleBack}
        />
      )}
    </Page>
  )
}

function InputPage({ companies, billing, onInput, onConfirm }) {
  const {
    stores, companyId, setCompanyId, storeId, setStoreId
  } = useCompanyStoreSelect({ isChildStore: false })

  const dealItems = {
    [DEAL_ITEM_CODE_ORDER]: DEAL_ITEM_NAME_ORDER,
    [DEAL_ITEM_CODE_POSTAGE]: DEAL_ITEM_NAME_POSTAGE,
    [DEAL_ITEM_CODE_ADMIN]: DEAL_ITEM_NAME_ADMIN,
    [DEAL_ITEM_CODE_OTHER]: DEAL_ITEM_NAME_OTHER,
  }

  const isDisabled = useMemo(() => {
    return Object.keys(billing)
      .filter(key => !['storeId', 'storeName'].includes(key))
      .some(key => !billing[key])
  }, [billing])

  return (
    <Form layout="horizontal" colon={false} labelAlign="left">
      <div className="mb-2">
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item label="対象月">
              <MonthPicker
                format={MONTH_FORMAT}
                style={inputStyle}
                value={billing.month}
                onChange={month => onInput({ month })}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="請求先企業">
              <Select
                showSearch
                optionFilterProp="children"
                style={inputStyle}
                allowClear
                value={companyId}
                onChange={val => {
                  const item = companies.find(c => c.companyId === val)
                  onInput({companyId: item.companyId, companyName: item.companyName} || { companyId: null, companyName: '' })
                  setCompanyId(val)
                }}
              >
                {companies.map(item => (
                  <Option key={item.companyId} value={item.companyId}>
                    {item.companyName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="請求先店舗">
              <Select
                style={inputStyle}
                showSearch
                optionFilterProp="children"
                allowClear
                value={storeId}
                onChange={val => {
                  const item = stores.find(s => s.storeId === val)
                  onInput({storeId: item.storeId, storeName: item.storeName} || { storeId: null, storeName: '' })
                  setStoreId(val)
                }}
              >
                {stores.map(item => (
                  <Option key={item.storeId} value={item.storeId}>
                    {item.storeName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item label="品目名">
            <Select
              style={inputStyle}
              value={billing.type}
              onChange={val => onInput({ type: val, typeName: dealItems[val] })}
            >
              {Object.keys(dealItems).map(key => (
                <Option key={key} value={key * 1}>{dealItems[key]}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="品名">
            <Input
              style={inputStyle}
              value={billing.name}
              onChange={e => onInput({ name: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="数量">
            <NumInput
              value={billing.count}
              onChange={count => onInput({ count })}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="金額（税抜）">
            <NumInput
              allowNegative
              value={billing.price}
              onChange={price => onInput({ price })}
            />
          </Form.Item>
        </Col>
      </Row>
      <div className="mt-3 text-right">
        <Button type="primary" onClick={onConfirm} disabled={isDisabled}>
          確認する
        </Button>
      </div>
    </Form>
  )
}

function ConfirmPage({ billing, loading, onSubmit, onBack }) {
  const tableData = [{
    key: 1,
    typeName: billing.typeName,
    companyName: billing.companyName,
    name: billing.name,
    count: numFormat(billing.count),
    price: numFormat(billing.price) + ' 円',
    total: numFormat(billing.price * billing.count) + ' 円',
  }]

  return (
    <div>
      <div className="panel mb-2">
        <ul className="plain-list">
          <li>
            <Row>
              <Col span={6}>対象月</Col>
              <Col span={18}>{monthFormat(billing.month)}</Col>
            </Row>
          </li>
          <li>
            <Row>
              <Col span={6}>請求先企業</Col>
              <Col span={18}>{billing.companyName}</Col>
            </Row>
          </li>
          <li>
            <Row>
              <Col span={6}>請求先店舗</Col>
              <Col span={18}>{billing.storeName || '-'}</Col>
            </Row>
          </li>
        </ul>
      </div>
      <div className="mb-3">
        <Table dataSource={tableData} bordered pagination={false}>
          <Column title={`品目名`} dataIndex={`typeName`} key={`type`} />
          <Column title={`品名`} dataIndex={`name`} key={`name`} />
          <Column
            title={`数量`}
            dataIndex={`count`}
            key={`count`}
            className="text-right"
          />
          <Column
            title={`金額`}
            dataIndex={`price`}
            key={`price`}
            className="text-right"
          />
          <Column
            title={`合計`}
            dataIndex={`total`}
            key={`total`}
            className="text-right"
          />
        </Table>
      </div>
      <div className="mt-4">
        <Row type="flex" align="middle" justify="space-between">
          <Col>
            <Button onClick={onBack}>もどる</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={onSubmit} loading={loading}>
              請求データを作成する
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BillingCreate
