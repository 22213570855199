import React from 'react'

export default function Pill({ type, icon, children }) {
  const classNames = 'pill' + (type ? ` is-${type}` : '')

  return (
    <span className={classNames}>
      {icon}
      {children}
    </span>
  )
}
