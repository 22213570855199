import orderApi from '../../api/order'
import {
  ORDER_DETAIL_TYPE_PRODUCT,
  ORDER_DETAIL_TYPE_SHIPPING,
  RESPONSE_OK
} from '../../constants'
import Product from '../../models/Product'

export default async function fetchOrder(id) {
  const response = await orderApi.show(id)

  if (response.status !== RESPONSE_OK) {
    return false
  }

  const { orderDetails, taxAmount, ...orderInfo } = response.data

  const productList = orderDetails
    .filter(item => item.detailType === ORDER_DETAIL_TYPE_PRODUCT)
    .map(item => {
      const { orderCount, displayCount, title, productId, images, ...details } = item
      const product = new Product({ productId, title, details, images })
      return { orderCount, displayCount, product }
    })

  const shippingItem = orderDetails.find(item => item.detailType === ORDER_DETAIL_TYPE_SHIPPING);
  const shippingCost = shippingItem ? shippingItem.price * shippingItem.orderCount : 0;

  return { productList, shippingCost, taxAmount, orderInfo }
}
