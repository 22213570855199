/**
 * @typedef {Object} Address
 * @property {string} postalCode
 * @property {string} prefecture
 * @property {string} city
 * @property {string} houseNumber
 * @property {string} buildingName
 */

/**
 * 住所作成
 * @param {Address} args
 * @returns {string} 住所
 */
export default function address(args) {
  return [
    args.postalCode ? `〒${args.postalCode}` : '',
    args.prefecture,
    args.city,
    args.houseNumber,
    args.buildingName,
  ].filter(item => !!item).join(' ')
}
