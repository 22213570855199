import React from 'react'
import useCheck from '../hooks/check'
import { Button, Card, Checkbox, Col, Divider, Icon, Row, Typography } from 'antd'
import Image from '../../../components/Image'
import AppLink from '../../../components/AppLink'
import { ProductSetContentsPopover } from '../../../components/ProductSetContents'
import { ReturnAddress } from '../../../components/ReturnCard'

const { Text } = Typography

export default function Step2({
  product,
  cancelCount,
  onPrevClick,
  onNextClick,
  loading,
}) {
  const [check1, toggleCheck1] = useCheck()
  const [check2, toggleCheck2] = useCheck()

  return (
    <>
      <p className="text-bold mb-2">解約する商品</p>

      <div className="mb-3">
        <Card size="small">
          <Row gutter={16} type="flex" align="middle">
            <Col span={3}>
              <Image src={product.image ? product.image.imageName : null} />
            </Col>
            <Col span={12}>
              <p className="mb-1">
                <AppLink to={`/products/${product.productId}`}>
                  {product.productTitle}
                </AppLink>
              </p>
              <div>
                {product.sexName}
                <Divider type="vertical" />
                {product.size}
                <Divider type="vertical" />
                {product.pNo}
              </div>
              {product.isSet && (
                <div className="mt-2">
                  <ProductSetContentsPopover
                    items={product.setContents}
                    header={product.title}
                  />
                </div>
              )}
            </Col>
            <Col span={4}>
              <span className="mr-2">レンタル中</span>
              <span>{product.status.rental}</span>
            </Col>
            <Col span={5}>
              <Row gutter={8} type="flex" align="middle">
                <Col span={12} className="text-right">解約数</Col>
                <Col span={12}>{cancelCount}</Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>

      <p className="text-bold mb-2">返送時の宛先</p>
      <div className="mb-3">
        <ReturnAddress />
      </div>

      <p className="text-bold mb-2">返送の送料に関して</p>
      <p className="mb-3">
        <Text type="danger">「元払い（お客様負担）」</Text>
        にてお願いいたします。
      </p>

      <p className="text-bold mb-2">解約に伴う同意事項</p>
      <p className="mb-2">以下にチェックを入れて「解約する」ボタンを押してください。</p>
      <p className="mb-1">
        <Checkbox checked={check1} onChange={toggleCheck1}>
          返送用の納品書は、返却商品に同梱して1週間以内に配送手続きを行うこと。
        </Checkbox>
      </p>
      <p>
        <Checkbox checked={check2} onChange={toggleCheck2}>
          返送時には商品のクリーニングが完了していること。
        </Checkbox>
      </p>

      <Divider />

      <Row type="flex" align="middle" justify="space-between">
        <Col span={12}>
          <Button onClick={onPrevClick}>
            <Icon type="left" />
            戻る
          </Button>
        </Col>
        <Col span={12} className="text-right">
          <Button
            type="primary"
            onClick={onNextClick}
            disabled={!check1 || !check2}
            loading={loading}
          >
            解約を確定する
          </Button>
        </Col>
      </Row>
    </>
  )
}
