import React from 'react'
import { numFormat } from '../formatter'
import { Divider, Table } from 'antd'
import { DEAL_ITEM_CODE_ORDER } from '../constants'

const { Column } = Table

const renderNumRow = text => numFormat(text)
const renderYenRow = text => `${numFormat(text)} 円`

const renderTitle = (text, record) => {
  if (record.detailTypeCode !== DEAL_ITEM_CODE_ORDER) {
    return record.title
  }

  // 種別は注文代金だが商品名がない場合
  // 例外請求時を想定
  if (!record.productName) {
    return record.title
  }

  return (
    <>
      <div>{record.productName}</div>
      <div>
        <span className="text-sm">{record.pNo}</span>
        <Divider type="vertical" />
        <span className="text-sm">{record.productSize}</span>
        <Divider type="vertical" />
        <span className="text-sm">{record.productTypeName}</span>
      </div>
    </>
  )
}

const toTableData = (d, index) => ({
  key: index,
  total: d.orderCount * d.price,
  ...d,
})

function DealTable({ records }) {
  const tableData = records.map(toTableData)

  return (
    <Table bordered dataSource={tableData} pagination={false}>
      <Column title="品目" dataIndex="detailTypeName" key="itemTypeName" />
      <Column title="品名" key="title" render={renderTitle} />
      <Column
        title="数量"
        dataIndex="orderCount"
        key="orderCount"
        className="text-right"
        render={renderNumRow}
      />
      <Column
        title="単価（税抜）"
        dataIndex="price"
        key="price"
        className="text-right"
        render={renderYenRow}
      />
      <Column
        title="金額（税抜）"
        dataIndex="total"
        key="total"
        className="text-right"
        render={renderYenRow}
      />
    </Table>
  )
}

export default DealTable
