import React from 'react'
import { Col, Row } from 'antd'
import { numFormat } from '../formatter'

export default function DealFooter({ item ,amountTitle}) {
  return (
    <ul className="plain-list">
      <li>
        <Row>
          <Col span={18}>
            <div className="text-right">合計金額</div>
          </Col>
          <Col span={6}>
            <div className="text-right text-bold text-md">
              {numFormat(item.totalAmount)} 円
            </div>
          </Col>
        </Row>
      </li>
      <li>
        <Row>
          <Col span={18}>
            <div className="text-right">消費税</div>
          </Col>
          <Col span={6}>
            <div className="text-right text-bold text-md">
              {numFormat(item.taxAmount)} 円
            </div>
          </Col>
        </Row>
      </li>
      <li>
        <Row>
          <Col span={18}>
            <div className="text-right">{!!amountTitle ? amountTitle : '金額合計（税込）'}</div>
          </Col>
          <Col span={6}>
            <div className="text-right text-bold text-md">
              {numFormat(item.totalAmount + item.taxAmount)} 円
            </div>
          </Col>
        </Row>
      </li>
    </ul>
  )
}
