import React, { useMemo } from 'react'
import { Button, Col, Divider, Row } from 'antd'
import { numFormat } from '../../../formatter'
import DeliveryTimePanel from '../../../components/DeliveryTimePanel'
import { useShowShipmentFee } from '../../../hooks/auth'

export default function SidePanel({
  productsCost,
  shippingCost,
  onConfirm,
  onSubmit,
  onBack,
  confirm,
  deliveryTimes,
  onTimeChange,
  onDateChange,
  showPrice,
  enableTransitionToConfirm,
  taxAmount
}) {
  const isCalcShipmentFee = useShowShipmentFee()
  const totalCost = useMemo(() => (isCalcShipmentFee && shippingCost) + productsCost + taxAmount, [shippingCost, productsCost, taxAmount])

  return (
    <div>
      {confirm && (
        <DeliveryTimePanel
          deliveryTimes={deliveryTimes}
          onTimeChange={onTimeChange}
          onDateChange={onDateChange}
        />
      )}
      <div className="panel">
        {!showPrice && (
          <>
            <div className="mb-2">
              <Row type="flex" justify="space-between" align="middle">
                <Col>商品代金</Col>
                <Col>
                  <span className="text-md">
                    {numFormat(productsCost)} 円
                  </span>
                </Col>
              </Row>
            </div>
            <div className="mb-2">
              <Row type="flex" justify="space-between" align="middle">
                <Col>送料</Col>
                <Col>
                  <span className={!isCalcShipmentFee ? "text-sm" : "text-md"}>
                    {isCalcShipmentFee ? `${numFormat(shippingCost)} 円`: "翌月に実送料を請求いたします" }
                  </span>
                </Col>
              </Row>
            </div>
            <div className="mb-4">
              <Row type="flex" justify="space-between" align="middle">
                <Col>消費税</Col>
                <Col>
                  <span className="text-md">
                    {numFormat(taxAmount)} 円
                  </span>
                </Col>
              </Row>
            </div>
            <Divider />
            <div className="mb-4">
              <Row type="flex" justify="space-between" align="middle">
                <Col>注文金額</Col>
                <Col>
                  <span className="text-lg text-bold">
                    {numFormat(totalCost)} 円
                  </span>
                </Col>
              </Row>
            </div>
          </>
        )}
        {confirm ? (
          <Button type="primary" block onClick={onSubmit}>
            注文を確定する
          </Button>
        ) : (
          <Button type="primary" block onClick={onConfirm} disabled={!enableTransitionToConfirm}>
            注文内容を確認する
          </Button>
        )}
      </div>
      {!showPrice && (
        <div className="mt-2 text-center">※ 現在表記している金額はインボイス（適格請求書）ではございません。参考金額となります。インボイス（適格請求書）は従来通りの方法でご提供いたします。</div>
      )}
      {confirm && (
        <div className="mt-2">
          <Button type="link" block onClick={onBack} icon="arrow-left">
            カートに戻る
          </Button>
        </div>
      )}
    </div>
  )
}
