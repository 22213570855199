// =====================================
// レスポンスステータスコード
// =====================================
export const RESPONSE_OK = 200
export const RESPONSE_INVALID = 400
export const RESPONSE_NOT_FOUND = 404
export const RESPONSE_SERVER_ERROR = 500

// =====================================
// 権限ロール
// =====================================
export const ROLE_ADMIN = 1
export const ROLE_SELLER = 2
export const ROLE_SUBTENANT = 3
export const ROLE_BUYER = 4
export const ROLE_NAME_ADMIN = '管理者'
export const ROLE_NAME_SELLER = '委託者'
export const ROLE_NAME_SUBTENANT = '転貸者'
export const ROLE_NAME_BUYER = '購入者'

// =====================================
// ページネーション
// =====================================
export const PAGE_ITEMS = 20
export const USAGE_STATUS_PAGE_ITEMS = 10
export const PRODUCT_PAGE_ITEMS = 40
export const STOCK_PAGE_ITEMS = 100

// =====================================
// 日付フォーマット
// =====================================
export const DATE_FORMAT = 'YYYY/M/D'
export const MONTH_FORMAT = 'YYYY/M'
export const DATE_FORMAT_DETAIL = 'YYYY/M/D HH:mm:ss'

// =====================================
// 商品
// =====================================
export const PRODUCT_IMAGE_TYPE_SIZE = 3

// =====================================
// 支払・請求
// =====================================
export const DEAL_ITEM_CODE_ORDER = 1
export const DEAL_ITEM_CODE_POSTAGE = 2
export const DEAL_ITEM_CODE_ADMIN = 3
export const DEAL_ITEM_CODE_OTHER = 99
export const DEAL_ITEM_NAME_ORDER = '注文代金'
export const DEAL_ITEM_NAME_POSTAGE = '送料'
export const DEAL_ITEM_NAME_ADMIN = '管理費'
export const DEAL_ITEM_NAME_OTHER = 'その他'

// =====================================
// 注文
// =====================================
export const ORDER_TYPE_SHOPPING = 1
export const ORDER_TYPE_RENTAL = 2
export const ORDER_TYPE_CHANGE = 3
export const ORDER_TYPE_CANCEL = 4
export const ORDER_TYPE_TRANSFER = 5
export const ORDER_TYPE_RETURN = 7
export const ORDER_STATUS_WAITING = 1
export const ORDER_STATUS_OK = 2
export const ORDER_STATUS_GOING = 3
export const ORDER_STATUS_DONE = 4
export const ORDER_STATUS_CANCEL = 5
export const ORDER_STATUS_RETURN_REGISTER = 6
export const ORDER_DETAIL_TYPE_PRODUCT = 1
export const ORDER_DETAIL_TYPE_SHIPPING = 2

// =====================================
// 返品
// =====================================
export const RETURN_STATUS_WAITING = 1
export const RETURN_STATUS_OK = 2

// =====================================
// 並び順
// =====================================
export const SORT_ASC = 0
export const SORT_DESC = 1

// =====================================
// お問い合わせ
// =====================================
export const FORUM_STATUS_TODO = 1
export const FORUM_STATUS_IN_PROGRESS = 2
export const FORUM_STATUS_COMPLETED = 3

// =====================================
// 利用状況種別
// =====================================
export const USAGE_STATUS_NONE = 0
export const USAGE_STATUS_TYPE1 = 1
export const USAGE_STATUS_TYPE2 = 2

// =====================================
// 請求サマリ種別
// =====================================
export const BILLING_SUMMARY_TYPE_COMPANY = 0
export const BILLING_SUMMARY_TYPE_STORE = 1

// =====================================
// 支払サマリ種別
// =====================================
export const PAYMENT_SUMMARY_TYPE_COMPANY = 0
export const PAYMENT_SUMMARY_TYPE_STORE = 1

// =====================================
// 交換理由
// =====================================
export const EXCHANGE_REASON_WRONG_SIZE = 1         // 商品のサイズを間違えた
export const EXCHANGE_REASON_BROKEN = 2             // 破損
export const EXCHANGE_REASON_INITIAL_FAILURE = 3    // 初期不良
export const EXCHANGE_REASON_LOST = 4               // 紛失
export const EXCHANGE_REASON_AGEING_DEGRADATION = 5 // 経年劣化
