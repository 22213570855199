import React from 'react'
import { Collapse, Divider, Icon, List, Popover, Typography } from 'antd'

export function ProductSetContentsCollapse({
  items,
  header = null,
  loading = false,
  onOpen
}) {
  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <Icon type="caret-right" rotate={isActive ? 90 : 0} />
      )}
      onChange={activeKeys => {
        if (activeKeys.length > 0 && typeof onOpen === 'function') {
          onOpen();
        }
      }}
    >
      <Collapse.Panel
        key={1}
        header={
          <span className="text-sm text-header">{header || 'セット内容'}</span>
        }
        style={{ border: 0 }}
      >
        <ProductSetContents items={items} loading={loading} />
      </Collapse.Panel>
    </Collapse>
  )
}

const { Text } = Typography

export function ProductSetContentsPopover({ items, header = null }) {
  return (
    <Popover
      trigger="click"
      placement="bottom"
      content={<ProductSetContents items={items} bordered={false} />}
      overlayStyle={{ minWidth: 300 }}
    >
      <span className="icon-text">
        <Icon type="caret-right" />
        <Text underline className="clickable">{header || 'セット内容'}</Text>
      </span>
    </Popover>
  )
}

export default function ProductSetContents({
  items,
  bordered = true,
  loading = false
}) {
  return (
    <List
      loading={loading}
      style={{ backgroundColor: '#fff' }}
      size="small"
      bordered={bordered}
      dataSource={items}
      renderItem={setItem => (
        <List.Item>
          <List.Item.Meta
            title={
              <>
                <span>{setItem.title}</span>
                <Divider type="vertical" />
                <span>{setItem.size}</span>
              </>
            }
            description={
              <span className="text-sm">{setItem.pNo}</span>
            }
          />
          <span>{setItem.setCount}点</span>
        </List.Item>
      )}
    />
  )
}
