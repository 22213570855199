import React, { useState, useEffect } from 'react'
import {
  Button,
  Row,
  Col,
  List,
  Divider,
  Spin,
  Table,
  Card,
  Icon,
} from 'antd'
import { useLocation } from '@reach/router'
import Page from '../../components/Page'
import { SetContentsCollapse } from '../../components/SetContents'
import Image from '../../components/Image'
import OrderTag from '../../components/OrderTag'
import OrderType from '../../components/OrderType'
import AppLink from '../../components/AppLink'
import ReturnTags from '../../components/ReturnTags'
import ReturnCard from '../../components/ReturnCard'
import CancelModal from './components/CancelModal'
import { dateFormat, numFormat } from '../../formatter'
import { notifySuccess } from '../../notify'
import rentalApi from '../../api/rental'
import returnAcceptApi from '../../api/return-accept'
import { 
          RESPONSE_OK, 
          RETURN_STATUS_OK,
          ORDER_STATUS_CANCEL,
        } from '../../constants'
import fetchOrder from './fetch-order'
import { useIsAdmin } from '../../hooks/auth'
import LinkButton from '../../components/LinkButton'
import useFetchReturnInfo from './hooks/fetch-return-info'
import queryString from "query-string"

const { Column } = Table

function ReturnShow({ id }) {
  const isAdmin = useIsAdmin()
  const [order, setOrder] = useState(null)
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)

  const isReturned = order ? order.returnStatus === RETURN_STATUS_OK : false
  const showReturnAccept = isAdmin && !isReturned

  const hasReturnTags = (
    order ? order.status !== ORDER_STATUS_CANCEL : false
  )

  useEffect(() => {
    if (!id) return () => {}
    (async () => {
      setLoading(true)
      const result = await fetchOrder(id)
      if (result) {
        const { productList, orderInfo } = result
        setOrder(orderInfo)
        setProducts(productList)
      }
      setLoading(false)
    })()
  }, [id])

  const { returnInfo } =
    useFetchReturnInfo({ id, setLoading, isAdmin })
  const [cancelModalVisible, setCancelModalVisible] = useState(false)

  const location = useLocation()
  const to = "/orders?" + queryString.stringify(location.state, { skipEmptyString: true })

  // キャンセル
  const handleCancelOk = async () => {
    setCancelModalVisible(false)
    setLoading(true)
    const response = await rentalApi.dropCancel(id)
    setLoading(false)
    if (response.status === RESPONSE_OK) {
      setLoading(true)
      const result = await fetchOrder(id)
      if (result) {
        const { productList, orderInfo } = result
        setOrder(orderInfo)
        setProducts(productList)
      }
      setLoading(false)
      notifySuccess({ message: '注文をキャンセルしました。' })
    }
  }

  // 返品依頼メール送信
  const sendReminderMail = async () => {
    const response = await returnAcceptApi.sendReminderMail(id)
    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: '返品依頼メールを送信しました。' })
    }
  }

  const renderListItem = item => (
    <List.Item>
      <div style={{ width: `100%` }}>
        <OrderRow item={item} />
      </div>
    </List.Item>
  )

  return (
    <Page title="注文内容の詳細">
      <Spin spinning={loading}>
        <div className="text-left">
          <AppLink to={to} >
            <Icon type="left" className="mr-1"/>
            <span>注文一覧に戻る</span>
          </AppLink>
        </div>
        <Divider />
        {order && (
          <>
            <OrderHeader
              order={order}
              canCancel={order.canCancel}
              onCancel={() => setCancelModalVisible(true)}
              showReturnAccept={showReturnAccept}
              onReminder={sendReminderMail}
              hasReturnTags={hasReturnTags}
            />

            <div className="mt-3 mb-3">
              <OrderInfoPanel order={order} returnInfo={returnInfo} hasReturnTags={hasReturnTags}/>
            </div>
            {hasReturnTags && (
              <div className="mb-2">
                <ReturnCard id={id} highlighted={!isReturned} />
              </div>
            )}
          </>
        )}

        {order && (
          <List
            bordered={false}
            dataSource={products}
            renderItem={renderListItem}
          />
        )}

        {(isAdmin && returnInfo) && (
          <div className="mt-3 mb-3">
            <Card title={<span className="text-base">返品情報</span>}>
              <ReturnInfoTable products={returnInfo.returnProductList}/>
              <div className="panel mt-3">
                <ul className="plain-list">
                  <li>
                    <Row>
                      <Col span={6}>返品処理日</Col>
                      <Col span={18}>{dateFormat(returnInfo.checkedAt)}</Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col span={6}>備考</Col>
                      <Col span={18}>{returnInfo.adminComment || '-'}</Col>
                    </Row>
                  </li>
                </ul>
              </div>
            </Card>
          </div>
        )}
      </Spin>

      {order && (
        <>
          <CancelModal
            id={order.orderId}
            visible={cancelModalVisible}
            onOk={handleCancelOk}
            onCancel={() => setCancelModalVisible(false)}
          />
        </>
      )}
    </Page>
  )
}

function OrderHeader({ order, canCancel, onCancel, showReturnAccept, onReminder, hasReturnTags }) {
  return (
    <Row type="flex" align="middle" justify="space-between">
      <Col>
        <OrderType type={order.typeCode} name={order.typeName} />
        <Divider type="vertical" />
        <OrderTag status={order.status} type={order.typeCode}>
          {order.statusName}
        </OrderTag>
        {hasReturnTags && (
          <ReturnTags
            status={order.returnStatus}
            orderType={order.typeCode} 
            checkedDate={order.checkedAt}
          />
        )}
      </Col>
      <Col>
        {canCancel && (
          <Button onClick={onCancel} className="ml-1">
            注文をキャンセルする
          </Button>
        )}
        {showReturnAccept && (
          <LinkButton
            to={`/returns/${order.orderId}/accept`}
            app
            type="default"
          >
            返品処理を開始する
          </LinkButton>
        )}
        {showReturnAccept && (
          <Button onClick={onReminder} className="ml-1">
            返品依頼メール送信
          </Button>
        )}
      </Col>
    </Row>
  )
}

function OrderInfoPanel({ order, returnInfo, hasReturnTags }) {
  return (
    <div className="panel">
      <ul className="plain-list">
        <li>
          <Row>
            <Col span={6}>解約番号</Col>
            <Col span={18}>{order.orderId}</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>解約者</Col>
            <Col span={18}>{order.storeName} 様</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>電話番号</Col>
            <Col span={18}>{order.tel}</Col>
          </Row>
        </li>

        {order.orderedAt && (
          <li>
            <Row>
              <Col span={6}>解約申込日</Col>
              <Col span={18}>{dateFormat(order.orderedAt)}</Col>
            </Row>
          </li>
        )}
        {order.canceledAt && (
          <li>
            <Row>
              <Col span={6}>キャンセル日</Col>
              <Col span={18}>{dateFormat(order.canceledAt)}</Col>
            </Row>
          </li>
        )}
        {hasReturnTags && (
          <li>
            <Row>
              <Col span={6}>返品完了日</Col>
              <Col span={18}>
              {order.checkedAt ? 
                <span>{dateFormat(order.checkedAt)}</span>
              : <span className="text-red">申し込み後は、かならず商品の返送を行ってください。</span>
              }
              </Col>
            </Row>
          </li>
        )}

        {returnInfo && (
          <li>
            <Row>
              <Col span={6}>備考</Col>
              <Col span={18}>
                <span>{returnInfo.adminComment}</span>
              </Col>
            </Row>
          </li>
        )}
      </ul>
    </div>
  )
}

function OrderRow({ item }) {
  const { product } = item
  const { details } = product

  return (
    <>
      <Row gutter={16} type="flex" align="middle">
        <Col span={4}>
          <Image src={product.mainImageUrl} />
        </Col>
        <Col span={16}>
          <p className="mb-1">
            <AppLink to={`/products/${product.productId}`}>
              {product.title}
            </AppLink>
          </p>
          <div className="mb-1">
            {details.sexName}
            <Divider type="vertical" />
            {details.size}
            <Divider type="vertical" />
            {details.typeName}
          </div>
          <p>{details.pNo}</p>
        </Col>
        <Col span={4}>解約数：{numFormat(item.orderCount)}</Col>
      </Row>
      <div className="mt-1">
        {details.set && (
          <SetContentsCollapse item={details} title={details.setTitle}/>
        )}
      </div>
    </>
  )
}

function ReturnInfoTable({products}) {
  return (
    <Table
    bordered
    pagination={false}
    dataSource={products}
    rowKey="pNo"
  >
    <Column
      key="product"
      title="商品"
      render={(_, record) => (
        <div className="text-paragraph">
          <div>
            <AppLink to={`/products/${record.productId}`}>
              {record.productTitle}
            </AppLink>
            {record.title && (
              <>
                <Divider type="vertical" />
                {record.title}
              </>
            )}
          </div>
          <div>
            {record.contentTitle && (
              <>
                {record.contentTitle}
                <Divider type="vertical" />
              </>
            )}
            {record.pNo}
            <Divider type="vertical" />
            {record.size}
          </div>
        </div>
      )}
    />
    <Column
      key="orderCount"
      title="申込数"
      dataIndex="orderCount"
      className="min-cell"
    />
    <Column
      key="ok"
      title="良品数"
      dataIndex="okCount"
      className="min-cell success-cell"
    />
    <Column
      key="repair"
      title="修繕数"
      dataIndex="repairCount"
      className="min-cell warning-cell"
    />
    <Column
      key="discard"
      title="破棄数"
      dataIndex="discardCount"
      className="min-cell error-cell"
    />
  </Table>
  )
}

export default ReturnShow
