import {
  USAGE_STATUS_TYPE1, USAGE_STATUS_TYPE2,
  PAYMENT_SUMMARY_TYPE_COMPANY, PAYMENT_SUMMARY_TYPE_STORE
} from './constants'
export const customerMenuGroup = user => {
  const buy = {
    key: 'buy',
    title: '購入',
    icon: 'skin',
    items: [
      { label: '商品を探す', path: 'products' },
      { label: 'カートを見る', path: 'cart' },
      { label: '注文履歴', path: 'orders' },
    ]
  }

  const account = {
    key: 'accounts',
    title: 'アカウント一覧',
    icon: 'team',
    path: 'accounts'
  }

  const pay1 = {
    key: 'pay',
    title: 'お支払い',
    icon: 'pay-circle',
    items: [
      { label: 'お支払い情報', path: 'payments' },
      { label: '店舗ごとの集計', path: 'summary' },
    ]
  }

  const pay2 = {
    key: 'pay',
    title: 'お支払い',
    icon: 'pay-circle',
    items: [
      { label: 'お支払い情報', path: 'payments' }
    ]
  }

  const qa = {
    key: 'qa',
    title: 'お問い合わせ',
    icon: 'question-circle',
    items: [
      { label: 'ヘルプ・規約', path: 'guides'},
      { label: 'お問い合わせ', path: 'forums'},
    ]
  }

  const stock1 = {
    key: 'stock',
    title: '商品管理',
    icon: 'container',
    items: [
      { label: '在庫一覧', path: 'stocks'},
    ]
  }

  const stock2 = {
    key: 'stock',
    title: '商品管理',
    icon: 'container',
    items: [
      { label: '利用期間検索', path: 'rental-item-search'}
    ]
  }

  if (user.isRental) {
    buy.items.push({
      label: 'レンタル状況',
      path: `rental-status/${user.accountId}`,
    })
    stock1.items.push({ label: '利用期間検索', path: 'rental-item-search' })

    if (user.isCompanyAdmin || user.isParentStore) {
      buy.items.push({ label: '店舗レンタル状況', path: 'rental-status' })
    }
  }

  if (user.company.isStatusUse === USAGE_STATUS_TYPE1) {
    buy.items.push({ label: '利用状況の確認・変更', path: 'usage-status'})
  }

  if (user.company.isStatusUse === USAGE_STATUS_TYPE2) {
    buy.items.push({ label: '利用状況の確認', path: 'usage-status'})
  }

  if (user.isCompanyAdmin) {
    return [account, buy, stock1, pay1, qa]
  }

  if (user.isParentStore) {
    if (user.isRental) {
      return [account, buy, stock2, qa]
    }
    if (user.store.paymentSummaryType === PAYMENT_SUMMARY_TYPE_STORE) {
      return [account, buy, pay2, qa]
    } else {
      return [account, buy, qa]
    }
  }

  if (!user.isCompanyAdmin && user.isRental) {
    return [buy, stock2, qa]
  }

  if (user.store.paymentSummaryType === PAYMENT_SUMMARY_TYPE_STORE) {
    return [buy, pay2, qa]
  } else {
    return [buy, qa]
  }
}

export const adminMenuGroup = () => ([
  { title: '注文履歴', icon: 'shopping', path: 'orders' },
  { title: '請求管理', icon: 'pay-circle', path: 'billings' },
  { 
    key: 'csv',
    title: 'CSV入出力', 
    icon: 'table', 
    items: [
      { label: 'ダウンロード', path: 'csv-download' },
      { label: 'アップロード', path: 'csv-upload' }
    ]
  },
  { title: 'アカウント登録', icon: 'user-add', path: 'accounts/create'},
  { title: 'バッチ実行', icon: 'table', path: 'batch' },
  {
    key: 'stock',
    title: '商品管理',
    icon: 'container' ,
    items: [
      { label: '在庫一覧', path: 'stocks'},
      { label: '利用期間検索', path: 'rental-item-search'},
    ]
  },
  {
    key: 'qa',
    title: 'お問い合わせ',
    icon: 'question-circle' ,
    items: [
      { label: 'ヘルプ・規約', path: 'guides'},
      { label: 'お問い合わせ', path: 'forums'},
    ]
  }
])

export const sellerMenuGroup = user => {
  const buyItems = [
    { label: '商品を探す', path: 'products' },
    { label: 'カートを見る', path: 'cart' },
    { label: '注文履歴', path: 'orders' },
  ];

  if (user.isRental) {
    buyItems.push({
      label: 'レンタル状況',
      path: `rental-status/${user.accountId}`,
    })

    if (user.isCompanyAdmin || user.isParentStore) {
      buyItems.push({ label: '店舗レンタル状況', path: 'rental-status' })
    }
  }

  if (user.company.isStatusUse === USAGE_STATUS_TYPE1) {
    buyItems.push({ label: '利用状況の確認・変更', path: 'usage-status'})
  }

  if (user.company.isStatusUse === USAGE_STATUS_TYPE2) {
    buyItems.push({ label: '利用状況の確認', path: 'usage-status'})
  }

  return [
    {
      key: 'sell',
      title: '販売',
      icon: 'shop',
      items: [
        { label: 'アカウント一覧', path: 'accounts'},
      ]
    },
    { key: 'buy', title: '購入', icon: 'skin', items: buyItems },
    {
      key: 'stock',
      title: '商品管理',
      icon: 'container',
      items: [
        { label: '在庫一覧', path: 'stocks'}
      ]
    },
    {
      key: 'deal',
      title: '売上・請求・支払',
      icon: 'pay-circle',
      items: [
        { label: '売上情報', path: 'proceeds'},
        { label: '請求情報', path: 'billings'},
        { label: '支払情報', path: 'payments'},
        { label: '店舗ごとの集計', path: 'summary' },
      ]
    },
    {
      key: 'qa',
      title: 'お問い合わせ',
      icon: 'question-circle' ,
      items: [
        { label: 'ヘルプ・規約', path: 'guides'},
        { label: 'お問い合わせ', path: 'forums'},
      ]
    },
  ]
}