import { useEffect, useState } from 'react'
import accountApi from '../api/accounts'

export default function useSalesFromTo(accountId) {
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')

  useEffect(() => {
    (async () => {
      const response = await accountApi.show(accountId)
      setFrom(response.data.salesFromCompanyName)
      setTo(response.data.companyName + ' ' + response.data.storeName)
    })()
  }, [accountId])

  return [from, to]
}
