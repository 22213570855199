import React, { useState } from 'react'
import { Button, Row, Col, Divider, Checkbox } from 'antd'
import LinkButton from '../../../components/LinkButton'
import { existsUnselectedItem, updatedSelectedUsageStatusItems, transformFormItems } from '../util/compute'

// 利用状況変更フォーム
const UsageStatusChangeForm = ({ usageStatusItems, selectedUsageStatusItems, setSelectedUsageStatusItems, dataLoading }) => {
  const [checked, setChecked] = useState(false)
  return (
    <Row type="flex" align="middle">
      <Col>
        <Checkbox
          checked={selectedUsageStatusItems.length > 0 ? existsUnselectedItem(selectedUsageStatusItems) ? false : checked: false}
          onChange={(item) => {
            setChecked(item.target.checked)
            setSelectedUsageStatusItems(updatedSelectedUsageStatusItems(usageStatusItems, !item.target.checked))
          }}
        >すべてチェック</Checkbox>
        <Divider type="vertical"/>
        <LinkButton to='/usage-status/change' state={{changeUsageStatusInfoList: transformFormItems(selectedUsageStatusItems)}} small ghost app>
          <Button
            htmlType="button"
            type="primary"
            disabled={transformFormItems(selectedUsageStatusItems).length === 0 || dataLoading}
            ghost className="mr-1"
          >
            チェックした商品の次回配送を変更
          </Button>
        </LinkButton>
      </Col>
      <Col className="text-sm" style={{ paddingLeft: 10, paddingTop: 10, marginBottom: -20 }}>
        ※次回配送月・配送変更期日は発送完了後に表示されます。その期間までは次回配送の変更は行えません。
      </Col>
    </Row>
  )
}

export default UsageStatusChangeForm