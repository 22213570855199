export default class ShoppingList {
  constructor(orders) {
    this.items = orders
  }

  putList(newOrder) {
    const items = this.items.map(order => {
      if (order.pNo === newOrder.pNo) {
        order.orderCount = newOrder.orderCount
      }
      return order
    })

    return new ShoppingList(items)
  }

  isEmpty() {
    return (
      this.items.length === 0 ||
      this.items.every(item => item.orderCount === 0)
    )
  }

  countOfProductNo(pNo) {
    const orderItem = this.items.find(o => o.pNo === pNo)
    return orderItem ? orderItem.orderCount : 0
  }

  productSizeList = () => Array.from(new Set(this.items.map(item => item.size)))

  get postData() {
    return this.items.filter(item => item.orderCount > 0).map(item => { const { size: {}, ...data} = item; return data })
  }
}
