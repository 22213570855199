import React from 'react'
import { Table } from 'antd'
import { extractDifferenceFromBefore } from '../util/compute'
import moment from 'moment'

// 次回配送内容変更確認テーブル
const NextDeliveryChangeConfirmTable = ({ dataSource, editedItems }) => {
  return (
    <Table bordered pagination={false} dataSource={dataSource} size="small" rowKey={record => record.itemStatusId}>
      <Column title="商品名" dataIndex="nextDeliveryItemTitle" key="title" width='30%' render={(value) => renderRow(value, false)} />
      <Column title="サイズ" key="size" width='15%' align="center" render={(_, record, itemIndex) => {
        const [isDifference, afterValue] = extractDifferenceFromBefore({
          items: editedItems,
          index: itemIndex,
          beforeItem: record,
          key: 'nextDeliveryItemSize'
        })
        return renderRowCenter(isDifference ? record.nextDeliveryItemSize + " → " + afterValue: afterValue, isDifference)
      }} />
      <Column title="数量" key="quantity" width='10%' align="center" render={(_, record, itemIndex) => {
        const [isDifference, afterValue] = extractDifferenceFromBefore({
          items: editedItems,
          index: itemIndex,
          beforeItem: record,
          key: 'nextDeliveryItemCount'
        })
        return renderRowCenter(isDifference ? record.nextDeliveryItemCount + " → " + afterValue: afterValue, isDifference)
      }} />
      <Column title="次回発送月" key="nextDeliveryMonth" width='15%' align="center" render={(_, record, itemIndex) => {
        const [isDifferenceDeliverySetting, deliverySettingAfterValue] = extractDifferenceFromBefore({
          items: editedItems,
          index: itemIndex,
          beforeItem: record,
          key: 'isSuspendedDelivery'
        })
        const [isDifferenceDeliveryMonth, deliveryMonthAfterValue] = extractDifferenceFromBefore({
          items: editedItems,
          index: itemIndex,
          beforeItem: record,
          key: 'nextDeliveryMonth'
        })
        return renderRowCenter(
          (deliverySettingAfterValue ? "ー": deliveryMonthAfterValue),
          isDifferenceDeliverySetting || isDifferenceDeliveryMonth
        )
      }} />
      <Column title="次回発送" key="nextDeliverySelection" width='15%' align="center" render={(_, record, itemIndex) => {
        const [isDifference, afterValue] = extractDifferenceFromBefore({
          items: editedItems,
          index: itemIndex,
          beforeItem: record,
          key: 'isSuspendedDelivery'
        })
        return renderRowCenter((afterValue ? "停止": isDifference ? "再開": "継続"), isDifference)
      }} />
      <Column title="変更可能期限" key="closingDate" width='15%' align="center" render={(_, record, itemIndex) => {
        const [isDifference, afterValue] = extractDifferenceFromBefore({
          items: editedItems,
          index: itemIndex,
          beforeItem: record,
          key: 'nextDeliveryMonth'
        })
        const closingDate = moment(afterValue, "YYYY/MM").subtract(1, 'months').startOf('month').add(14, 'days').format('YYYY-MM-DD')
        return renderRowCenter(isDifference ? closingDate : record.closingDate, isDifference)
      }}/>
    </Table>
  )
}
const { Column } = Table
const renderRow = (element, isDifference) => (<span><div className={"text-paragraph" + (isDifference ? " text-red":"")}>{element}</div></span>)
const renderRowCenter = (element, isDifference) => (<span><div style={{textAlign: "center"}} className={"text-paragraph" + (isDifference ? " text-red":"")}>{element}</div></span>)
const listStyle = { width: '100%' }

export default NextDeliveryChangeConfirmTable