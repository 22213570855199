import React from 'react'
import { Table } from 'antd'

// 利用状況リストテーブル
const UsageStatusOnlyListTable = ({ dataSource, rowSelection}) => {
  return (
    <Table bordered pagination={false} dataSource={dataSource} size="small" rowKey={record => record.itemStatusId} rowSelection={rowSelection}>
      <Column title="商品名" dataIndex="useItemTitle" key="useItemTitle" width='30%'render={renderRow} />
      <Column title="新品・中古" dataIndex="useItemProductType" key="useItemProductType" width='10%'align="center" render={renderRowCenter} />
      <Column title="サイズ" dataIndex="useItemSize" key="useItemSize" width='10%' align="center" render={renderRowCenter} />
      <Column title="数量" dataIndex="useItemCount" key="useItemCount" width='10%' align="center" render={renderRowCenter} />
      <Column title="初回注文日" dataIndex="firstOrderDate" key="firstOrderDate" width='20%' align="center" render={renderRowCenter} />
      <Column title="個人用バーコード" dataIndex="barcodeText" key="barcodeText" width='20%' align="center" render={renderRowCenter} />
    </Table>
  )
}
const { Column } = Table
const renderRow = (element) => (<span><div className="text-paragraph">{element}</div></span>)
const renderRowCenter = (element) => (<span><div style={{textAlign: "center"}} className="text-paragraph">{element}</div></span>)

export default UsageStatusOnlyListTable
