import React, { useState } from 'react'
import Page from '../../components/Page'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'
import useStep from '../../hooks/step'
import useRentalProduct from '../../hooks/rental-product'
import { cancel as rentalCancelApi } from '../../api/rental'

export default function RentalCancel({ accountId, pNo }) {
  const { incrementStep, decrementStep, isStep } = useStep(1)
  const product = useRentalProduct({ accountId, pNo })
  const [cancelCount, setCancelCount] = useState(0)
  const [orderId, setOrderId] = useState(null)
  const [loading, setLoading] = useState(false)

  const pageTitle = (() => {
    if (isStep(1)) return '解約申し込み'
    if (isStep(2)) return '申し込み内容の確認'
    if (isStep(3)) return '解約申し込み完了'
  })()

  const submit = async () => {
    const data = { accountId, pno:pNo, orderCount: cancelCount }
    setLoading(true)
    const response = await rentalCancelApi(data)
    setLoading(false)
    setOrderId(response.data)
    incrementStep()
  }

  return (
    <Page title={pageTitle}>
      {isStep(1) && (
        <Step1
          product={product}
          cancelCount={cancelCount}
          onCancelCountChange={setCancelCount}
          onNextClick={incrementStep}
        />
      )}

      {isStep(2) && (
        <Step2
          product={product}
          cancelCount={cancelCount}
          onPrevClick={decrementStep}
          onNextClick={submit}
          loading={loading}
        />
      )}

      {isStep(3) && (
        <Step3 orderId={orderId} />
      )}
    </Page>
  )
}
