import React from 'react'
import { Layout as AntLayout } from 'antd'
import Sidebar, { sidebarWidth } from './Sidebar'
import Footer from './Footer'
import { useAuth } from '../hooks/auth'

const wrapperStyles = sidebar => ({
  minHeight: '100vh',
  marginLeft: sidebar ? sidebarWidth : 0,
})

function Layout({ children }) {
  const isAuth = useAuth()

  return (
    <AntLayout>
      {isAuth && <Sidebar />}
      <div style={{ width: `100%` }}>
        <AntLayout style={wrapperStyles(isAuth)}>
          <AntLayout children={children} />
          {isAuth && <Footer />}
        </AntLayout>
      </div>
    </AntLayout>
  )
}

export default Layout
