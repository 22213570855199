import { useEffect, useState } from 'react'
import { updatedSelectedUsageStatusItems } from '../util/compute'

const useSelectUsageStatus = (usageStatusItems, query, setQuery) => {
  const [selectedUsageStatusItems, setSelectedUsageStatusItems] = useState([])

  useEffect(() => {
    setSelectedUsageStatusItems(updatedSelectedUsageStatusItems(usageStatusItems, true))
  }, [usageStatusItems])

  const handlePaginate = page =>  {
    setQuery(Object.assign({}, query, { page }))
    setSelectedUsageStatusItems(updatedSelectedUsageStatusItems(usageStatusItems, true))
  }
  return { selectedUsageStatusItems, setSelectedUsageStatusItems, handlePaginate }
}

export default useSelectUsageStatus