import React from "react"
import { Card, Divider } from "antd"

// 社員情報
const StaffInfo = ({staffInfo}) => {
  return (
    <div className="mb-4">
      <div className="mb-2">
        <span className="text-bold">社員情報</span>
      </div>
        <Card className="mb-1">
          <span>社員番号：{staffInfo.staffNo}</span>
          <Divider type="vertical" />
          <span>社員名：{staffInfo.staffName}</span>
          <Divider type="vertical" />
          <span>所属店舗：{staffInfo.storeName}</span>
          {staffInfo.isPersonalOrder && <div className="mt-1">注文分類：個人注文</div>}
        </Card>
    </div>
  )
}

export default StaffInfo