import { Button, Card, Col, Icon, Row, Spin, Upload, Form, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import Page from '../components/Page'
import restockOrderApi from '../api/restock-order'
import saleApi from '../api/sale'
import { notifySuccess, notifyError } from '../notify'
import { RESPONSE_OK } from '../constants'
import ErrorShowModal from '../components/ErrorShowModal'

const inputStyle = { width: '100%' }
const { Option } = Select

// CSVアップロード
const CSVUpload = () => {
  const [loading, setLoading] = useState(false)
  const [salesFromToList, setSalesFromToList] = useState([])

  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await saleApi.fromTo()
      if (response.status === RESPONSE_OK) {
        setSalesFromToList(response.data)
      }
      setLoading(false)
    })()
  }, [])

  return (
    <Page title="CSVアップロード">
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReturnedData loading={loading} setLoading={setLoading} />
        </div>
        <div className="mb-3">
          <SaleProductSortData loading={loading} setLoading={setLoading} salesFromToList={salesFromToList} />
        </div>
      </Spin>
    </Page>
  )
}

// 返品データアップロード
const ReturnedData = ({loading, setLoading}) => {
  const [fileList, setFileList] = useState([])
  const [visibleError, setVisibleError] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const onRemove = () =>  setFileList([])
  const beforeUpload = file => { setFileList([file]); return false }
  const handleUpload = async () => {
    setLoading(true)
    const response = await restockOrderApi.fileUpload(fileList[0])
    setLoading(false)
    if (response.status === RESPONSE_OK) {
      if (response.data.errorMessages !== undefined && response.data.errorMessages.length > 0) {
        setVisibleError(true)
        setErrorMessages(response.data.errorMessages)
      } else {
        notifySuccess({ message: '返品データが登録されました。' })
        setFileList([])
      }
    } else {
      notifyError({ message : 'データの登録に失敗しました。'})
    }
  }

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">返品データアップロード</p>
          <p> 返品する情報が記載されたCSVファイルをアップロードして登録します。<br/>
          対象の注文番号が存在しない場合や、注文数量を超えている場合は、返品登録に失敗します。
          1件でも返品登録に失敗した場合は、全てロールバックされるため、再度登録して下さい。
          </p>
          <p className="text-bold mb-1 text-sm">アップロードファイルのフォーマット例</p>
          <div className="panel" style={{margin: 0, padding: 15}}>
            注文番号,品番,数量<br />
            A123456,P123456,1<br />
            A123457,P123457,2<br />
            A123458,P123458,3<br />
          </div>
        </Col>
        <Col span={8}>
          <div className="panel text-center" type="flex" align="middle">
            <Upload multiple={false} accept=".csv" fileList={fileList} beforeUpload={beforeUpload} onRemove={onRemove} >
              <Button><Icon type="upload"/>{!fileList.length ? "返品用データを選択する" : "別のデータを選択する"}</Button>
            </Upload>
            <Button type="primary" disabled={!fileList.length} loading={loading} style={{ marginTop: 16 }}onClick={handleUpload}>
              {loading ? 'アップロード中' : 'アップロードを実行する'}
            </Button>
          </div>
        </Col>
      </Row>
      <ErrorShowModal
        title='返品登録に失敗しました'
        visible={visibleError}
        setVisible={setVisibleError}
        errorMessages={errorMessages}
        setErrorMessages={setErrorMessages}
      />
    </Card>
  )
}

// 販売商品並び替えデータアップロード
const SaleProductSortData = ({loading, setLoading, salesFromToList}) => {
  const [toFromId, setToFromId] = useState("")
  const [fileList, setFileList] = useState([])
  const [visibleError, setVisibleError] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const onRemove = () =>  setFileList([])
  const beforeUpload = file => { setFileList([file]); return false }
  const handleDownload = async () => {
    setLoading(true)
    await saleApi.fileDownloadWithSalesSortTemplate({
      toId: parseInt(toFromId.split("_")[0]),
      salesFromCompanyId: parseInt(toFromId.split("_")[1])
    })
    setLoading(false)
  }
  const handleUpload = async () => {
    setLoading(true)
    const response = await saleApi.fileUpload(fileList[0])
    setLoading(false)
    if (response.status === RESPONSE_OK) {
      if (response.data.errorMessages !== undefined && response.data.errorMessages.length > 0) {
        setVisibleError(true)
        setErrorMessages(response.data.errorMessages)
      } else {
        notifySuccess({ message: '並び替えデータが登録されました。' })
        setFileList([])
      }
    } else {
      notifyError({ message : 'データの登録に失敗しました。'})
    }
  }

  return (
    <Card>
      <Row gutter={40}>
        <Col span={40}>
          <p className="text-bold mb-2">販売商品並び替えデータアップロード</p>
          <Col>
            <Form.Item label="[販売先] [販売元] ">
              <Select
                showSearch
                optionFilterProp="children"
                allowClear
                style={inputStyle}
                value={toFromId}
                onChange={val => {
                  setToFromId(val)
                }}
              >
                {salesFromToList.map(item => (
                  <Option key={`${item.toId}_${item.salesFromCompanyId}`} value={`${item.toId}_${item.salesFromCompanyId}`}>
                    {"[販売先] " + item.toName + " ← " + "[販売元] " + item.salesFromCompanyName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Col>
      </Row>
      <Row span={40} style={{display: "flex", justifyContent: "center"}}>
        <Col span={8} className="panel text-center" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Button type="primary" disabled={!toFromId.length} loading={loading} style={{ height: "auto", padding: 20 }}onClick={handleDownload}>
            {loading ? 'ローディング中' : '並び替え用テンプレート'}<br/>
            {loading ? '' : 'をダウンロードする'}
          </Button>
        </Col>
        <div style={{width: 20}}/>
        <Col span={8} className="panel text-center" type="flex" align="middle">
          <Upload multiple={false} accept=".csv" fileList={fileList} beforeUpload={beforeUpload} onRemove={onRemove} >
            <Button><Icon type="upload"/>{!fileList.length ? "並び替えデータを選択する" : "別のデータを選択する"}</Button>
          </Upload>
          <Button type="primary" disabled={!fileList.length} loading={loading} style={{ marginTop: 16 }}onClick={handleUpload}>
            {loading ? 'アップロード中' : 'アップロードを実行する'}
          </Button>
        </Col>
      </Row>
      <ErrorShowModal
        title='並び替え登録に失敗しました'
        visible={visibleError}
        setVisible={setVisibleError}
        errorMessages={errorMessages}
        setErrorMessages={setErrorMessages}
      />
    </Card>
  )
}

export default CSVUpload
