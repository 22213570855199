import React from 'react'
import { Icon } from 'antd'
import {
  ORDER_TYPE_CHANGE,
  ORDER_TYPE_CANCEL,
  ORDER_TYPE_RENTAL,
  ORDER_TYPE_SHOPPING,
  ORDER_TYPE_TRANSFER
} from '../constants'

function getIconInfo(type) {
  switch (type) {
    case ORDER_TYPE_SHOPPING:
      return ['text-primary', 'shopping']
    case ORDER_TYPE_RENTAL:
      return ['text-primary', 'shopping']
    case ORDER_TYPE_CHANGE:
      return ['text-gold', 'swap']
    case ORDER_TYPE_CANCEL:
      return ['text-mute', 'stop']
    case ORDER_TYPE_TRANSFER:
      return ['text-geekblue', 'double-right']
    default:
      return ['text-primary', 'shopping']
  }
}

function OrderType({ type, name }) {
  const [color, iconType] = getIconInfo(type)

  return (
    <>
      <span className={['mr-1', 'text-xl', color].join(' ')}>
        <Icon type={iconType} />
      </span>
      <span className="text-lg">{name}</span>
    </>
  )
}

export default OrderType
