import { notification } from 'antd'

function createOption({ key, autoClose, message, description }) {
  const option = {
    className: `notification`,
    placement: `topRight`,
    message
  }

  if (key) {
    option.key = key
  }

  if (!autoClose) {
    option.duration = null
  }

  if (description) {
    option.description = description
  }

  return option
}

export function notifySuccess({ key, autoClose = true, message, description}) {
  const option = createOption({ key, autoClose, message, description});
  notification.success(option)
}

export function notifyError({ key, autoClose = true, message, description }) {
  const option = createOption({ key, autoClose, message, description});
  notification.error(option)
}