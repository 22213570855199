import React from 'react'
import { Button, Card, Col, Divider, Row, Select, Icon } from 'antd'
import Image from '../../../components/Image'
import AppLink from '../../../components/AppLink'
import { ProductSetContentsPopover } from '../../../components/ProductSetContents'
import { range } from '../../../arrays'

const { Option } = Select

export default function Step1({
  transferFromStore,
  transferToStoreId,
  stores,
  onStoreSelect,
  product,
  transferCount,
  onTransferCountChange,
  onNextClick
}) {
  if (!product) return null

  stores = stores.filter(function(store){
    return store.storeId !== transferFromStore.storeId
  })

  return (
    <>
      <p className="text-bold mb-2">店舗移動する商品</p>

      <div className="mb-3">
        <Card size="small">
          <Row gutter={16} type="flex" align="middle">
            <Col span={3}>
              <Image src={product.image ? product.image.imageName : null} />
            </Col>
            <Col span={12}>
              <p className="mb-1">
                <AppLink to={`/products/${product.productId}`}>
                  {product.productTitle}
                </AppLink>
              </p>
              <div>
                {product.sexName}
                <Divider type="vertical" />
                {product.size}
                <Divider type="vertical" />
                {product.pNo}
              </div>
              {product.isSet && (
                <div className="mt-2">
                  <ProductSetContentsPopover
                    items={product.setContents}
                    header={product.title}
                  />
                </div>
              )}
            </Col>
            <Col span={9} >
              <span className="mr-2">レンタル中</span>
              <span>{product.status.rental}</span>
            </Col>
          </Row>
        </Card>
      </div>

      <p className="text-bold mb-2">移動情報入力</p>
      <div className="mb-3">
        <Card size="small">
          <Row gutter={16} type="flex" align="middle">
            <Col span={9}>
              <Row gutter={8} type="flex" align="middle">
                <Col span={6} className="text-left">移動元店舗</Col>
                <Col span={18}>
                  {transferFromStore.storeName}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="text-center">
              <Icon type="arrow-right"></Icon>
            </Col>
            <Col span={9}>
              <Row gutter={8} type="flex" align="middle">
                <Col span={6} className="text-left">移動先店舗</Col>
                <Col span={18}>
                  <Select
                    style={{ width: '100%' }}
                    value={transferToStoreId}
                    onChange={onStoreSelect}
                    showSearch
                    optionFilterProp="children"
                  >
                    {stores.map((store) => (
                      <Option value={store.storeId} key={store.storeId}>
                        {store.storeName}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col span={1} className="text-right">
              <Divider type="vertical" />
            </Col>
            <Col span={4}>
              <Row gutter={8} type="flex" align="middle" justify="end">
                <Col span={8} className="text-right">移動数</Col>
                <Col span={16}>
                  <Select
                    style={{ width: '100%' }}
                    value={transferCount}
                    onChange={onTransferCountChange}
                  >
                    {range(product.status.rental).map(num => (
                      <Option key={num} value={num}>{num}</Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>

      <Divider />

      <div className="text-right">
        <Button
          type="primary"
          onClick={onNextClick}
          disabled={transferCount === 0 || transferToStoreId === null}
        >
          内容を確認する
        </Button>
      </div>
    </>
  )
}
