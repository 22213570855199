import React from 'react'
import { Button, Card, Col, Divider, Icon, Row, Typography } from 'antd'
import Image from '../../../components/Image'
import AppLink from '../../../components/AppLink'
import { ProductSetContentsPopover } from '../../../components/ProductSetContents'

const { Text } = Typography

export default function Step2({
  transferFromStore,
  transferToStoreName,
  product,
  transferCount,
  onPrevClick,
  onNextClick,
  loading,
}) {

  return (
    <>
      <p className="text-bold mb-2">店舗移動する商品</p>

      <div className="mb-3">
        <Card size="small">
          <Row gutter={16} type="flex" align="middle">
            <Col span={3}>
              <Image src={product.image ? product.image.imageName : null} />
            </Col>
            <Col span={12}>
              <p className="mb-1">
                <AppLink to={`/products/${product.productId}`}>
                  {product.productTitle}
                </AppLink>
              </p>
              <div>
                {product.sexName}
                <Divider type="vertical" />
                {product.size}
                <Divider type="vertical" />
                {product.pNo}
              </div>
              {product.isSet && (
                <div className="mt-2">
                  <ProductSetContentsPopover
                    items={product.setContents}
                    header={product.title}
                  />
                </div>
              )}
            </Col>
            <Col span={9} >
              <span className="mr-2">レンタル中</span>
              <span>{product.status.rental}</span>
            </Col>
          </Row>
        </Card>
      </div>

      <p className="text-bold mb-2">移動情報</p>
      <div className="mb-3">
        <Card size="small">
          <Row gutter={16} type="flex" align="middle">
            <Col span={9}>
              <Row gutter={8} type="flex">
                <Col span={6} className="text-right">移動元店舗</Col>
                <Col span={18}>
                {transferFromStore.storeName}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="text-center">
              <Icon type="arrow-right"></Icon>
            </Col>
            <Col span={9}>
              <Row gutter={8} type="flex" align="middle">
                <Col span={6} className="text-right">移動先店舗</Col>
                <Col span={18}>
                  {transferToStoreName}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="text-right">
              <Divider type="vertical" />
            </Col>
            <Col span={4}>
              <Row gutter={8} type="flex" align="middle" justify="end">
                <Col span={8} className="text-right">移動数</Col>
                <Col span={16}>
                  {transferCount}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
      <Text type="danger">※レンタル代金は、移動申請月の「翌月」から移動先の店舗へカウントされます。</Text>


      <Divider />

      <Row type="flex" align="middle" justify="space-between">
        <Col span={12}>
          <Button onClick={onPrevClick}>
            <Icon type="left" />
            戻る
          </Button>
        </Col>
        <Col span={12} className="text-right">
          <Button
            type="primary"
            onClick={onNextClick}
            loading={loading}
          >
            店舗移動申請を確定する
          </Button>
        </Col>
      </Row>
    </>
  )
}
