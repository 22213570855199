import React, { useState, useMemo } from 'react'
import Page from '../../components/Page'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'
import { useCurrentUser } from '../../hooks/auth'
import useAccount from './hooks/account'
import useStoreSelect from '../../hooks/store-all-select'
import useStep from '../../hooks/step'
import useRentalProduct from '../../hooks/rental-product'
import { transfer as rentalTransferApi } from '../../api/rental'

export default function RentalTransfer({ accountId, pNo }) {
  const account = useCurrentUser();
  const transferFromStore = useAccount(accountId)
  const stores = useStoreSelect()
  const [transferToStoreId, setTransferToStoreId] = useState(null)
  const { incrementStep, decrementStep, isStep } = useStep(1)
  const product = useRentalProduct({ accountId, pNo })
  const [transferCount, setTransferCount] = useState(0)
  const [orderId, setOrderId] = useState(null)
  const [loading, setLoading] = useState(false)

  // 移動先店舗名
  const transferToStoreName = useMemo(() => {
    if (stores.length === 0 || !transferToStoreId) {
      return ''
    }

    const item = stores.find(r => r.storeId === transferToStoreId)
    return item ? item.storeName : ''
  }, [stores, transferToStoreId])

  const pageTitle = (() => {
    if (isStep(1)) return '店舗移動申請'
    if (isStep(2)) return '申請内容の確認'
    if (isStep(3)) return '店舗移動申請完了'
  })()

  const submit = async () => {
    const data = { 
      pno:pNo, 
      orderCount: transferCount,
      accountId: account.accountId,
      transferFromId: transferFromStore.accountId, 
      transferToId: transferToStoreId
    }
    setLoading(true)
    //店舗移動申し込みapi
    const response = await rentalTransferApi(data)
    setLoading(false)
    setOrderId(response.data.id)
    incrementStep()
  }

  return (
    <Page title={pageTitle}>
      {isStep(1) && (
        <Step1
          transferFromStore={transferFromStore}
          transferToStoreId={transferToStoreId}
          stores={stores}
          onStoreSelect={setTransferToStoreId}
          product={product}
          transferCount={transferCount}
          onTransferCountChange={setTransferCount}
          onNextClick={incrementStep}
        />
      )}

      {isStep(2) && (
        <Step2
          transferFromStore={transferFromStore}
          transferToStoreName={transferToStoreName}
          product={product}
          transferCount={transferCount}
          onPrevClick={decrementStep}
          onNextClick={submit}
          loading={loading}
        />
      )}

      {isStep(3) && (
        <Step3 orderId={orderId} />
      )}
    </Page>
  )
}
