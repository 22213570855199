import React from 'react'
import { Button, Card, Col, Divider, Icon, Row, Table } from 'antd'
import AppLink from '../../../components/AppLink'
import Image from '../../../components/Image'

export default function Step2({
  product,
  reasonName,
  exchangeItems,
  afterProductsLoading,
  onPrevClick,
  onNextClick,
  onChangedExchangeAfterItem,
}) {
  return (
    <>
      {exchangeItems.map((exchangeItem, _) => {
        return (
          <Card style={{marginBottom: "24px" }}>
            <p className="text-bold mb-2">返却する商品</p>

            <Card size="small">
              <Row gutter={16} type="flex" align="middle">
                <Col span={3}>
                  <Image src={product.image ? product.image.imageName : null} />
                </Col>
                <Col span={12}>
                  <p className="mb-1">
                    <AppLink to={`/products/${product.productId}`}>
                      {product.productTitle}
                    </AppLink>
                  </p>
                  <div>
                    {product.isSet && (
                      <>
                        <span className="text-md">{exchangeItem.title}</span>
                        <Divider type="vertical" />
                      </>
                    )}
                    {product.size}
                    <Divider type="vertical" />
                    {product.pNo}
                  </div>
                </Col>
                <Col span={9}>
                  <div className="panel is-narrow">
                    <ul className="plain-list">
                      <li>
                        <Row>
                          <Col span={6}>交換理由</Col>
                          <Col span={18}>{reasonName}</Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span={6}>交換枚数</Col>
                          <Col span={18}>{exchangeItem.count}</Col>
                        </Row>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Card>

            <div className="text-center mt-3 mb-3">
              <Icon type="arrow-down" style={{ fontSize: 32 }} />
            </div>

            <p className="text-bold mb-2">交換先の商品を選択してください。</p>

            {(exchangeItem.exchangeProducts.length === 0 && !afterProductsLoading) ? (
              <p className="message">
                交換先の商品が見つかりません。前の画面に戻って、交換理由または返却商品を選び直してください。
              </p>
            ) : (
              <Table
                rowKey="pNo"
                pagination={false}
                rowSelection={{
                  type: 'radio',
                  selectedRowKeys: exchangeItem.exchangeAfterItem ? [exchangeItem.exchangeAfterItem.pNo]: [],
                  getCheckboxProps: (record) => ({
                    disabled: typeof(record.count) != 'number'
                  }),
                  onSelect: record => onChangedExchangeAfterItem({
                    exchangeItemPNo: exchangeItem.pNo,
                    exchangeAfterItemPNo: record.pNo
                  })
                }}
                dataSource={exchangeItem.exchangeProducts}
                columns={[
                  { title: '商品名', dataIndex: 'productTitle', key: 'productTitle' },
                  { title: 'サイズ', dataIndex: 'size', key: 'size' },
                  { title: '品番', dataIndex: 'pNo', key: 'pNo' },
                  { title: '交換枚数', dataIndex: 'count', key: 'count' },
                ]}
                loading={afterProductsLoading}
              />
            )}
          </Card>
        )
      })}

      <Row type="flex" align="middle" justify="space-between">
        <Col span={12}>
          <Button onClick={onPrevClick}>
            <Icon type="left" />
            戻る
          </Button>
        </Col>
        <Col span={12} className="text-right">
          <Button
            type="primary"
            ghost
            onClick={onNextClick}
            disabled={!exchangeItems.every(exchangeItem =>
              exchangeItem.exchangeAfterItem !== null && exchangeItem.exchangeAfterItem !== undefined
            )}
          >
            確認する
            <Icon type="right" />
          </Button>
        </Col>
      </Row>
    </>
  )
}
