import httpClient, { fileClient } from '../http'
import { RESPONSE_OK } from '../constants'
import download from '../download'

export default {
  /** 支払検索API */
  index(params) {
    return httpClient.get(`/api/payments/`, { params })
  },

  showWithCompany(companyId, year, month) {
    return httpClient.get(`/api/payments/company/${companyId}/${year}/${month}`)
  },

  showWithStore(companyId, storeId, year, month) {
    return httpClient.get(`/api/payments/store/${companyId}/${storeId}/${year}/${month}`)
  },

  showRental(companyId, year, month) {
    const url = `/api/rental-payments/${companyId}/${year}/${month}`
    return httpClient.get(url)
  },

  async csv(params) {
    const url = `/api/payments/downloadcsv`
    const response = await fileClient.get(url, { params })

    if (response.status === RESPONSE_OK) {
      const fileName = `payment_${params.term}.csv`
      download(response.data, fileName)
    }
  },

  summary(params) {
    return httpClient.get(`/api/payments/summary`, { params })
  },

  async summaryCsv(params) {
    const url = `/api/payments/summarycsv`
    const response = await fileClient.get(url, { params })

    if (response.status === RESPONSE_OK) {
      const fileName = `summary_${params.term}.csv`
      download(response.data, fileName)
    }
  },
}
