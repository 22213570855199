import React from 'react'
import { Col, Row, Statistic, Typography } from 'antd'

const { Text } = Typography

export default function RentalStatusSummary({ summary }) {
  return (
    <Row gutter={12}>
      <Col span={8}>
        <div className="panel is-narrow">
          <Statistic
            title={<Text>レンタル中</Text>}
            value={summary ? summary.rental : null}
            formatter={val => val !== null ? val : '-'}
            suffix="点"
          />
        </div>
      </Col>
      <Col span={8}>
        <div className="panel is-narrow">
          <Statistic
            title={<Text>未返却（点数）</Text>}
            value={summary ? summary.unreturned : null}
            formatter={val => val !== null ? val : '-'}
            suffix="点"
          />
        </div>
      </Col>
      <Col span={8}>
        <div className="panel is-narrow">
          <Statistic
            title={<Text>解約済み（当月）</Text>}
            value={summary ? summary.canceled : null}
            formatter={val => val !== null ? val : '-'}
            suffix="点"
          />
        </div>
      </Col>
    </Row>
  )
}
