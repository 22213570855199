import React, { useMemo, useState } from 'react'
import { Button, Col, Divider, List, Row, Spin, Icon, } from 'antd'
import { useLocation } from '@reach/router'
import Page from '../../components/Page'
import Image from '../../components/Image'
import OrderTag from '../../components/OrderTag'
import OrderType from '../../components/OrderType'
import AppLink from '../../components/AppLink'
import { SetContentsCollapse } from '../../components/SetContents'
import ApproveModal from './components/ApproveModal'
import CancelModal from './components/CancelModal'
import orderApi from '../../api/order'
import { dateFormat, numFormat } from '../../formatter'
import { notifySuccess } from '../../notify'
import address from '../../address'
import queryString from "query-string"
import {
  ORDER_TYPE_CANCEL,
  ORDER_TYPE_CHANGE,
  ORDER_TYPE_RETURN,
  RESPONSE_OK,
  USAGE_STATUS_TYPE1
} from '../../constants'
import useFetchOrder from './hooks/fetch-order'
import useApproveModal from './hooks/approve-modal'
import { useShowPrice, useIsStatusUse, useStatusUseType, useShowShipmentFee } from '../../hooks/auth'

function OrderShow({ id }) {
  const [loading, setLoading] = useState(false)
  
  const {
    order, products, shippingCost, taxAmount, patchOrder
  } = useFetchOrder({ id, setLoading })

  const productsCost = useMemo(() => {
    return products.reduce((prev, current) => {
      return prev + current.product.details.taxOutPrice * current.orderCount
    }, 0)
  }, [products])

  const {
    approveModalVisible, handleApproveOk, showApproveModal, hideApproveModal
  } = useApproveModal({ id, setLoading, patchOrder })

  const [cancelModalVisible, setCancelModalVisible] = useState(false)

  const location = useLocation()
  const to = "/orders?" + queryString.stringify(location.state, { skipEmptyString: true })

  const showPrice = useShowPrice()

  const priceSign = (order) => order.typeCode === ORDER_TYPE_RETURN ? '- ' : ''

  // キャンセル
  const handleCancelOk = async () => {
    setCancelModalVisible(false)
    setLoading(true)

    const response = await orderApi.cancel(id)

    patchOrder({
      status: response.data.statusCode,
      statusName: response.data.statusName,
      canceledAt: response.data.createdAt,
    })

    setLoading(false)

    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: '注文をキャンセルしました。' })
    }
  }

  const renderListItem = item => (
    <List.Item>
      <div style={{ width: `100%` }}>
        <OrderRow item={item} showPrice={showPrice} priceSign={priceSign(order)}/>
      </div>
    </List.Item>
  )

  return (
    <Page title="注文内容の詳細">
      <Spin spinning={loading}>
        <div className="text-left">
          <AppLink to={to} >
            <Icon type="left" className="mr-1"/>
            <span>注文一覧に戻る</span>
          </AppLink>
        </div>
        <Divider />
        {order && (
          <>
            <OrderHeader
              order={order}
              canApprove={order.canApprove}
              canCancel={order.canCancel}
              onApprove={showApproveModal}
              onCancel={() => setCancelModalVisible(true)}
            />
            <div className="mt-3 mb-3">
              <OrderInfoPanel order={order} />
            </div>
          </>
        )}

        {order && (
          <List
            bordered={false}
            dataSource={products}
            renderItem={renderListItem}
          />
        )}

        {!showPrice && (
          <>
            <Divider />

            {order && (
              <Footer productsCost={productsCost} shippingCost={shippingCost} taxAmount={taxAmount} to={to} priceSign={priceSign(order)}/>
            )}
          </>
        )}

      </Spin>

      {order && (
        <>
          <ApproveModal
            id={order.orderId}
            visible={approveModalVisible}
            onOk={handleApproveOk}
            onCancel={hideApproveModal}
          />

          <CancelModal
            id={order.orderId}
            visible={cancelModalVisible}
            onOk={handleCancelOk}
            onCancel={() => setCancelModalVisible(false)}
          />
        </>
      )}
    </Page>
  )
}

function OrderHeader({ order, canApprove, onApprove, canCancel, onCancel }) {
  if (order.rerental) {
    order.typeName = "再レンタル（解約申込解除）"
  }

  return (
    <Row type="flex" align="middle" justify="space-between">
      <Col>
        <OrderType type={order.typeCode} name={order.typeName} />
        <Divider type="vertical" />
        <OrderTag status={order.status} type={order.typeCode}>
          {order.statusName}
        </OrderTag>
      </Col>
      <Col>
        {canApprove && (
          <Button type="primary" icon="check" onClick={onApprove}>
            注文を承認する
          </Button>
        )}
        {canCancel && (
          <Button onClick={onCancel} className="ml-1">
            注文をキャンセルする
          </Button>
        )}
      </Col>
    </Row>
  )
}

function OrderInfoPanel({ order }) {
  const isStatusUse = useIsStatusUse()
  const { isStatusUseType } = useStatusUseType()
  function getOrdererInfo(typeCode) {
    switch (typeCode) {
      case ORDER_TYPE_CHANGE:
        return ['交換', '申込者']
      case ORDER_TYPE_CANCEL:
        return ['解約', '解約者']
      case ORDER_TYPE_RETURN:
        return ['返品', '返品者']
      default:
        return ['注文', '注文者']
    }
  }

  const [orderType, orderer] = getOrdererInfo(order.typeCode)

  const hasStaffInfos = ((order.staffNo && !!order.staffNo.length) && (order.staffName && !!order.staffName.length))

  return (
    <div className="panel">
      <ul className="plain-list">
        <li>
          <Row>
            <Col span={6}>{orderType}番号</Col>
            <Col span={18}>{order.orderId}</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>{orderer}</Col>
            <Col span={18}>{order.storeName} 様</Col>
          </Row>
        </li>
        {isStatusUse && hasStaffInfos &&
          <li>
            <Row>
              <Col span={6}>社員情報</Col>
              <Col span={18}>
                社員番号：{order.staffNo}<Divider type="vertical" />
                社員名：{order.staffName}<Divider type="vertical" />
                所属支店：{order.staffStoreName}
              </Col>
            </Row>
          </li>
        }
        {isStatusUseType(USAGE_STATUS_TYPE1) && order.isPersonalOrder &&
          <li>
            <Row>
              <Col span={6}>注文分類</Col>
              <Col span={18}>個人注文</Col>
            </Row>
          </li>
        }
        <li>
          <Row>
            <Col span={6}>配送先</Col>
            <Col span={18}>
              {order.addressee}
              <br />
              {address({
                postalCode: order.postalCode,
                prefecture: order.prefectureName,
                city: order.city,
                houseNumber: order.houseNumber,
                buildingName: order.buildingName,
              })}
            </Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>電話番号</Col>
            <Col span={18}>{order.tel}</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>お届け希望日（希望時間帯）</Col>
            <Col span={18}>
              {order.deliveryDate}（{order.deliveryTimeRange}）
            </Col>
          </Row>
        </li>

        {order.reservedAt && (
          <li>
            <Row>
              <Col span={6}>注文予約日</Col>
              <Col span={18}>{dateFormat(order.reservedAt)}</Col>
            </Row>
          </li>
        )}
        {order.orderedAt && (
          <li>
            <Row>
              <Col span={6}>注文日</Col>
              <Col span={18}>{dateFormat(order.orderedAt)}</Col>
            </Row>
          </li>
        )}
        {order.shippingCompletedAt && (
          <>
            <li>
              <Row>
                <Col span={6}>発送完了日</Col>
                <Col span={18}>{dateFormat(order.shippingCompletedAt)}</Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col span={6}>発送業者</Col>
                <Col span={18}>
                  <span>{order.shippingCompanyName}</span>
                  <Divider type="vertical" />
                  <span>お問い合わせ番号：{order.shippingContactNo}</span>
                </Col>
              </Row>
            </li>
          </>
        )}
        {order.canceledAt && (
          <li>
            <Row>
              <Col span={6}>キャンセル日</Col>
              <Col span={18}>{dateFormat(order.canceledAt)}</Col>
            </Row>
          </li>
        )}
        {order.returnedAt && (
          <li>
            <Row>
              <Col span={6}>返品登録日</Col>
              <Col span={18}>{dateFormat(order.returnedAt)}</Col>
            </Row>
          </li>
        )}
        {order.freeMemo && (
          <li>
            <Row>
              <Col span={6}>フリーメモ</Col>
              <Col span={18}>{order.freeMemo.replace(/\r?\n/g, "\u00A0\u00A0")}</Col>
            </Row>
          </li>
        )}
      </ul>
    </div>
  )
}

function OrderRow({ item, showPrice, priceSign }) {
  const { product } = item
  const { details } = product

  return (
    <>
      <Row gutter={16} type="flex" align="middle">
        <Col span={4}>
          <Image src={product.mainImageUrl} />
        </Col>
        <Col span={6}>
          <p className="mb-1">
            <AppLink to={`/products/${product.productId}`}>
              {product.title}
            </AppLink>
          </p>
          <div className="mb-1">
            {details.sexName}
            <Divider type="vertical" />
            {details.size}
            <Divider type="vertical" />
            {details.typeName}
          </div>
          <p>{details.pNo}</p>
        </Col>
        {!showPrice && (
          <Col span={4}>{numFormat(details.taxOutPrice)} 円</Col>
        )}
        <Col span={4}>数量：{numFormat(item.orderCount)}</Col>
        {!showPrice && (
          <Col span={6}>
            <div className="text-right">
              <span className="text-md text-bold">
                {priceSign}{numFormat(item.orderCount * details.taxOutPrice)} 円
              </span>
              <span className="text-sm">（税抜）</span>
            </div>
          </Col>
        )}
      </Row>
      {details.set && (
        <div className="mt-1">
          <SetContentsCollapse item={details} title={details.setTitle}/>
        </div>
      )}
      {details.detailFreeMemo &&
        <div className="mt-1 panel is-narrow">
          <Row>
            <Col span={6}>備考</Col>
            <Col span={18}>{details.detailFreeMemo}</Col>
          </Row>
        </div>
      }
    </>
  )
}

function Footer({ productsCost, shippingCost, taxAmount, to, priceSign }) {
  const isCalcShipmentFee = useShowShipmentFee()
  const totalCost = useMemo(() => (isCalcShipmentFee && shippingCost) + productsCost + taxAmount, [shippingCost, productsCost, taxAmount])

  return (
    <ul className="plain-list">
      <li>
        <Row>
          <Col span={18}>
            <div className="text-right">商品合計</div>
          </Col>
          <Col span={6}>
            <div className="text-right">
              <span className="text-bold text-md">
                {priceSign}{numFormat(productsCost)} 円
              </span>
            </div>
          </Col>
        </Row>
      </li>
      <li>
        <Row>
          <Col span={18}>
            <div className="text-right">送料</div>
          </Col>
          <Col span={6}>
            <div className="text-right">
              <span className={`text-bold ${!isCalcShipmentFee ? " text-sm": "text-md"}`}>
                {isCalcShipmentFee ? `${priceSign}${numFormat(shippingCost)} 円`: "翌月に実送料を請求いたします"}
              </span>
            </div>
          </Col>
        </Row>
      </li>
      <li>
        <Row>
          <Col span={18}>
            <div className="text-right">消費税</div>
          </Col>
          <Col span={6}>
            <div className="text-right">
              <span className="text-bold text-md">
                {priceSign}{numFormat(taxAmount)} 円
              </span>
            </div>
          </Col>
        </Row>
      </li>
      <li>
        <Row>
          <Col span={18}>
            <div className="text-right">注文合計</div>
          </Col>
          <Col span={6}>
            <div className="text-right">
              <span className="text-bold text-md">
                {priceSign}{numFormat(totalCost)} 円
              </span>
            </div>
          </Col>
        </Row>
      </li>
    </ul>
  )
}

export default OrderShow
