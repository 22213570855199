import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, List, Divider, Button, Affix, Spin, Alert, Input } from 'antd'
import Page from '../../components/Page'
import Image from '../../components/Image'
import AppLink from '../../components/AppLink'
import NumInput from '../../components/NumInput'
import ShippingInfo from './components/ShippingInfo'
import SidePanel from './components/SidePanel'
import cartApi from '../../api/cart'
import staffApi from '../../api/staff'
import { numFormat } from '../../formatter'
import { RESPONSE_INVALID, RESPONSE_OK, USAGE_STATUS_TYPE1 } from '../../constants'
import { useCurrentUser, useShowPrice, useIsStatusUse, useStatusUseType } from '../../hooks/auth'
import useShippingMessage from '../../hooks/shipping-message'
import usePageState from './hooks/page-state'
import useLoading from './hooks/loading'
import fetchCart from './fetch-cart'
import fetchShippingAddresses from './fetch-shipping-addresses'
import { SetContentsCollapse } from '../../components/SetContents'
import useDeliveryTimes from '../../hooks/delivery-times'
import { StaffInfoInput, useStaffInfo } from './components/StaffInfoInput'
import StaffInfo from './components/StaffInfo'
import StaffValidateModal from './components/StaffValidateModal'

const transformForPost = cartItem => ({
  cartId: cartItem.cartId,
  pNo: cartItem.product.details.pNo,
  orderCount: cartItem.orderCount,
  changeOrderCount: cartItem.orderCount,
  price: cartItem.product.details.taxOutPrice,
  tax: cartItem.product.details.tax,
  isSet: cartItem.product.details.set,
  detailFreeMemo: cartItem.detailFreeMemo
})

const hasNumInput = val => val !== '' && val !== null

function Cart() {
  const {
    toCart, toConfirm, toComplete, isCart, isConfirm, isComplete, pageTitle
  } = usePageState()

  const shippingMessage = useShippingMessage()
  const user = useCurrentUser()
  const isStatusUse = useIsStatusUse()
  const [carts, setCarts] = useState([])
  const [shippingCost, setShippingCost] = useState(null)
  const [shippingAddresses, setShippingAddresses] = useState([])
  const [shipTo, setShipTo] = useState(null)
  const [editData, setEditData] = useState({ carts: [] })
  const [orderId, setOrderId] = useState('')
  const [loading, withLoading] = useLoading()
  const [invalid, setInvalid] = useState(false)
  const [stockShortage, setStockShortage] = useState(false)
  const [freeMemo, setFreeMemo] = useState(null)
  const [showDetailMemo, setShowDetailMemo] = useState(null)
  const [detailFreeMemo, setDetailFreeMemo] = useState([])
  const {inputStaffInfo, setInputStaffInfo, currentStaffInfo, setCurrentStaffInfo, inputErrors, existsStaffInputError } = useStaffInfo()
  const [taxAmount, setTaxAmount] = useState([])
  const { isStatusUseType } = useStatusUseType()

  const {
    deliveryTimes,
    fetchDeliveryTimes,
    timeId,
    setTimeId,
    deliveryDate,
    setDeliveryDate,
  } = useDeliveryTimes()

  const showPrice = useShowPrice()

  const initCart = async () => {
    const result = await fetchCart()

    if (result) {
      setCarts(result.carts)
      setShippingCost(result.shippingCost)
      setEditData({ carts: result.carts.map(transformForPost) })
      setShowDetailMemo(result.showDetailMemo)
      setTaxAmount(result.taxAmount)
    }
  }

  useEffect(() => {
    withLoading(initCart)
  }, [])

  useEffect(() => {
    fetchDeliveryTimes()
  }, [])

  const productsCost = useMemo(() => {
    let cost = 0
    carts.forEach(cartItem => {
      cost += cartItem.product.details.taxOutPrice * cartItem.orderCount
    })
    return cost
  }, [carts])

  // カートに同一の商品IDの商品が含まれるか
  const containsSameProduct = useMemo(() => {
    const orderProductList = carts.reduce((prev, current) => {
      if (prev[current.product.productId] === undefined) {
        prev[current.product.productId] = [current]
      } else {
        prev[current.product.productId].push(current)
      }
      return prev
    }, {})
    return !!Object.keys(orderProductList).filter(
      key => orderProductList[key].length > 1
    ).length
  }, [carts])

  // 商品ID重複登録エラーを表示するか
  const showProductIdDuplicateError =
    containsSameProduct &&
    !inputStaffInfo.isPersonalOrder &&
    isStatusUseType(USAGE_STATUS_TYPE1)

  // カート確認画面へ遷移するボタンを活性にするか
  const enableTransitionToConfirm = !isStatusUse || (!existsStaffInputError && !showProductIdDuplicateError)

  // バリデーションエラー処理
  const handleResponseError = response => {
    if (response.data.message.indexOf('INVALID_ORDER_COUNT') === 0) {
      setStockShortage(true)
    } else {
      setInvalid(true)
    }
  }

  // アイテム削除
  const handleDelete = cartId => withLoading(async () => {
    const response = await cartApi.delete(cartId, editData)

    if (response.status === RESPONSE_OK) {
      await initCart()
    } else if (response.status === RESPONSE_INVALID) {
      handleResponseError(response)
    }
  })

  // 数量入力
  const handleOrderChange = (cartId, num) => {
    const newCarts = editData.carts.map(item => {
      if (item.cartId === cartId) {
        item.changeOrderCount = hasNumInput(num) ? num : item.orderCount
      }
      return item
    })
    setEditData({ carts: newCarts })
  }

  const handleDetailMemoChange = (cartId, detailFreeMemo) => {
    const newCarts = editData.carts.map(item => {
      if (item.cartId === cartId) {
        item.detailFreeMemo = detailFreeMemo
      }
      return item
    })

    carts.map(item => {
          if (item.cartId === cartId) {
            item.detailFreeMemo = detailFreeMemo
          }
          return item
        })

    setEditData({ carts: newCarts })
  }

  // 確認画面へ
  const handleConfirm = async () => {
    // 発送先住所取得
    if (shippingAddresses.length === 0) {
      await withLoading(async () => {
        const addresses = await fetchShippingAddresses(user.store.storeId)
        setShippingAddresses(addresses)
        // fetchShippingAddresses内で自店舗を上にセットしている
        setShipTo(addresses[0])
      })
    }
    
    // 数量編集内容をクリアしておく
    setEditData({ carts: editData.carts.map(item => {
      item.changeOrderCount = item.orderCount
      return item
    })})

    if (isStatusUse && await withLoading(async () => {
      const response = await staffApi.check(inputStaffInfo)
      const isSuspend = response.status === RESPONSE_OK && response.data.status === 2
      setCurrentStaffInfo({
        isConfirm: isSuspend,
        staffName: response.status === RESPONSE_OK ? response.data.staffName: '',
        storeName: response.status === RESPONSE_OK ? response.data.storeName: ''
       })
      return isSuspend
    })) { return }
    
    // 画面表示切り替え
    toConfirm()
  }

  // 数量更新
  const handleUpdate = () => withLoading(async () => {
    const response = await cartApi.update(editData)
    if (response.status === RESPONSE_OK) {
      if (response.data.message === 'INVALID_ORDER_COUNT') {
        handleResponseError(response)
      } else {
        await initCart()
      }
    } else if (response.status === RESPONSE_INVALID) {
      handleResponseError(response)
    }
  })

  // 注文
  const handleSubmit = () => withLoading(async () => {
    const response = await cartApi.submit({
      deliveryFee: shippingCost,
      deliveryDate: deliveryDate,
      deliveryTimeId: timeId,
      carts: carts.map(transformForPost),
      freeMemo: freeMemo,
      ...shipTo,
      ...(isStatusUse ? inputStaffInfo : {})
    })

    if (response.status === RESPONSE_OK) {
      toComplete()
      setOrderId(response.data.orderId)
    } else if (response.status === RESPONSE_INVALID) {
      toCart()
      handleResponseError(response)
    }
  })

  // 再読み込み
  const handleReload = () => withLoading(async () => {
    await initCart()
    setShipTo(0)
    setInvalid(false)
  })

  // 配送先変更
  const handleShipToChange = address => {
    setShipTo(address)

    withLoading(async () => {
      const response = await cartApi.deliveryFee({
        prefectureId: address.prefectureId,
        carts: carts.map(transformForPost)
      })
      setShippingCost(response.data.deliveryFee)
    })
  }

  const renderCartItem = cartItem => {
    const { changeOrderCount } = editData.carts.find(
      item => item.cartId === cartItem.cartId
    ) || {}

    const { detailFreeMemo } = editData.carts.find(
      item => item.cartId === cartItem.cartId
    ) || {}

    return (
      <List.Item>
        <div style={{ width: `100%` }}>
          <OrderRow
            item={cartItem}
            orderCount={changeOrderCount}
            onOrderChange={handleOrderChange}
            onDelete={handleDelete}
            confirm={isConfirm}
            showDetailMemo={showDetailMemo}
            detailMemo={detailFreeMemo}
            onDetailMemo={handleDetailMemoChange}
            showPrice={showPrice}
          />
        </div>
      </List.Item>
    )
  }

  // 数量更新可能か？
  const canUpdate = carts.length > 0 && !loading && !isConfirm

  if (isComplete) {
    return (
      <Page title={pageTitle}>
        <Thanks orderId={orderId} />
      </Page>
    )
  }

  return (
    <Page title={pageTitle}>
      {shippingMessage && (
        <div className="mb-2">
          <Alert message={shippingMessage} type="info" showIcon />
        </div>
      )}
      <Spin spinning={loading}>
        <Row gutter={40}>
          {isStatusUse && isCart && carts.length !== 0 && !loading &&
            <Col span={40}>
              <StaffInfoInput staffInfo={inputStaffInfo} setStaffInfo={setInputStaffInfo} inputErrors={inputErrors}/>
            </Col>
          }
          <Col span={16}>
            {isConfirm && (
              <div className="mb-4">
                <ShippingInfo
                  user={user}
                  shippingAddresses={shippingAddresses}
                  currentShipIndex={shipTo}
                  shipTo={shipTo}
                  onShipChange={handleShipToChange}
                />
              </div>
            )}
            {isStatusUse && isConfirm && <StaffInfo staffInfo={inputStaffInfo} />}
            {isConfirm && (
              <div className="mb-4">
                <div className="mb-2">
                  <Row type="flex" align="middle" justify="space-between">
                    <div className="mb-1">
                      <Col className="text-bold">
                        フリーメモ
                      </Col>
                    </div>
                    <Col className="text text-sm">
                      注文、発送に関するご要望ではありません。ご注文者様用のメモとして、注文履歴ページに掲載されます。
                    </Col>
                  </Row>
                </div>
                <Input.TextArea rows={4} maxLength="100" autoSize={{ minRows: 2 }}
                  value={freeMemo} onChange={event => setFreeMemo(event.target.value)} />
              </div>
            )}
            <div className="mb-2">
              <Row type="flex" align="middle" justify="space-between">
                <Col className="text-bold">
                  注文内容
                </Col>
                {canUpdate && (
                  <Col className="text-red text-sm">
                    数量を変更した場合にはページ下部にある「数量を更新する」ボタンをクリックしてください。
                  </Col>
                )}
              </Row>
            </div>
            {invalid && <ValidationError onReload={handleReload} />}
            {stockShortage && (
              <StockShortage onOk={() => setStockShortage(false)} />
            )}
            {showProductIdDuplicateError && (<DuplicateError />)}
            <List
              bordered={false}
              dataSource={carts}
              renderItem={renderCartItem}
            />
            {canUpdate && (
              <div className="text-right mt-1">
                <Button type="primary" ghost onClick={handleUpdate}>
                  数量を更新する
                </Button>
              </div>
            )}
            {carts.length === 0 && !loading && <p>カートに商品はありません。</p>}
          </Col>
          <Col span={8}>
            {carts.length > 0 && (
              <Affix offsetTop={16}>
                <SidePanel
                  productsCost={productsCost}
                  shippingCost={shippingCost}
                  onConfirm={handleConfirm}
                  onSubmit={handleSubmit}
                  onBack={toCart}
                  confirm={isConfirm}
                  deliveryTimes={deliveryTimes}
                  onTimeChange={id => setTimeId(id)}
                  onDateChange={date => setDeliveryDate(date)}
                  showPrice={showPrice}
                  enableTransitionToConfirm={enableTransitionToConfirm}
                  taxAmount={taxAmount}
                />
              </Affix>
            )}
          </Col>
        </Row>
        <StaffValidateModal
          inputStaffInfo={inputStaffInfo}
          currentStaffInfo={currentStaffInfo}
          setCurrentStaffInfo={setCurrentStaffInfo}
          onOk={toConfirm}
         />
      </Spin>
    </Page>
  )
}

function Thanks({ orderId }) {
  return (
    <>
      <div className="text-lg text-center mb-2">
        ご注文ありがとうございました。
      </div>
      <div className="text-center mb-4">
        注文番号：{orderId}
      </div>
      <div className="text-center">
        <AppLink to={`/orders/${orderId}`}>注文詳細ページ</AppLink>
        で、ご注文の内容を確認できます。
      </div>
    </>
  )
}

function OrderRow({ item, orderCount, onOrderChange, onDelete, confirm, showDetailMemo, detailMemo, onDetailMemo, showPrice}) {
  const { product } = item
  const { details } = product

  const handleChange = num => {
    onOrderChange(item.cartId, num)
  }

  const handleDelete = () => {
    onDelete(item.cartId)
  }

  const handleDetailMemoChange = freeMemo => {
    onDetailMemo(item.cartId, freeMemo)
  }

  return (
    <>
      <Row gutter={16} type="flex" align="middle">
        <Col span={6}>
          <Image src={product.mainImageUrl} />
        </Col>
        <Col span={showPrice ? 14 : 10}>
          <p className="mb-1">
            {confirm ? (
              <span className="text-header">{product.title}</span>
            ) : (
              <AppLink to={`/products/${product.productId}`}>
                {product.title}
              </AppLink>
            )}
          </p>
          <div className="mb-1">
            {details.sexName}
            <Divider type="vertical" />
            {details.size}
            <Divider type="vertical" />
            {!details.set && (
              <>
                {details.typeName}
              </>
            )}
          </div>
          <p>{details.pNo}</p>
        </Col>
        {!showPrice && (
          <Col span={4}>{numFormat(details.taxOutPrice)} 円</Col>
        )}
        <Col span={4}>
          {confirm ? (
            <div className="text-right">
              {numFormat(item.orderCount)} {product.unitName}
            </div>
          ) : (
            <NumInput
              max={details.stockCount}
              value={orderCount}
              onChange={handleChange}
            />
          )}
        </Col>
      </Row>

      {details.set && (
        <div className="mt-1">
          <SetContentsCollapse item={details} title={details.setTitle}/>
        </div>
      )}

      {!showPrice && !confirm && (
        <div className="mt-1" style={{ backgroundColor: `#f5f5f5`, padding: 8 }}>
          <Row type="flex" align="middle">
            <Col span={16}>
              {!confirm && (
                <>
                  {/* eslint-disable-next-line */}
                  <a href="#" onClick={handleDelete}>削除</a>
                </>
              )}
            </Col>
            {!showPrice && (
              <>
                <Col span={4}>
                  <div className="text-right">小計</div>
                </Col>
                <Col span={4}>
                  <div className="text-right text-bold">
                    {numFormat(item.orderCount * details.taxOutPrice)} 円
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
      )}

      {showDetailMemo &&
        <div className="mt-1">
          {!confirm ? (
          <>
            <p className="mb-1">備考（数量を確定後に入力してください）</p>
            <Input.TextArea
              className="mt-1"
              rows={2} 
              maxLength="100" 
              autoSize={{ minRows: 2 }}
              value={detailMemo}
              onChange={event => handleDetailMemoChange(event.target.value)} 
            />
          </>
          ) : (
            <>
              {detailMemo &&
                <>
                  <p className="mb-1">備考</p>
                  <div className="panel is-narrow">
                    {detailMemo}
                  </div>
                </>
              }
            </>
          )}
        </div>
      }
    </>
  )
}

function ValidationError({ onReload }) {
  return (
    <div className="mt-2 mb-2">
      <div className="message is-danger">
        <p className="mb-2">
          商品の情報が変更されているか、別の端末でカートの内容が更新されています。ページを読み込み直してください。
        </p>
        <div className="text-right">
          <Button
            type="danger"
            icon="redo"
            onClick={onReload}
          >
            再読み込み
          </Button>
        </div>
      </div>
    </div>
  )
}

function StockShortage({ onOk }) {
  return (
    <div className="mb-2">
      <div className="message is-danger">
        <p className="mb-2">
          ご指定の注文数は、在庫数を上回っています。商品ページへ戻って在庫数をご確認ください。
        </p>
        <div className="text-right">
          <Button type="danger" onClick={onOk} ghost>OK</Button>
        </div>
      </div>
    </div>
  )
}

function DuplicateError() {
  return (
    <div className="mb-2">
      <div className="message is-danger">
        <p className="mb-2">
          同一商品に対して複数のサイズがある場合、注文することができません。
        </p>
      </div>
    </div>
  )
}

export default Cart
