import React, { useEffect, useMemo } from 'react'
import {
  Button,
  Card,
  Col,
  Divider,
  Icon,
  Input,
  Row,
  Select,
  Table
} from 'antd'
import Image from '../../../components/Image'
import AppLink from '../../../components/AppLink'
import { ProductSetContentsPopover } from '../../../components/ProductSetContents'
import { range } from '../../../arrays'

const { TextArea } = Input
const { Option } = Select

export default function Step1({
  product,
  selectTable,
  reasons,
  reason,
  comment,
  exchangeItems,
  onSelectedReason,
  onChangedComment,
  onChangedExchangeItem,
  onNextClick,
}) {

  const wrongSize = useMemo(() => reason === 1, [reason])

  const canSelectWrongSize = selectTable.every(item => {
    return item.leftItemCount >= item.setCount
  })

  const reasonOptions = reasons.filter(r => (
    canSelectWrongSize ? true : r.value !== 1
  ))

  const getChangeCountOptions = row => {
    if (product.isSet && !wrongSize) {
      return range(row.leftItemCount)
    }
    return range(row.setCount * product.status.rental)
      .filter(num => wrongSize ? (num % row.setCount === 0) : true)
  }

  const canForward = useMemo(
    () => exchangeItems.length > 0,
    [exchangeItems]
  )

  if (!product) return null

  return (
    <>
      <div className="mb-4">
        <Card size="small">
          <Row gutter={16} type="flex" align="middle">
            <Col span={3}>
              <Image src={product.image ? product.image.imageName : null} />
            </Col>
            <Col span={12}>
              <p className="mb-1">
                <AppLink to={`/products/${product.productId}`}>
                  {product.productTitle}
                </AppLink>
              </p>
              <div>
                {product.sexName}
                <Divider type="vertical" />
                {product.size}
                <Divider type="vertical" />
                {product.pNo}
              </div>
              {product.isSet && (
                <div className="mt-2">
                  <ProductSetContentsPopover
                    items={product.setContents}
                    header={product.title}
                  />
                </div>
              )}
            </Col>
            <Col span={9}>
              <span className="mr-2">レンタル中</span>
              <span>{product.status.rental}</span>
            </Col>
          </Row>
        </Card>
      </div>

      <div className="mb-4">
        <p className="mb-2">
          <span className="text-bold">交換理由を選択してください。</span>
          <span>サイズ交換の場合は、セット枚数ごとの交換となります。</span>
        </p>
        <Select
          value={reason}
          onChange={onSelectedReason}
          style={{ width: '100%' }}
          disabled={reasons.length === 0}
        >
          {reasonOptions.map(item => (
            <Option key={item.value} value={item.value}>{item.label}</Option>
          ))}
        </Select>
        <p className="mt-2 mb-1">備考があればご記載ください。（任意）</p>
        <TextArea
          value={comment}
          onChange={e => onChangedComment(e.target.value)}
          autoSize={{ minRows: 2 }}
        />
      </div>

      <p className="text-bold mb-2">返却する商品と枚数を選択してください。</p>

      <Table
        rowKey="pNo"
        pagination={false}
        dataSource={selectTable}
        columns={[
          { title: '商品名', dataIndex: 'title', key: 'title' },
          { title: 'サイズ', dataIndex: 'size', key: 'size' },
          { title: '品番', dataIndex: 'pNo', key: 'pNo' },
          {
            title: 'レンタル数',
            dataIndex: 'setCount',
            key: 'count',
            render: (val, row) => {
              if (!product.isSet) {
                return val * product.status.rental + '枚'
              }
              return wrongSize
                ? `${product.status.rental}セット (${val * product.status.rental}枚)`
                : `${row.leftItemCount}枚`
            },
          },
          {
            title: '交換数',
            dataIndex: 'changes',
            key: 'changes',
            render: (_, row) => {
              const findItem = exchangeItems.find(item => item.pNo === row.pNo)
              return (
                <Select
                  allowClear
                  value={ findItem ? findItem.count : "" }
                  onChange={val => {
                    onChangedExchangeItem({
                      sortId: row.sortId,
                      pNo: row.pNo,
                      count: val,
                      setCount: product.isSet ? Math.ceil(val / row.setCount) : 1
                    })
                  }}
                  style={{ width: '100%' }}
                  disabled={reason == null}
                  defaultValue={wrongSize ? row.setCount : 1}
                >
                  {getChangeCountOptions(row).map((num, index) => (
                    <Select.Option key={num} value={num}>
                      {
                        (wrongSize && product.isSet) ? `${index + 1}セット (${num}枚)`: `${num}枚`
                      }
                    </Select.Option>
                  ))}
                </Select>
            )},
            width: 200
          },
        ]}
      />

      <Divider />

      <div className="text-right">
        <Button
          type="primary"
          ghost
          onClick={onNextClick}
          disabled={!canForward}
        >
          交換先を選ぶ
          <Icon type="right" />
        </Button>
      </div>
    </>
  )
}
