import httpClient, { fileClient } from '../http'
import { RESPONSE_OK } from '../constants'
import download from '../download'

export default {
  index(params) {
    return httpClient.get(`/api/billings/`, { params })
  },

  showWithCompany(companyId, year, month) {
    return httpClient.get(`/api/billings/company/${companyId}/${year}/${month}`)
  },

  showWithStore(companyId, storeId, year, month) {
    return httpClient.get(`/api/billings/store/${companyId}/${storeId}/${year}/${month}`)
  },

  destinations() {
    return httpClient.get(`/api/billing-destinations`)
  },

  showRental(companyId, year, month) {
    const url = `/api/rental-billings/${companyId}/${year}/${month}`
    return httpClient.get(url)
  },

  async csv(params) {
    const url = `/api/billings/downloadcsv`
    const response = await fileClient.get(url, { params })

    if (response.status === RESPONSE_OK) {
      const fileName = `billing_${params.term}.csv`
      download(response.data, fileName)
    }
  },

  create(data) {
    return httpClient.post(`/api/billings`, data)
  },
}
