import { useState } from 'react'
import transferApi from '../../../api/transfer-order'
import { RESPONSE_OK } from '../../../constants'
import { notifySuccess } from '../../../notify'

export default function useApproveModal({ id, setLoading, patchOrder }) {
  const [approveModalVisible, setApproveModalVisible] = useState(false)

  const handleApproveOk = async () => {
    setApproveModalVisible(false)
    setLoading(true)

    const response = await transferApi.approve(id)

    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: '申請を承認しました。' })

      patchOrder({
        status: response.data.statusCode,
        statusName: response.data.statusName,
        orderedAt: response.data.createdAt,
        canApprove: false,
      })

    }
    setLoading(false)
  }

  const showApproveModal = () => setApproveModalVisible(true)
  const hideApproveModal = () => setApproveModalVisible(false)

  return {
    approveModalVisible,
    handleApproveOk,
    showApproveModal,
    hideApproveModal
  }
}
