import React from 'react'
import NotFound from '../../components/NotFound'
import UsageStatusOnlySearch from './pages/UsageStatusOnlySearch'
import UsageStatusAndNextDeliverySearch from './pages/UsageStatusAndNextDeliverySearch'
import { useStatusUseType } from '../../hooks/auth'
import { USAGE_STATUS_NONE, USAGE_STATUS_TYPE1, USAGE_STATUS_TYPE2 } from '../../constants'

// 利用状況検索
const UsageStatusSearch = (props) => {
  const { isStatusUseType } = useStatusUseType()
  return (
    <div>
      {isStatusUseType(USAGE_STATUS_TYPE1) && (
        <UsageStatusAndNextDeliverySearch
          props={props}
        />
      )}
      {isStatusUseType(USAGE_STATUS_TYPE2) && (
        <UsageStatusOnlySearch
          props={props}
        />
      )}
      {isStatusUseType(USAGE_STATUS_NONE) && (
        <NotFound />
      )}
    </div>
  )
}

export default UsageStatusSearch
