import React, { useState, useMemo } from 'react'
import { Button, Row, Col, Divider, Icon, Card, Spin, Table } from 'antd'
import { useLocation } from '@reach/router'
import Page from '../../components/Page'
import Image from '../../components/Image'
import OrderTag from '../../components/OrderTag'
import OrderType from '../../components/OrderType'
import AppLink from '../../components/AppLink'
import ReturnCard from '../../components/ReturnCard'
import ApproveModal from './components/ApproveModal'
import CancelModal from './components/CancelModal'
import rentalApi from '../../api/rental'
import returnAcceptApi from '../../api/return-accept'
import { dateFormat, numFormat } from '../../formatter'
import { notifySuccess } from '../../notify'
import address from '../../address'
import {
  RESPONSE_OK,
  ORDER_TYPE_CHANGE,
  ORDER_TYPE_CANCEL,
  RETURN_STATUS_OK,
  ORDER_STATUS_CANCEL
} from '../../constants'
import useFetchOrder from './hooks/fetch-order'
import useFetchReturnInfo from './hooks/fetch-return-info'
import useApproveModal from './hooks/approve-modal'
import ReturnTags from '../../components/ReturnTags'
import { useIsAdmin, useIsStatusUse } from '../../hooks/auth'
import LinkButton from '../../components/LinkButton'
import queryString from "query-string"

const { Column } = Table

function ExchangeShow({ id }) {
  const isAdmin = useIsAdmin()
  const [loading, setLoading] = useState(false)

  const {
    order, products, shippingCost, patchOrder, refreshOrder
  } = useFetchOrder({ id, setLoading })

  const { returnInfo } =
    useFetchReturnInfo({ id, setLoading, isAdmin })

  const isReturned = order ? order.returnStatus === RETURN_STATUS_OK : false
  const showReturnAccept = isAdmin && !isReturned

  const exchangeFromProducts = useMemo(
    () => products.filter(({ product }) => product.details.returned),
    [products]
  )

  const exchangeToProducts = useMemo(
    () => products.filter(({ product }) => !product.details.returned),
    [products]
  )

  const exchangeItems = exchangeFromProducts.map(exchangeFromProduct => {
    const exchangeToProduct = exchangeToProducts.find(exchangeToProduct =>
      exchangeToProduct.product.details.beforeTitle === exchangeFromProduct.product.details.beforeTitle )
    return {
      exchangeFromProduct: exchangeFromProduct,
      exchangeToProduct: exchangeToProduct || {}
    }
  })

  const {
    approveModalVisible, handleApproveOk, showApproveModal, hideApproveModal
  } = useApproveModal({ id, setLoading, patchOrder })

  const [cancelModalVisible, setCancelModalVisible] = useState(false)

  const hasReturnTags = (
    order ? order.status !== ORDER_STATUS_CANCEL : false
  )

  const location = useLocation()
  const to = "/orders?" + queryString.stringify(location.state, { skipEmptyString: true })

  // キャンセル
  const handleCancelOk = async () => {
    setCancelModalVisible(false)
    setLoading(true)

    const response = await rentalApi.dropExchange(id)

    if (response.status !== RESPONSE_OK) {
      setLoading(false)
      return false
    }

    await refreshOrder(id)

    setLoading(false)
    notifySuccess({ message: '注文をキャンセルしました。' })
  }

  // 返品依頼メール送信
  const sendReminderMail = async () => {
    const response = await returnAcceptApi.sendReminderMail(id)
    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: '返品依頼メールを送信しました。' })
    }
  }

  return (
    <Page title="注文内容の詳細">
      <Spin spinning={loading}>
        <div className="text-left">
          <AppLink to={to} >
            <Icon type="left" className="mr-1"/>
            <span>注文一覧に戻る</span>
          </AppLink>
        </div>
        <Divider />
        {order && (
          <>
            <OrderHeader
              order={order}
              products={products}
              canApprove={order.canApprove}
              canCancel={order.canCancel}
              onApprove={showApproveModal}
              onCancel={() => setCancelModalVisible(true)}
              showReturnAccept={showReturnAccept}
              onReminder={sendReminderMail}
              hasReturnTags={hasReturnTags}
            />

            <div className="mt-3 mb-3">
              <OrderInfoPanel
                order={order}
                products={products}
                exchangeFrom={exchangeItems[0] != null ? exchangeItems[0].exchangeFromProduct: null}
                exchangeTo={exchangeItems[0] != null ? exchangeItems[0].exchangeToProduct: null}
                returnInfo={returnInfo}
                hasReturnTags={hasReturnTags}
              />
            </div>

            {hasReturnTags && (
              <div className="mb-3">
                <ReturnCard id={id} highlighted={!isReturned} hasReturnTags={hasReturnTags} />
              </div>
            )}
          </>
        )}

        {(order && products) && exchangeItems.map((exchangeItem, _) => {
          return (
            <ReturnRow
              exchangeFrom={exchangeItem.exchangeFromProduct}
              exchangeTo={exchangeItem.exchangeToProduct}
            />
          )
        })}

        <Divider />

        {order && (
          <Footer shippingCost={shippingCost} />
        )}

        {returnInfo && (
          <div className="mt-3 mb-3">
            <ReturnInfoTable products={returnInfo.returnProductList}/>
          </div>
        )}
      </Spin>

      {order && (
        <>
          <ApproveModal
            id={order.orderId}
            visible={approveModalVisible}
            onOk={handleApproveOk}
            onCancel={hideApproveModal}
          />

          <CancelModal
            id={order.orderId}
            visible={cancelModalVisible}
            onOk={handleCancelOk}
            onCancel={() => setCancelModalVisible(false)}
          />
        </>
      )}
    </Page>
  )
}

function OrderHeader({
  order,
  canApprove,
  onApprove,
  canCancel,
  onCancel,
  showReturnAccept,
  onReminder,
  hasReturnTags,
}) {
  return (
    <Row type="flex" align="middle" justify="space-between">
      <Col>
        <OrderType type={order.typeCode} name={order.typeName} />
        <Divider type="vertical" />
        <OrderTag status={order.status} type={order.typeCode}>
          {order.statusName}
        </OrderTag>
        {hasReturnTags && (
          <ReturnTags
            status={order.returnStatus}
            orderType={order.typeCode}
            checkedDate={order.checkedAt}
          />
        )}
      </Col>
      <Col>
        {canApprove && (
          <Button type="primary" icon="check" onClick={onApprove}>
            注文を承認する
          </Button>
        )}
        {canCancel && (
          <Button onClick={onCancel} className="ml-1">
            注文をキャンセルする
          </Button>
        )}
        {showReturnAccept && (
          <LinkButton
            to={`/returns/${order.orderId}/accept`}
            app
            type="default"
          >
            返品処理を開始する
          </LinkButton>
        )}
        {showReturnAccept && (
          <Button onClick={onReminder} className="ml-1">
            返品依頼メール送信
          </Button>
        )}
      </Col>
    </Row>
  )
}

function OrderInfoPanel({ order, products, exchangeFrom, exchangeTo, returnInfo, hasReturnTags }) {
  function getOrdererInfo(typeCode) {
    switch (typeCode) {
      case ORDER_TYPE_CHANGE:
        return ['交換', '申込者']
      case ORDER_TYPE_CANCEL:
        return ['解約', '解約者']
      default:
        return ['注文', '注文者']
    }
  }

  const [orderType, orderer] = getOrdererInfo(order.typeCode)
  const returnedTitle = exchangeFrom ? exchangeFrom.product.details.setTitle : ''
  const changedTitle = exchangeTo ? exchangeTo.product.details.setTitle : ''

  return (
    <div className="panel">
      <ul className="plain-list">
        <li>
          <Row>
            <Col span={6}>{orderType}番号</Col>
            <Col span={18}>{order.orderId}</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>{orderer}</Col>
            <Col span={18}>{order.storeName} 様</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>配送先</Col>
            <Col span={18}>
              {order.addressee}
              <br />
              {address({
                postalCode: order.postalCode,
                prefecture: order.prefectureName,
                city: order.city,
                houseNumber: order.houseNumber,
                buildingName: order.buildingName,
              })}
            </Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>電話番号</Col>
            <Col span={18}>{order.tel}</Col>
          </Row>
        </li>
        {order.orderedAt && (
          <li>
            <Row>
              <Col span={6}>交換申込日</Col>
              <Col span={18}>{dateFormat(order.orderedAt)}</Col>
            </Row>
          </li>
        )}
        <li>
          <Row>
            <Col span={6}>お届け希望日（希望時間帯）</Col>
            <Col span={18}>
              {order.deliveryDate}（{order.deliveryTimeRange}）
            </Col>
          </Row>
        </li>
        {order.shippingCompletedAt && (
          <>
            <li>
              <Row>
                <Col span={6}>発送完了日</Col>
                <Col span={18}>{dateFormat(order.shippingCompletedAt)}</Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col span={6}>発送業者</Col>
                <Col span={18}>
                  <span>{order.shippingCompanyName}</span>
                  <Divider type="vertical" />
                  <span>お問い合わせ番号：{order.shippingContactNo}</span>
                </Col>
              </Row>
            </li>
          </>
        )}
        {order.canceledAt && (
          <li>
            <Row>
              <Col span={6}>キャンセル日</Col>
              <Col span={18}>{dateFormat(order.canceledAt)}</Col>
            </Row>
          </li>
        )}
        {order.freeMemo && (
          <li>
            <Row>
              <Col span={6}>フリーメモ</Col>
              <Col span={18}>
                {order.freeMemo.replace(/\r?\n/g, "\u00A0\u00A0")}
              </Col>
            </Row>
          </li>
        )}
        {order.reason && (
          <li>
            <Row>
              <Col span={6}>交換理由</Col>
              <Col span={18}>
                <span>{order.reason}</span>
                <br />
                <span>{order.userCommnet}</span>
              </Col>
            </Row>
          </li>
        )}
        {(returnedTitle && changedTitle) && (
          <li>
            <Row>
              <Col span={6}>セット変更</Col>
              <Col span={18}>{returnedTitle} → {changedTitle}</Col>
            </Row>
          </li>
        )}
        {hasReturnTags && (
          <li>
            <Row>
              <Col span={6}>返品完了日</Col>
              <Col span={18}>
                {order.checkedAt ? (
                  <span>{dateFormat(order.checkedAt)}</span>
                ) : (
                  <span className="text-red">
                    申し込み後は、かならず商品の返送を行ってください。
                  </span>
                )}
              </Col>
            </Row>
          </li>
        )}
        {returnInfo && (
          <li>
            <Row>
              <Col span={6}>備考</Col>
              <Col span={18}>
                <span>{returnInfo.adminComment}</span>
              </Col>
            </Row>
          </li>
        )}
      </ul>
    </div>
  )
}

function ReturnRow({ exchangeFrom, exchangeTo}) {
  if (!exchangeFrom || !exchangeTo) {
    return null
  }

  const returnedProduct = exchangeFrom.product
  const returnedDetails = returnedProduct.details
  const changedProduct = exchangeTo.product
  const changedDetails = changedProduct.details

  return (
    <>
      <Card style={{marginBottom: "24px"}}>
        <Card size="small">
          <Row gutter={16} type="flex" align="middle">
            <Col span={21}>
              <p className="mb-1">
                <AppLink to={`/products/${returnedProduct.productId}`}>
                  {returnedProduct.title}
                </AppLink>
                <span className="text-mute ml-1 mr-1">&gt;</span>
                <span>{returnedDetails.setTitle}</span>
              </p>
              <div>
                {returnedDetails.beforeTitle}
                <Divider type="vertical" />
                {returnedDetails.size}
                <Divider type="vertical" />
                {returnedDetails.beforePNo}
              </div>
            </Col>
          </Row>
        </Card>
        <div className="text-center mt-2 mb-4">
          <Icon type="arrow-down" style={{ fontSize: 16 }} />
        </div>
        <Row gutter={16} type="flex" align="middle">
          <Col span={4}>
            <Image src={changedProduct.mainImageUrl} />
          </Col>
          <Col span={16}>
            <p className="mb-1">
              <AppLink to={`/products/${changedProduct.productId}`}>
                {changedProduct.title}
              </AppLink>
              <span className="text-mute ml-1 mr-1">&gt;</span>
              <span>{changedDetails.setTitle}</span>
            </p>
            <div className="mb-1">
              {changedDetails.afterTitle}
              <Divider type="vertical" />
              {changedDetails.size}
            </div>
            <p>{changedDetails.afterPNo}</p>
          </Col>
          <Col span={4} className="text-right">
            数量：{numFormat(exchangeTo.displayCount)}
          </Col>
        </Row>
      </Card>
    </>
  )
}

function Footer({ shippingCost }) {
  const isStatusUse = useIsStatusUse()
  return (
    <Row>
      <Col span={18}>
        <div className="text-right">送料</div>
      </Col>
      <Col span={6}>
        <div className="text-right">
          <span className={isStatusUse ? "text-sm" : "text-md"}>
            {isStatusUse ? "※支払い情報でご確認ください" : `${numFormat(shippingCost)} 円`}
          </span>
        </div>
      </Col>
    </Row>
  )
}

function ReturnInfoTable({ products }) {
  return (
    <Table
    bordered
    pagination={false}
    dataSource={products}
    rowKey="pNo"
  >
    <Column
      key="product"
      title="商品"
      render={(_, record) => (
        <div className="text-paragraph">
          <div>
            <AppLink to={`/products/${record.productId}`}>
              {record.productTitle}
            </AppLink>
            <Divider type="vertical" />
            {record.title}
          </div>
          <div>
            {record.contentTitle && (
              <>
                {record.contentTitle}
                <Divider type="vertical" />
              </>
            )}
            {record.pNo}
            <Divider type="vertical" />
            {record.size}
          </div>
        </div>
      )}
    />
    <Column
      key="orderCount"
      title="申込数"
      dataIndex="orderCount"
      className="min-cell"
    />
    <Column
      key="ok"
      title="良品数"
      dataIndex="okCount"
      className="min-cell success-cell"
    />
    <Column
      key="repair"
      title="修繕数"
      dataIndex="repairCount"
      className="min-cell warning-cell"
    />
    <Column
      key="discard"
      title="破棄数"
      dataIndex="discardCount"
      className="min-cell error-cell"
    />
  </Table>
  )
}

export default ExchangeShow
